//#region react
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region actions
import {
    getCommissionPlans,
    setCommissionPlansSorting,
    setCommissionPlansFilters
} from "store/actions/portal/commissionPlans/commissionPlans.action";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import CommissionPlanCreateComponent from './commissionPlanCreate/commissionPlan-create.component';
import Filters from './filters.component';

//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { addQueryStringParamsToUrl } from 'utils/queryString';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { COMMISSION_PLAN_LIST } from 'constants/pageName.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import commissionPlanType from "types/commissionPlan/commissionPlan.type";
import sortingType from 'types/common/sorting.type';
import usePermissions from 'core/hooks/usePermission';
//#endregion

const ROW_UNIQUE_KEY_PROP = "id";

/** Project Edit Page Commission Plan List Component */
const CommissionPlanListComponent = ({
    commissionPlans,
    setCommissionPlansSorting,
    setCommissionPlansFilters,
    getCommissionPlans,
    isLoading,
    sorting,
    filters,
    total
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const { search, pathname, hash } = useLocation();
    const navigate = useNavigate();

    const [addPopup, setAddPopup] = useState(false)

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: COMMISSION_PLAN_LIST });


    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, PERMISSION_ACTION.CREATE );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setCommissionPlansFilters({
            ...filters,
            nameOrId: value
        })
    }

    const showAddPopup = () => {
        setAddPopup(true);
    }

    const hideAddPopup = () => {
        setAddPopup(false);
    }

    const handleRightArrowIconClick = record => {
        navigate(
            addQueryStringParamsToUrl(
                pathname, 
                search, 
                hash, 
                {
                    commissionId: record.id,
                    commissionName: record.name
                }
            )
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsForExport is only needed in the desktop version.
    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns: includedColumns,
            constructForInclude: true,
            additionalProps: {
                t
            },
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    tableRowActions.push({
        title: t("backoffice.common.deepView"),
        icon: "right",
        onClick: handleRightArrowIconClick,
    })

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            onSearch: handleSearchChange,
            loadFn: getCommissionPlans,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.COMMISSION_PLAN,
            },
            placeholder: t("backoffice.commissionplans.nameOrId"),
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            text: t("backoffice.commissionplans.createCommission"),
            enabled: hasCreatePermission,
            onClick: () => showAddPopup(),
        }
    }

    //#endregion

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={commissionPlans}
                loadFn={getCommissionPlans}
                total={total}
                sorting={sorting}
                setSortingFn={setCommissionPlansSorting}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                actions={tableRowActions}
            />
            {
                addPopup && (
                    <CommissionPlanCreateComponent
                        onClose={hideAddPopup}
                    />
                )
            }
        </TabTableDashboardLayout>
    )
}

/** CommissionPlanListComponent propTypes
    * PropTypes
*/
CommissionPlanListComponent.propTypes = {
    /** Redux action to get project commission plans */
    getCommissionPlans: PropTypes.func,
    /** Redux state property, is true when project commission plans are loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the commission plans of current editing project  */
    commissionPlans: PropTypes.arrayOf(commissionPlanType),
    /** Redux state property, commission plans sorting details */
    sorting: sortingType,
    /** Redux state property, commission plans filters */
    filters: PropTypes.object,
    /** Redux state property, commission plans total count */
    total: PropTypes.number,
    /** Redux action to set commission plans sorting details */
    setCommissionPlansSorting: PropTypes.func,
    /** Redux action to set commission plans filters */
    setCommissionPlansFilters: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getCommissionPlans: nextPage => {
            dispatch(getCommissionPlans(nextPage));
        },

        setCommissionPlansSorting: sorting => {
            dispatch(setCommissionPlansSorting(sorting))
        },

        setCommissionPlansFilters: filters => {
            dispatch(setCommissionPlansFilters(filters))
        }
    }
)

const mapStateToProps = state => {
    return {
        commissionPlans: state.commissionPlans.commissionPlans,
        sorting: state.commissionPlans.sorting,
        filters: state.commissionPlans.filters,
        total: state.commissionPlans.total,
        isLoading: state.commissionPlans.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanListComponent)