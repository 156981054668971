//#region react
import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getAffiliateManagers,
    setAffiliateManagersSorting,
    setAffiliateManagersFilters,
    forceLogout
} from "store/actions/portal/userManagement/affiliateManagers/affiliateManagers.action";
//#endregion

//#region components
import MainDashboardLayout from "components/layouts/main";
import Table from "components/common/table";
import Filters from "./filters.component";
import Confirmation from "components/common/confirmation";
import AffiliateManagerCreateComponent from "./affiliateManager-create.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { isMobile } from "utils/common";
import { hasAffiliateManagerEditPageAccess } from "utils/pageAccess";
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import ROUTES from "core/constants/routes/routes";
import ApiUrls from 'constants/api.constants';
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { AFFILIATE_MANAGERS } from "constants/pageName.constants";
import { tableColumns } from "./columns";
//#endregion

//#region types
import userType from "types/user/user.type";
import sortingType from "types/common/sorting.type";
//#endregion

import { globalProjectIdSelector, useAuthSelector } from "core/stores/authStore";
import usePermissions from "core/hooks/usePermission";

const AffiliateManagersComponent = ({
    getAffiliateManagers,
    affiliateManagers,
    isLoading,
    total,
    setAffiliateManagersSorting,
    setAffiliateManagersFilters,
    sorting,
    filters,
    forceLogout
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);
    const [isForceLogoutPopupVisible, setIsForceLogoutPopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: AFFILIATE_MANAGERS });

    const isMobileDevice = isMobile();

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_MANAGER, PERMISSION_ACTION.EXPORT );
    const hasAffiliateManagerCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_MANAGER, PERMISSION_ACTION.CREATE );
    const hasAffiliateManagersForceLogoutPermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_MANAGER, PERMISSION_ACTION.MODIFY );

    const canNavigateAffiliateManagerEditPage = hasAffiliateManagerEditPageAccess(permissionUtils)

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setAffiliateManagersFilters({
            ...filters,
            nameOrId: value
        })
    }

    const showForceLogoutPopup = () => {
        setIsForceLogoutPopupVisible(true);
    }

    const hideForceLogoutPopup = () => {
        setIsForceLogoutPopupVisible(false);
    }

    const showAffiliateManagerCreatePopup = () => {
        setIsCreatePopupVisible(true);
    }

    const hideAffiliateManagerCreatePopup = () => {
        setIsCreatePopupVisible(false);
    }

    const handleRightArrowIconClick = (affiliate) => {
        navigate({
            pathname: `${ROUTES.AFFILIATE_MANAGERS_EDIT}/${affiliate.id}`,
            search: `name=${affiliate.userName}&state=${affiliate.state}`
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            includedColumns,
            mainColumns: tableColumns,
            constructForExport: true,
            constructForInclude: true,
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (canNavigateAffiliateManagerEditPage) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "right",
            onClick: handleRightArrowIconClick
        });
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            onSearch: handleSearchChange,
            loadFn: getAffiliateManagers,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AFFILIATE_MANAGERS,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.users.userNameOrId"),
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns,
        },
        buttons: [
            {
                icon: (isMobileDevice ? "logout" : null),
                className: 'rt--button rt--button-secondary',
                type: "secondary",
                onClick: showForceLogoutPopup,
                text: t("backoffice.users.forceLogout"),
                enabled: hasAffiliateManagersForceLogoutPermission,
            },
            {
                icon: "plus",
                type: "primary",
                onClick: showAffiliateManagerCreatePopup,
                text: t("backoffice.users.createAffiliateManager"),
                enabled: hasAffiliateManagerCreatePermission,
            },
        ],
        breadcrumbs: {
            items: [
                { title: t('backoffice.menu.affiliateManagers') }
            ]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            columns: columnsForExport,
            tableName: t("backoffice.menu.affiliateManagers"),
            url: ApiUrls.EXPORT_AFFILIATE_MANAGERS,
            filters: filters,
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={affiliateManagers}
                loadFn={getAffiliateManagers}
                sorting={sorting}
                setSortingFn={setAffiliateManagersSorting}
                total={total}
                actions={tableRowActions}
                updateProps={[globalProjectId]}
            />

            <Confirmation
                title={t('backoffice.users.forceLogout')}
                message={t('backoffice.users.affiliateManagersForceLogoutConfirmationMessage')}
                onOk={forceLogout}
                onCancel={hideForceLogoutPopup}
                isVisible={isForceLogoutPopupVisible}
            />

            {isCreatePopupVisible && <AffiliateManagerCreateComponent onClose={hideAffiliateManagerCreatePopup} />}
        </MainDashboardLayout>
    )
};

/** AffiliateManagersComponent propTypes
 * PropTypes
*/
AffiliateManagersComponent.propTypes = {
    /** Redux action to get affiliate managers */
    getAffiliateManagers: PropTypes.func,
    /** Redux state property, represents the array of affiliate managers  */
    affiliateManagers: PropTypes.arrayOf(userType),
    /** Redux state property, is true when loading affiliate managers */
    isLoading: PropTypes.bool,
    /** Redux state property, affiliate managers total count */
    total: PropTypes.number,
    /** Redux action to set affiliate managers sorting details */
    setAffiliateManagersSorting: PropTypes.func,
    /** Redux action to set affiliate managers sorting details */
    setAffiliateManagersFilters: PropTypes.func,
    /** Redux state property, affiliate managers sorting details */
    sorting: sortingType,
    /** Redux state property, affiliate managers filters */
    filters: PropTypes.object,
    /** Redux action to force logout */
    forceLogout: PropTypes.func
};

const mapDispatchToProps = dispatch => ({
    getAffiliateManagers: nextPage => {
        dispatch(getAffiliateManagers(nextPage));
    },
    setAffiliateManagersSorting: sorting => {
        dispatch(setAffiliateManagersSorting(sorting));
    },
    setAffiliateManagersFilters: filters => {
        dispatch(setAffiliateManagersFilters(filters));
    },
    forceLogout: () => {
        dispatch(forceLogout());
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.affiliateManagers.isLoading,
        affiliateManagers: state.affiliateManagers.affiliateManagers,
        total: state.affiliateManagers.total,
        sorting: state.affiliateManagers.sorting,
        filters: state.affiliateManagers.filters
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AffiliateManagersComponent);
