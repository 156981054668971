import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";

import { Form, Col, Row } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";

import { addAffiliateManagerAffiliate, getAffiliateManagerAvailableAffiliates } from "store/actions/portal/userManagement/affiliateManagers/affiliates.action";

import autoSuggestionType from "types/autoSuggestion/autoSuggestion.type";

/**  Affiliate Adding Popup Component */
const AffiliateAddComponent = ({
    isSaving,
    addAffiliateManagerAffiliate,
    getAffiliateManagerAvailableAffiliates,
    availableAffiliates,
    onClose
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    useEffect(() => {
        getAffiliateManagerAvailableAffiliates(searchParams.id)
    }, [])

    /** Fires when form submitted
       * @function
       * @memberOf AffiliateAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                addAffiliateManagerAffiliate(searchParams.id, data.id, onClose);
            }).catch(() => { })
    }

    return (
        <Modal
            title={ t('backoffice.users.addAffiliate') }
            cancelText={t('backoffice.common.cancel')}
            okText={ t('backoffice.common.add') }
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
        >
            <Form
                className="rt--form"
                form={formInstance}
                colon={false}
                requiredMark={false}
                layout="vertical"
            >
                <Row gutter={[16, 0]}>
                    <Col span={24}>
                        <Form.Item
                            label={ `${t('backoffice.users.affiliate')} *` }
                            name="id"
                            rules={[
                                { required: true, message: t('backoffice.validation.fieldRequired') },
                            ]}
                            className="rt--form-item-without-margin"
                        >
                            <Select
                                options={ 
                                    availableAffiliates
                                    .map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                }
                                placeholder={`${t('backoffice.common.select')} ${t('backoffice.users.affiliate')}`}
                                search={true}
                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            />
                        </Form.Item>
                    </Col> 
                           
                </Row>
            </Form>
        </Modal>
    )
}

/** AffiliateAddComponent propTypes
    * PropTypes
*/
AffiliateAddComponent.propTypes = {
    /** Redux state property, is true when adding affiliate request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to add affiliate to affiliate manager */
    addAffiliateManagerAffiliate: PropTypes.func,
    /** Redux action to get available affiliates for affiliate manager */
    getAffiliateManagerAvailableAffiliates: PropTypes.func,
    /** Redux state property, available affilaites */
    availableAffiliates: PropTypes.arrayOf(autoSuggestionType),
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addAffiliateManagerAffiliate: (managerId, id, onSuccess) => {
            dispatch(addAffiliateManagerAffiliate(managerId, id, onSuccess));
        },

        getAffiliateManagerAvailableAffiliates: id => {
            dispatch(getAffiliateManagerAvailableAffiliates(id));
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.affiliateManagers.isSaving,
        availableAffiliates: state.affiliateManagers.edit.affiliates.availableAffiliates
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateAddComponent)