import React, { forwardRef } from "react";

import PropTypes from "prop-types";

import Form from "..";

import Input from "core/ui-kit/input";

import classNames from "core/helpers/common/classNames";

import { INPUT_TYPE } from "core/constants/component/input";

const FormPassword = forwardRef(({
	name,
	label,
	rules=[],
	className,
	showIsRequired = true,
	noStyle,
	placeholder,
    dependencies,
    disabled,
    ...props
}, ref) => {
	const isRequired = rules && rules.find(rule => rule.required) && showIsRequired;

	const builtLabel = label ? `${label} ${isRequired ? "*" : ""}` : "";
    
    const builtClassName = classNames(
        disabled && "rt--form-item-disabled",
        className
    )

	return (
		<Form.Item
			className={builtClassName}
			name={name}
			rules={rules}
            dependencies={dependencies}
			label={builtLabel}
			noStyle={noStyle}
            validateFirst={rules.length > 1}
		>
			<Input
                ref={ref}
                type={INPUT_TYPE.PASSWORD} 
                placeholder={placeholder}
                disabled={disabled}
                {...props}
            />
		</Form.Item>
	);
});

FormPassword.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
	rules: PropTypes.array,
	showIsRequired: PropTypes.bool,
	noStyle: PropTypes.bool,
	placeholder: PropTypes.string,
    dependencies: PropTypes.array,
    disabled: PropTypes.bool
};

export default FormPassword;
