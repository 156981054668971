//#region react
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getSystemLanguages,
    setSystemLanguagesSorting
} from "store/actions/portal/settings/systemLanguages/systemLanguages.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import SystemLanguageAddComponent from "./systemLanguage-add.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import ApiUrls from 'constants/api.constants';
import { SYSTEM_LANGUAGES } from "constants/pageName.constants";
import { tableColumns } from "./columns";
//#endregion

//#region types
import languageType from "types/language/language.type";
import sortingType from "types/common/sorting.type";
//#endregion

import usePermissions from "core/hooks/usePermission";

/** System Languages Page Component */
const SystemLanguagesComponent = ({
    getSystemLanguages,
    languages,
    isLoading,
    total,
    setSystemLanguagesSorting,
    sorting
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: SYSTEM_LANGUAGES });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.SYSTEM_LANGUAGES, PERMISSION_ACTION.EXPORT );
    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.SYSTEM_LANGUAGES, PERMISSION_ACTION.CREATE );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showCreatePopup = () => {
        setIsCreatePopupVisible(true);
    }

    const hideCreatePopup = () => {
        setIsCreatePopupVisible(false);
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            constructForExport: true,
            constructForInclude: true,
            includedColumns,
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        buttons: [
            {
                text: t("backoffice.languages.addLanguage"),
                icon: "plus",
                type: "primary",
                onClick: showCreatePopup,
                enabled: hasCreatePermission,
            }
        ],
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.languages') }]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.languages"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_SYSTEM_LANGUAGES,
        }
    }

    //#endregion

    // Close currency add/edit popup, after save
    useEffect(() => {
        hideCreatePopup();
    }, [languages])

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={languages}
                loadFn={getSystemLanguages}
                sorting={sorting}
                setSortingFn={setSystemLanguagesSorting}
                total={total}
                disableFullView={true}
            />

            {isCreatePopupVisible && <SystemLanguageAddComponent onClose={hideCreatePopup} />}
        </MainDashboardLayout>
    )
};

/** SystemLanguagesComponent propTypes
 * PropTypes
*/
SystemLanguagesComponent.propTypes = {
    /** Redux action to get system languages */
    getSystemLanguages: PropTypes.func,
    /** Redux state property, represents the array of system languages  */
    languages: PropTypes.arrayOf(languageType),
    /** Redux state property, is true when loading system languages */
    isLoading: PropTypes.bool,
    /** Redux state property, system languages total count */
    total: PropTypes.number,
    /** Redux action to set system languages sorting details */
    setSystemLanguagesSorting: PropTypes.func,
    /** Redux state property, languages sorting details */
    sorting: sortingType
};

const mapDispatchToProps = dispatch => ({
    getSystemLanguages: nextPage => {
        dispatch(getSystemLanguages(nextPage));
    },
    setSystemLanguagesSorting: sorting => {
        dispatch(setSystemLanguagesSorting(sorting));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.systemLanguages.isLoading,
        languages: state.systemLanguages.languages,
        total: state.systemLanguages.total,
        sorting: state.systemLanguages.sorting
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SystemLanguagesComponent);
