import React, { useState, useEffect, useRef } from "react";
import Spin from "core/ui-kit/spin";

import LeftBar from "core/layouts/main/guide/leftBar";
import guideService from "core/services/apiServices/guideService";

/** Guide Page Component */
const GuideComponent = () => {

	const [data, setData] = useState(null);
	const [menuItems, setMenuItems] = useState([]);
	const [selectedPostId, setSelectedPostId] = useState(null);
	const [loading, setLoading] = useState(false);

	const contentRef = useRef(null);

	const loadPost = postId => {
		setLoading(true);
		setSelectedPostId(postId);
		guideService.getPostData(postId)
			.then(textResponse => {
				setData(textResponse);
				contentRef.current.scrollTop = 0;
			})
			.finally(() => {
				setLoading(false);
			})
	}

	useEffect(() => {
		setLoading(true);
		guideService.getPostList()
			.then(result => {
				if (result && result.length > 0) {
					setMenuItems(result);
					loadPost(result[0].id)
				}
			})
		.finally(() => {
			setLoading(false);
		});
		
	}, []);


	const handleMenuClick = (postId) => {
		if (postId !== selectedPostId) {
			loadPost(postId)
		}
	};

	return (
		<div className="rt--guide rt--flex">
			<LeftBar
				items={menuItems}
				handleMenuClick={handleMenuClick}
				selectedPostId={selectedPostId}
			/>
			<div className="rt--guide-content">
				<Spin spinning={loading}>
					<div className="rt--guide-content-inner" ref={contentRef}>
						<div dangerouslySetInnerHTML={{ __html: data }} />
					</div>
				</Spin>
			</div>
		</div>
	);
};

export default GuideComponent;
