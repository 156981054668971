import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useLocation, useParams } from "react-router-dom";

import { Form, Col, Row, Divider, DatePicker } from 'antd';

import Modal from "components/common/modal";
import Select from "components/common/select";
import Table from "components/common/table";
import Icon from "components/common/icon";

import { addCommissionPlan, getAvailableCommissionPlans } from "store/actions/portal/commissionPlans/commissionPlans.action";

import { POPUP_SIZE } from 'constants/common.constants';
import { COMMISSION_PLAN_TYPE } from 'constants/commission.constants';

import { getTableColumns } from "./columns";

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import useCurrencies from 'hooks/useCurrencies';
import useDate from 'hooks/useDate';

import { USER_ROLE } from 'constants/user.constants';

import commissionPlanType from 'types/commissionPlan/commissionPlan.type';

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

/** Commission Plan Add Popup Component */
const CommissionPlanAddComponent = ({
    isSaving,
    addCommissionPlan,
    isAvailableLoading,
    availableCommissionPlans,
    getAvailableCommissionPlans,
    onClose
}) => {
    const { t } = useTranslation();

    const userInfo = useGlobalSelector(userInfoSelector);
    const userRole = useAuthSelector(userRoleSelector);

    const searchParams = useParams();

    const { search } = useLocation();
    
    const { dateService } = useDate();

    const affiliateLongId = userRole === USER_ROLE.AFFILIATE ? userInfo.longId : (new URLSearchParams(search)).get("longId");

    const [ currencies ] = useCurrencies();

    const [formInstance] = Form.useForm();
    const { validateFields } = formInstance;

    const [selected, setSelected] = useState(null);
    const [selectedType, setSelectedType] = useState("");
    const [selectedCurrency, setSelectedCurrency] = useState("");

    const filteredAvailableCommissionPlans = availableCommissionPlans.filter(p =>
        (selectedType === "" || p.type === selectedType) &&
        (selectedCurrency === "" || p.currencyCode === selectedCurrency)
    )

    // Set first as selected
    useEffect(() => {
        if (filteredAvailableCommissionPlans.length > 0) {
            setSelected(filteredAvailableCommissionPlans[0].id)
        } else {
            setSelected(null)
        }
    }, [availableCommissionPlans, selectedType, selectedCurrency])

    /** Fires when form submitted
       * @function
       * @memberOf CommissionPlanAddComponent
    */
    const handleForm = () => {
        validateFields()
            .then(data => {
                addCommissionPlan(selected, searchParams.id, data.startDate, affiliateLongId, onClose)
            }).catch(() => { })
        
    }

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                t
            },
            t
        });
    }, [t])

    //#endregion

    return (
        <Modal
            title={t('backoffice.commissionplans.addCommission')}
            cancelText={t('backoffice.common.cancel')}
            okText={t('backoffice.common.add')}
            onOk={handleForm}
            onCancel={onClose}
            isLoading={isSaving}
            disableOkButton={filteredAvailableCommissionPlans.length === 0}
            size={POPUP_SIZE.BIGER}
        >
            <Row gutter={[16, 0]}>
                <Col span={24}>
                    <Form
                        className="rt--form"
                        form={formInstance}
                        colon={false}
                        requiredMark={false}
                        layout="vertical"
                        initialValues={{
                            startDate: dateService.getNow()
                        }}
                    >
                        <Row gutter={[16, 0]}>
                            <Col span={12}>
                                <Form.Item
                                    label={t('backoffice.commissionplans.commissionType')}
                                >
                                    <Select
                                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.currency')}`}
                                        onChange={value => setSelectedType(value)}
                                        value={selectedType}
                                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                        options={[
                                            { value: "", text: t('backoffice.common.all') },
                                            { value: COMMISSION_PLAN_TYPE.REVSHARE, text: t('backoffice.commissionplans.revshare') },
                                            { value: COMMISSION_PLAN_TYPE.CPA, text: t('backoffice.commissionplans.cpa') },
                                            { value: COMMISSION_PLAN_TYPE.FIX, text: t('backoffice.commissionplans.fix') }
                                        ]}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12} >
                                <Form.Item
                                    label={t('backoffice.commissionplans.currency')}
                                >
                                    <Select
                                        placeholder={`${t('backoffice.common.select')} ${t('backoffice.commissionplans.currency')}`}
                                        value={selectedCurrency}
                                        onChange={v => setSelectedCurrency(v)}
                                        search={true}
                                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                        options={[
                                            { value: "", text: t("backoffice.common.all") },
                                            ...currencies.map(item => (
                                                { value: item.code, text: item.code }
                                            ))
                                        ]}
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12} >
                                <Form.Item
                                    label={`${t('backoffice.commissionplans.startDateTime')}`}
                                    className="rt--form-item-without-margin"
                                    name="startDate"
                                >
                                    <DatePicker
                                        className="rt--datepicker"
                                        dropdownClassName='rt--datepicker-popup rt--datepicker-withtime-popup'
                                        format={`${dateService.getFormat(true)}`}
                                        showTime={{ format: dateService.getFormat(true, true) }}
                                        showToday={false}
                                        showNow={false}
                                        allowClear={false}
                                        placeholder={t('backoffice.common.selectDate')}
                                        suffixIcon={<Icon name="date" />}
                                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                        inputReadOnly={true}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>

                <Col span={24}>
                    <Divider className='rt--form-section-divider' />

                    <div className="rt--modal-table">
                        <Table
                            loading={isAvailableLoading}
                            columns={mainTableColumns}
                            data={filteredAvailableCommissionPlans}
                            loadFn={() => getAvailableCommissionPlans(searchParams.id)}
                            noPagination={true}
                            disableFullView={true}
                            chooser={{
                                type: "radio",
                                valueIndex: "id",
                                dataIndex: "selected",
                                value: selected,
                                onChange: value => setSelected(value),
                            }}
                        />
                    </div>
                </Col>
            </Row>
        </Modal>
    )
}

/** CommissionPlanAddComponent propTypes
    * PropTypes
*/
CommissionPlanAddComponent.propTypes = {
    /** Redux state property, is true when adding commission plan request is in process */
    isSaving: PropTypes.bool,
    /** Redux action to add commission plan */
    addCommissionPlan: PropTypes.func,
    /** Redux action to load available commission plans */
    getAvailableCommissionPlans: PropTypes.func,
    /** Redux state property, is true when loading available commission plans */
    isAvailableLoading: PropTypes.bool,
    /** Redux state, represents the available commision plans of current editing affiliate  */
    availableCommissionPlans: PropTypes.arrayOf(commissionPlanType),
    /** Fires on popup close */
    onClose: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        addCommissionPlan: (id, affiliateId, startDate, affiliateLongId, onSuccess) => {
            dispatch(addCommissionPlan(id, affiliateId, startDate, affiliateLongId, onSuccess));
        },

        getAvailableCommissionPlans: id => {
            dispatch(getAvailableCommissionPlans(id));
        },
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.commissionPlans.isSaving,
        availableCommissionPlans: state.commissionPlans.availableCommissionPlans,
        isAvailableLoading: state.commissionPlans.isAvailableLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanAddComponent)