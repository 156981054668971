//#region react
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import { Form, Switch } from 'antd';

//#endregion

//#region components
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
import Table from "components/common/table";
import CommissionPlanAddComponent from '../commissionPlanAdd/commissionPlan-add.component';
//#endregion

//#region actions
import { getAffiliateCommissionPlans, setCommissionPlansSorting } from "store/actions/portal/commissionPlans/commissionPlans.action";
import { saveAffiliateNegativeCarryOver, getAffiliateNegativeCarryOver } from 'store/actions/portal/affiliates/affiliates/negativeCarryOver.action';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { addQueryStringParamsToUrl } from 'utils/queryString';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { COMMISSIONS_LIST } from 'constants/pageName.constants';
import { USER_ROLE, USER_STATE } from 'constants/user.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import commissionPlanType from "types/commissionPlan/commissionPlan.type";
import sortingType from 'types/common/sorting.type';
//#endregion

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import usePermissions from 'core/hooks/usePermission';

const ROW_UNIQUE_KEY_PROP = "id";

/** Affilaite Edit Page Commission Plan List Component */
const CommissionPlanListComponent = ({
    commissionPlans,
    getAffiliateCommissionPlans,
    setCommissionPlansSorting,
    sorting,
    isLoading,
    total,
    isNegativeCarryOverEnabled,
    saveAffiliateNegativeCarryOver,
    getAffiliateNegativeCarryOver
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const userInfo = useGlobalSelector(userInfoSelector);
    const role = useAuthSelector(userRoleSelector);

    const { pathname, search, hash } = useLocation();    
    const affiliateLongId = role === USER_ROLE.AFFILIATE ? userInfo.longId : (new URLSearchParams(search)).get("longId");

    const searchParams = useParams();

    const entityState = Number((new URLSearchParams(search)).get("state"));

    const affiliateId = role === USER_ROLE.AFFILIATE ? userInfo.id : searchParams.id;

    const navigate = useNavigate();

    const [addPopup, setAddPopup] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: COMMISSIONS_LIST });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasAddPermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN, PERMISSION_ACTION.CREATE );
    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN, PERMISSION_ACTION.MODIFY );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const showAddPopup = () => {
        setAddPopup(true);
    }

    const hideAddPopup = () => {
        setAddPopup(false);
    }

    const handleRightArrowIconClick = record => {
        navigate(
            addQueryStringParamsToUrl(
                pathname, 
                search, 
                hash, 
                {
                    commissionId: record.id,
                    commissionName: record.name
                }
            )
        )
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                t
            },
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    tableRowActions.push({
        title: t("backoffice.common.deepView"),
        icon: "right",
        onClick: handleRightArrowIconClick,
    })

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            text: t("backoffice.common.add"),
            enabled: hasAddPermission && ( entityState !== USER_STATE.IN_PROGRESS && entityState !== USER_STATE.DECLINED),
            onClick: () => showAddPopup(),
        }
    }

    //#endregion

    /** Get Negative Carry over */
    useEffect(() => {
        getAffiliateNegativeCarryOver(affiliateId)
    }, [])

    return (
        <SubTabTableDashboardLayout 
            header={headerPartsData}
        >
            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                <Form.Item
                    className='rt--form-item-without-margin'
                >
                    <Switch 
                        disabled={!hasModifyPermission}
                        checked={isNegativeCarryOverEnabled}
                        onChange={checked => saveAffiliateNegativeCarryOver(affiliateId, checked)}
                    />

                </Form.Item>
                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.commissionplans.negativeCarryOver')}</label>
            </div>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={commissionPlans}
                loadFn={nextPage => getAffiliateCommissionPlans(affiliateLongId, nextPage)}
                total={total}
                sorting={sorting}
                setSortingFn={setCommissionPlansSorting}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                actions={tableRowActions}
            />
            {
                addPopup && (
                    <CommissionPlanAddComponent
                        onClose={hideAddPopup}
                    />
                )
            }
        </SubTabTableDashboardLayout>
    )
}

/** CommissionPlanListComponent propTypes
    * PropTypes
*/
CommissionPlanListComponent.propTypes = {
    /** Redux action to get affiliate commission plans */
    getAffiliateCommissionPlans: PropTypes.func,
    /** Redux state property, is true when affiliate commission plans are loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the commission plans of current editing affiliate  */
    commissionPlans: PropTypes.arrayOf(commissionPlanType),
    /** Redux state property, commission plans sorting details */
    sorting: sortingType,
    /** Redux state property, commission plans total count */
    total: PropTypes.number,
    /** Redux action to set commission plans sorting details */
    setCommissionPlansSorting: PropTypes.func,
    /** Redux state property, affiliate negative carry over */
    isNegativeCarryOverEnabled: PropTypes.bool,
    /** Redux action to get affiliate negative carry over */
    getAffiliateNegativeCarryOver: PropTypes.func,
    /** Redux action to save affiliate negative carry over */
    saveAffiliateNegativeCarryOver: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateCommissionPlans: (affiliateId, nextPage) => {
            dispatch(getAffiliateCommissionPlans(affiliateId, nextPage));
        },

        setCommissionPlansSorting: sorting => {
            dispatch(setCommissionPlansSorting(sorting))
        },

        saveAffiliateNegativeCarryOver: (affiliateId, isNegativeCarryOverEnabled) => {
            dispatch(saveAffiliateNegativeCarryOver(affiliateId, isNegativeCarryOverEnabled))
        },

        getAffiliateNegativeCarryOver: id => {
            dispatch(getAffiliateNegativeCarryOver(id))
        },
    }
)

const mapStateToProps = state => {
    return {
        commissionPlans: state.commissionPlans.commissionPlans,
        sorting: state.commissionPlans.sorting,
        total: state.commissionPlans.total,
        isLoading: state.commissionPlans.isLoading,
        isNegativeCarryOverEnabled: state.affiliates.edit.isNegativeCarryOverEnabled
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommissionPlanListComponent)