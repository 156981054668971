export const PERMISSION_RESOURCE = {

    COMPANY: "company",
    COMPANY_SECURITY_SETTINGS: "company_SecuritySettings",
    COMPANY_GENERALINFO: "company_GeneralInfo",

    PROJECT: "project",
    PROJECT_BRANDS: "project_Brands",
    PROJECT_BRAND_URLS: "project_BrandUrls",
    PROJECT_CURRENCIES: "project_Currencies",
    PROJECT_LANGUAGE_SETTINGS: "project_LanguageSettings",
    PROJECT_REGISTRATION_CONFIGURATION: "project_RegistrationConfiguration",
    PROJECT_COMMISSION_PLAN: "project_CommissionPlan",
    PROJECT_GENERALINFO: "project_GeneralInfo",
    PROJECT_DOMAINS: "project_Domains",
    PROJECT_INTEGRATION: "project_Integration",
    PROJECT_PAYMENT_METHODS: "project_PaymentMethods",
    PROJECT_REFERRAL_SETTINGS: "project_ReferralSettings",
    PROJECT_PAYOUT_SETTINGS: "project_PayoutSettings",
    PROJECT_PROMO: "project_Promo",
    PROJECT_AFFILIATE_ACCESS_SETTINGS: "project_AffiliateAccessSettings",
    PROJECT_COMMISSION_RECALCULATION: "project_CommissionRecalculation",

    SYSTEM_LANGUAGES: "systemLanguages",
    SYSTEM_PAYMENT_METHODS: "systemPaymentMethods",
    SYSTEM_CURRENCIES: "systemCurrencies",

    COMMUNICATION: "communication",
    COMMUNICATION_PROJECT_TEMPLATES: "communication_ProjectTemplates",
    COMMUNICATION_SYSTEM_TEMPLATES: "communication_SystemTemplates",
    COMMUNICATION_NEWSLETTER: "communication_Newsletters",

    PERMISSION: "permission",
    PERMISSION_GROUP: "permission_Group",
    PERMISSION_REQUESTS: "permission_Requests",

    ADMIN: "admin",
    ADMIN_FORCE_LOGOUT: "admin_ForceLogout",
    ADMIN_GENERALINFO: "admin_GeneralInfo",
    ADMIN_SESSIONS: "admin_Sessions",
    ADMIN_COMPANY_ACCESS: "admin_CompanyAccess",
    ADMIN_PROJECT_ACCESS: "admin_ProjectAccess",
    ADMIN_PERMISSIONS: "admin_Permissions",

    ACCESS_MANAGER: "accessManager",
    ACCESS_MANAGER_GENERALINFO: "accessManager_GeneralInfo",
    ACCESS_MANAGER_SESSIONS: "accessManager_Sessions",

    AFFILIATE_MANAGER: "affiliateManager",
    AFFILIATE_MANAGER_GENERALINFO: "affiliateManager_GeneralInfo",
    AFFILIATE_MANAGER_AFFILIATES: "affiliateManager_Affiliates",
    AFFILIATE_MANAGER_SESSIONS: "affiliateManager_Sessions",
    
    TRANSLATION: "translation",

    PLAYER: "player",
    PLAYER_GENERALINFO: "player_GeneralInfo",

    AFFILIATE: "affiliate",
    AFFILIATE_GENERALINFO: "affiliate_GeneralInfo",
    AFFILIATE_FORCE_LOGOUT: "affiliate_ForceLogout",
    AFFILIATE_WALLETS: "affiliate_Wallets",
    AFFILIATE_SESSIONS: "affiliate_Sessions",
    AFFILIATE_COMMISSION_PLAN: "affiliate_CommissionPlan",
    AFFILIATE_PAYMENT_METHODS: "affiliate_PaymentMethods",
    AFFILIATE_GROUPS: "affiliate_Groups",

    PAYMENTS: "payments",
    PAYMENTS_COMMISSION_CALCULATIONS: "payments_CommissionCalculations",
    PAYMENTS_INVOICES: "payments_Invoices",
    PAYMENTS_WALLETS_HISTORY: "payments_WalletsHistory",

    MARKETING_TOOLS: "marketingTools",
    MARKETING_TOOLS_CAMPAIGN: "marketingTools_Campaign",
    MARKETING_TOOLS_CREATIVE: "marketingTools_Creative",
    MARKETING_TOOLS_MEDIA: "marketingTools_Media",

    REPORT: "report",
    REPORT_CLICKS: "report_Clicks",
    REPORT_AFFILIATE_PERFORMANCE: "report_AffiliatePerformance",
    REPORT_PLAYER_PERFORMANCE: "report_PlayerPerformance",
    REPORT_AFFILIATE_PERFORMANCE_TOTALS: "report_AffiliatePerformanceTotals",
    REPORT_PLAYER_PERFORMANCE_TOTALS: "report_PlayerPerformanceTotals",
    REPORT_MEDIA_PERFORMANCE: "report_MediaPerformance",
    REPORT_MEDIA_PERFORMANCE_TOTALS: "report_MediaPerformanceTotals",
    REPORT_AGGREGATION_HISTORY: "report_AggregationHistory",

    USER_LOGS: "userLogs",

    DEVELOPER: "developer",

    DASHBOARD: "dashboard",
    DASHBOARD_FINANCIAL: "dashboard_Financial",
    DASHBOARD_OPERATIONAL: "dashboard_Operational",
    DASHBOARD_MARKETING: "dashboard_Marketing",

    POSTBACK: "postback",
    POSTBACK_GENERALINFO: "postback_GeneralInfo",
    POSTBACK_LOGS: "postback_Logs",
}

export const PERMISSION_ACTION = {
    VIEW: "View",
    MODIFY: "Modify",
    DELETE: "Delete",
    CREATE: "Create",
    EXPORT: "Export"
}