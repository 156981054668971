//#region react
import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
import { Row, Col, Spin, Button } from "antd";
//#endregion

//#region actions
import {
    getDomainSites,
    removeDomainSite,
    removeSiteCname,
} from "store/actions/portal/projects/domains.action";
//#endregion

//#region components
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
import Table from "components/common/table";
import SiteAddComponent from "./site-add.component";
import SiteCnameAddComponent from "./cname-add.component";
import Confirmation from "components/common/confirmation";
import Status from "components/common/status";
//#endregion

//#region utils
import { tableColumnsCreator } from "utils/tableColumnsCreator";
import { isMobile } from "utils/common";
//#endregion

//#region constants
import { STATUS_TYPES } from "constants/status.constants";
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION,
} from "constants/permissions.constants";
import { tableColumns } from "./columns";

//#endregion

//#region types
import projectSiteType from "types/project/site.type";
//#endregion

import usePermissions from "core/hooks/usePermission";

/** Project Edit Page Domain Tab Sites Sub Tab Component */
const DomainSitesComponent = ({
    getDomainSites,
    removeDomainSite,
    removeSiteCname,
    isLoading,
    isSaving,
    sites,
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [isAddSitePopupVisible, setIsAddSitePopupVisible] = useState(false);
    const [addCnamePopup, setAddCnamePopup] = useState(null);

    const [deleteConfirmation, setDeleteConfirmation] = useState(null);

    useEffect(() => {
        getDomainSites();
    }, []);

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_DOMAINS, PERMISSION_ACTION.CREATE );
    const hasDeletePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_DOMAINS, PERMISSION_ACTION.DELETE );

    //#endregion

    //#region --------------------------------- HEADER DATA ---------------------------------//

    const headerPartsData = {
        button: {
            icon: "plus",
            type: "primary",
            onClick: () => setIsAddSitePopupVisible(true),
            text: t("backoffice.projects.addSite"),
            enabled: hasCreatePermission,
        },
    };

    //#endregion

    //#region --------------------------------- HANDLERS ---------------------------------//

    const hideDeleteConfirmation = () => {
        setDeleteConfirmation(null);
    };

    const handleSiteDelete = () => {
        removeDomainSite(deleteConfirmation);
        setDeleteConfirmation(null);
    };

    const tableRowActions = [
        {
            title: t("backoffice.common.delete"),
            confirmationMessage: t(
                "backoffice.projects.doYouWantToDeleteCname"
            ),
            confirmationTitle: t("backoffice.common.delete"),
            icon: "trash",
            showConfirmation: true,
            className: "rt--button-danger",
            onClick: (record) => removeSiteCname(record.id, record.siteId),
        },
    ];

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            t
        });
    }, [t]);
    //#endregion

    return (
        <SubTabTableDashboardLayout header={headerPartsData}>
            <Spin spinning={isLoading || isSaving}>
                <Row gutter={[16, 24]}>
                    {sites.map((site) => (
                        <Col md={24} sm={24} xs={24} lg={12} key={site.id}>
                            <div className="rt--card-section rt--card-section-block">
                                <div className="rt--flex rt--flex-col rt--pl-24 rt--pr-24 rt--pt-24 rt--pb-24 rt--card-section-block-inner">
                                    <div className="rt--flex rt--flex-col">
                                        <div className={"rt--flex rt--justify-between rt--mb-8 " + (isMobile() ? 'rt--flex-col rt--align-start' : '')}>
                                            <span className="rt--card-section-title rt--font-extra-bold  rt--font-bigest">
                                                {site.domain}
                                            </span>
                                            <div className={"rt--flex rt--align-center rt--justify-end rt--flex-equal " + (isMobile() ? 'rt--mt-8' : '')}>
                                                {hasCreatePermission &&
                                                    site.records?.length <
                                                        3 && (
                                                        <Button
                                                            type="primary"
                                                            className="rt--button rt--button-main"
                                                            onClick={() =>
                                                                setAddCnamePopup(
                                                                    site.id
                                                                )
                                                            }
                                                        >
                                                            <span>
                                                                {t(
                                                                    "backoffice.projects.addCname"
                                                                )}
                                                            </span>
                                                        </Button>
                                                    )}
                                                {hasDeletePermission && (
                                                    <Button
                                                        type="danger"
                                                        className="rt--button rt--button-danger rt--ml-8"
                                                        onClick={() =>
                                                            setDeleteConfirmation(
                                                                site.id
                                                            )
                                                        }
                                                    >
                                                        <span>
                                                            {t(
                                                                "backoffice.common.delete"
                                                            )}
                                                        </span>
                                                    </Button>
                                                )}
                                            </div>
                                        </div>
                                        <span className="rt--card-section-subTitle rt--font-regular rt--font-normal rt--mb-4">
                                            <span className="rt--card-section-subTitle rt--font-regular rt--font-normal rt--pr-8">
                                                {t("backoffice.common.status")}:
                                            </span>
                                            <Status
                                                type={STATUS_TYPES.DOMAIN}
                                                status={site.status}
                                            />
                                        </span>
                                        <span className="rt--card-section-subTitle rt--font-regular rt--font-normal rt--mb-4">
                                            {t(
                                                "backoffice.projects.nameServer"
                                            ) + " 1"}
                                            :
                                            <b className="rt--font-bold rt--pl-8 rt--pr-8">
                                                {site.nS1}
                                            </b>
                                        </span>
                                        <span className="rt--card-section-subTitle rt--font-regular rt--font-normal rt--mb-4">
                                            {t(
                                                "backoffice.projects.nameServer"
                                            ) + " 2"}
                                            :
                                            <b className="rt--font-bold rt--pl-8 rt--pr-8">
                                                {site.nS2}
                                            </b>
                                        </span>
                                    </div>
                                    <div className="rt--mt-16 rt--card-section-block-table">
                                        {site.records.length > 0 ? (
                                            <Table
                                                loading={isLoading}
                                                columns={mainTableColumns}
                                                data={site.records.map((r) => ({
                                                    ...r,
                                                    siteId: site.id,
                                                }))}
                                                noPagination={true}
                                                disableFullView={true}
                                                actions={tableRowActions}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </Col>
                    ))}
                </Row>
            </Spin>
            {isAddSitePopupVisible && (
                <SiteAddComponent
                    onClose={() => setIsAddSitePopupVisible(false)}
                />
            )}
            {addCnamePopup && (
                <SiteCnameAddComponent
                    onClose={() => setAddCnamePopup(null)}
                    id={addCnamePopup}
                />
            )}

            <Confirmation
                title={t("backoffice.projects.removeSite")}
                message={t("backoffice.projects.doYouWantToDeleteSite")}
                onOk={handleSiteDelete}
                onCancel={hideDeleteConfirmation}
                isVisible={Boolean(deleteConfirmation)}
            />
        </SubTabTableDashboardLayout>
    );
};

DomainSitesComponent.propTypes = {
    /** Redux action to get Project Sites */
    getDomainSites: PropTypes.func,
    /** Redux action to delete Project Site */
    removeDomainSite: PropTypes.func,
    /** Redux action to delete Project Site Cname */
    removeSiteCname: PropTypes.func,
    /** Redux state property, is true when loading project payments */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when changing Project Payment State */
    isSaving: PropTypes.bool,
    /** Redux state, represents the Payments of current editing Project */
    sites: PropTypes.arrayOf(projectSiteType),
};

const mapDispatchToProps = (dispatch) => ({
    getDomainSites: () => {
        dispatch(getDomainSites());
    },
    removeDomainSite: (id) => {
        dispatch(removeDomainSite(id));
    },
    removeSiteCname: (recordId, id) => {
        dispatch(removeSiteCname(recordId, id));
    },
});

const mapStateToProps = (state) => {
    return {
        sites: state.projects.edit.sites,
        isLoading: state.projects.isLoading,
        isSaving: state.projects.isSaving,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DomainSitesComponent);
