import { message } from 'antd';

import axios from "core/lib/axios";

import Methods from "constants/methods.constants";
import ApiUrls from "constants/api.constants";

import { isResponseSuccess } from "utils/request";

import { 
    setAffiliatesActionBefore, 
    setAffiliatesActionFinished, 
    setAffiliatesSaveActionBefore, 
    setAffiliatesSaveActionFinished 
} from './affiliates.action';

import { SET_AFFILIATE_NEGATIVE_CARRY_OVER } from "../../../../actionTypes";


const setAffiliateNegativeCarryOver = isNegativeCarryOverEnabled => ({
    type: SET_AFFILIATE_NEGATIVE_CARRY_OVER,
    payload: { isNegativeCarryOverEnabled }
})


export const getAffiliateNegativeCarryOver = id => {
    return dispatch => {
        dispatch(setAffiliatesActionBefore());

        return axios({
            url: ApiUrls.GET_AFFILIATE_NEGATIVE_CARRY_OVER,
            method: Methods.GET,
            params: {
                id
            }
        })
        .then(({data}) => {
            dispatch(setAffiliateNegativeCarryOver(data.value?.isNegativeCarryOverEnabled))
            dispatch(setAffiliatesActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesActionFinished());
        })
    }
}

export const saveAffiliateNegativeCarryOver = (affiliateId, isNegativeCarryOverEnabled) => {
    return dispatch => {
        dispatch(setAffiliatesSaveActionBefore());
        dispatch(setAffiliateNegativeCarryOver(isNegativeCarryOverEnabled))
        return axios({
            url: ApiUrls.SAVE_AFFILIATE_NEGATIVE_CARRY_OVER,
            method: Methods.POST,
            data: {
                affiliateId, 
                isNegativeCarryOverEnabled
            }
        })
        .then(({ data }) => {
            if (isResponseSuccess(data)) {
                message.success(data.message);
            } 
            dispatch(setAffiliatesSaveActionFinished());
        })
        .catch((ex) => {
            dispatch(setAffiliatesSaveActionFinished());
        })
    }
}
