import React, { Fragment } from "react"
import PropTypes from "prop-types";
import { useTranslation } from "core/hooks/useTranslation";

import { Button, Row, Col } from "antd";

import Select from "components/common/select";
import FormId from "components/common/formId";
import Status from 'components/common/status';
import Icon from 'components/common/icon';

import { isMobile } from "utils/common";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';

import buttonType from 'types/components/button.type';
import statusType from 'types/status/status.type';
import usePermissions from "core/hooks/usePermission";

const PLACEMENT = {
    RIGHT: "right",
    LEFT: "left"
}

/** Sub Tab Form dashboard layout */
const SubTabFormDashboardLayout = ({
    children,
    buttons = [],
    actions,
    id,
    longId,
    alwaysShowId=false,
    status,
    header,
    dropdown
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const isMobileDevice = isMobile();

    const enabledRightPlacedButtons = buttons.filter(
        buttonProps => buttonProps.placement !== PLACEMENT.LEFT && buttonProps.enabled
    );
    const enabledLeftPlacedButtons = buttons.filter(
        buttonProps => buttonProps.placement === PLACEMENT.LEFT && buttonProps.enabled
    );

    const hasEnabledButtons = enabledRightPlacedButtons.length + (isMobileDevice ? 0 : enabledLeftPlacedButtons.length) > 0;

    /** Function to Render dropdown 
         * @function
         * @returns {JSX}
         * @memberOf SubTabFormDashboardLayout
    */
    const renderDropdown = () => (
        <Select
            options={
                dropdown.items.map(item => ({ value: item.key, text: item.value }))
            }
            placeholder={dropdown.placeholder}
            value={dropdown.value}
            onChange={dropdown.onChange}
            className={"rt--subtab-form-layout-actions-dropdown" + (dropdown.small ? " rt--subtab-form-layout-actions-dropdown-small" : "")}
            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
        />
    )

    return (
        <div className="rt--subtab-form-layout">
            <div className="rt--subtab-form-layout-inner rt--flex rt--flex-col">
                <div className={"rt--subtab-form-layout-content rt--flex rt--flex-col rt--flex-equal " + (isMobile() ? "rt--pl-16 rt--pr-16 rt--pt-16 rt--pb-16" : "rt--pl-24 rt--pr-24 rt--pt-24 rt--pb-24")}>
                    {
                        (id || longId) && (
                            <Row gutter={[16, 0]}>
                                <Col span={24} >
                                    <div className={'rt--flex rt--justify-between rt--align-center ' + (isMobile() ? "rt--mb-16" : "rt--mb-24")}>
                                        <div className="rt--subtab-form-layout-header rt--flex rt--align-center rt--flex-equal">
                                            {
                                                (
                                                    id && (
                                                        alwaysShowId ||
                                                        permissionUtils.has( PERMISSION_RESOURCE.DEVELOPER, PERMISSION_ACTION.VIEW )
                                                    )
                                                ) && (
                                                    <FormId id={id} text={t("backoffice.common.id")} />
                                                )
                                            }
                                            {longId && (
                                                <div className="rt--ml-16">
                                                    <FormId
                                                        id={longId}
                                                        text={t("backoffice.common.longId")}
                                                    />
                                                </div>
                                            )}
                                            {status && (
                                                <div className="rt--ml-16">
                                                    <Status {...status} />
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        )
                    }

                    <div className="rt--subtab-form-layout-content-content rt--flex rt--flex-col rt--flex-equal">
                        {
                            header && header.button && header.button.enabled && (
                                <div className={"rt--subtab-form-layout-content-content-header rt--align-center rt--flex rt--jsutify-brtween " + (!isMobile() ? "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16" : "rt--pl-12 rt--pr-12 rt--pt-12 rt--pb-12")}>
                                    <Button
                                        icon={<Icon name={header.button.icon} />}
                                        type={header.button.type}
                                        className={
                                            "rt--button " +
                                            (header.button.className ? header.button.className : "") +
                                            (header.button.type === "primary" ? "rt--button-main" : "")
                                        }
                                        onClick={header.button.onClick}
                                        disabled={header.button.disabled ? true : false}
                                    >
                                        {
                                            header.button.text
                                        }
                                    </Button>
                                    {
                                        isMobile() && dropdown ? <div className="rt--ml-8">{renderDropdown()}</div> : <Fragment />
                                    }
                                </div>
                            )
                        }
                        <div className={"rt--subtab-form-layout-content-content-form rt--flex-equal"}>
                            {children}
                        </div>
                    </div>

                </div>
                {
                    (actions || dropdown) && (
                        <div className="rt--subtab-form-layout-actions">
                            {
                                actions ? actions : <Fragment />
                            }
                            {
                                dropdown && !isMobile() ? renderDropdown() : <Fragment />
                            }

                        </div>
                    )
                }

                {
                    hasEnabledButtons && (
                        <div
                            className={
                                ("rt--subtab-form-layout-footer") + (isMobileDevice ? " rt--flex" : " rt--flex rt--align-center") +
                                (isMobileDevice ? "rt--pl-16 rt--pr-16 rt--pt-8 rt--pb-8" : "rt--pl-24 rt--pr-24 rt--pt-16 rt--pb-16") +
                                ((!isMobileDevice && enabledLeftPlacedButtons.length > 0) ? " rt--justify-between" : " rt--justify-end")
                            }
                        >
                            {
                                (!isMobileDevice && enabledLeftPlacedButtons.length > 0) && (
                                    <div className="rt--flex rt--align-center">
                                        {
                                            enabledLeftPlacedButtons.map(buttonProps => {
                                                const { type, className = "rt--button-primary", onClick, text, disabled = false, htmlType = "submit", loading } = buttonProps;
                                                return (
                                                    <Button
                                                        type={type}
                                                        className={"rt--button " + (className)}
                                                        onClick={onClick}
                                                        key={text}
                                                        disabled={disabled}
                                                        htmlType={htmlType}
                                                        loading={loading}
                                                    >
                                                        {
                                                            text
                                                        }

                                                    </Button>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }

                            {
                                enabledRightPlacedButtons.length > 0 && (
                                    <div
                                        className={
                                            "rt--flex rt--align-center" +
                                            (isMobileDevice && enabledRightPlacedButtons.length > 1 ? " rt--justify-between" : " rt--justify-end")
                                        }
                                    >
                                        {
                                            enabledRightPlacedButtons.map(buttonProps => {
                                                const { type, className = "", onClick, text, disabled = false, htmlType = "submit", loading } = buttonProps;

                                                return (
                                                    <Button
                                                        type={type}
                                                        className={"rt--button rt--button-primary" + (className)}
                                                        onClick={onClick}
                                                        key={text}
                                                        disabled={disabled}
                                                        htmlType={htmlType}
                                                        loading={loading}
                                                    >
                                                        {
                                                            text
                                                        }

                                                    </Button>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    )
                }

            </div>
        </div>
    )
}

/** SubTabFormDashboardLayout propTypes
 * PropTypes
*/
SubTabFormDashboardLayout.propTypes = {
    /** Layout Content */
    children: PropTypes.node,
    /** Buttons */
    buttons: PropTypes.arrayOf(PropTypes.shape(buttonType)),
    /** Actions */
    actions: PropTypes.node,
    /** The id */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** The longId */
    longId: PropTypes.number,
    /** If true, will always show id, even if not have developer permissino */
    alwaysShowId: PropTypes.bool,
    /** Status component props */
    status: statusType,
    /** Header */
    header: PropTypes.shape({
        /** Header button */
        button: PropTypes.shape(buttonType)
    }),
    /** Dropdown options */
    dropdown: PropTypes.object,
}

export default SubTabFormDashboardLayout;