import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from "components/common/tabs";

import PostbackComponent from './postback';

/** Project Edit Page Configurations Tab Component */
const ConfigurationsComponent = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.projects.postback"),
            component: <PostbackComponent/>
        }
    ]

    return (
        <Tabs.SubTabs 
            items={items}
            onTabChange={onTabChange}
        />
    )
}

/** ConfigurationsComponent propTypes
    * PropTypes
*/
ConfigurationsComponent.propTypes = {
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

export default ConfigurationsComponent;
