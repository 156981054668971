import React, { useState, useEffect, memo } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from "core/hooks/useTranslation";

import { DatePicker } from 'antd';

import Icon from "components/common/icon";

import useDate from 'hooks/useDate';

import { classNames, isMobile, getFilterRanges } from 'utils/common';
import { getShowTimeOptionValue } from './helpers';

import { TIME_PICKER_MODES } from './constants';

/** DateRangePicker Component */
const DateRangePicker = ({
    showTime,
    timePickerMode = TIME_PICKER_MODES.DEFAULT,
    onChange,
    value,
    allowClear = true,
    disabledRanges = [],
    enabledMountsCount = 3,
    rangeMaxDaysCount
}) => {

    const { t } = useTranslation();

    const [opened, setOpened] = useState(false);

    const [dates, setDates] = useState(null);

    const [selectedRange, setSelectedRange] = useState(null)

    const { dateService } = useDate();

    const format = isMobile() && timePickerMode === TIME_PICKER_MODES.ONLY_HOURS ? dateService.getFormat(false) + " " + "HH:mm" : dateService.getFormat(showTime)

    const disabledDate = currentDate => {
        if(rangeMaxDaysCount){
            if (dates) {
                console.log(
                    dateService.getDifference(currentDate, dates[0], 'days'),
                    dateService.getDifference(dates[1], currentDate, 'days')
                )
                const tooLate = dates[0] && dateService.getDifference(currentDate, dates[0], 'days') > rangeMaxDaysCount - 1;
                const tooEarly = dates[1] && dateService.getDifference(dates[1], currentDate, 'days') > rangeMaxDaysCount - 1;
                const isOutOfRange =  !!tooEarly || !!tooLate;
                if(isOutOfRange){
                    return true
                }
            }
            
        }

        if (showTime) {
            return !dateService.isInRange(currentDate, dateService.monthsAgo(enabledMountsCount), dateService.endOfDay())
        } else {
            if (!currentDate) return false;

            if (dateService.isAfter( currentDate, dateService.endOfTomorrow() ) ) {
                 return true
            }

            return false;
        }

    }

    const handleInternalChange = (...args) => {
        const [startDate, endDate] = args[0] ? args[0] : [null, null];

        if (!showTime && startDate && endDate) {
            if(dateService.isSame(startDate, endDate, "day")){
                return;
            }
        }

        onChange && onChange(...args);
    }

    const pickerRanges = showTime ? getFilterRanges(disabledRanges, t) : [];

    const handleSelectPreset = (range) => {
        handleInternalChange(range.value);
        setSelectedRange(range.key);
        setOpened(false);
    }

    const handleOpenChange = value => {
        setOpened(value);
    }

    useEffect(() => {
        if(!value || !value[0] || !value[1]){
            setSelectedRange(null);
            return;
        };

        let selected = null;

        pickerRanges.forEach(range => {
            if(
                dateService.isSame(value[0], range.value[0]) &&
                dateService.isSame(value[1], range.value[1])
            ) {
                selected = range.key;
                return;
            }
        })

        setSelectedRange(selected);
        
    }, [value])

    return (
        <DatePicker.RangePicker
            open={opened}
            onOpenChange={handleOpenChange}
            onCalendarChange={setDates}
            placeholder={[t('backoffice.common.from'), t('backoffice.common.to')]}
            format={format}
            disabledDate={disabledDate}
            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
            //ranges={pickerRanges}
            showTime={getShowTimeOptionValue({ showTime, timePickerMode, dateService })}
            allowClear={allowClear}
            inputReadOnly={isMobile()}
            onChange={handleInternalChange}
            value={dates || value}
            suffixIcon={<Icon name="date" />}
            separator={<div className='rt--datepicker-separator' />}
            className={classNames(
                "rt--datepicker",
                showTime && "rt--datepicker-withtime",
            )}
            dropdownClassName={classNames(
                "rt--datepicker-popup",
                showTime && "rt--datepicker-withtime-popup",
                (showTime && timePickerMode === TIME_PICKER_MODES.ONLY_HOURS) && "rt--datepicker-popup-only-hours",
            )}
            renderExtraFooter={() => (
                pickerRanges.map(range => (
                    <button 
                        className={'rt--datepicker-footer-button' + ( selectedRange === range.key ? " rt--datepicker-footer-button-selected" : "")} 
                        onClick={() => handleSelectPreset(range)}
                        key={range.key}
                    >
                        {range.label}
                    </button>
                ))
            )}
        />
    )
}

/** DateRangePicker propTypes
    * PropTypes
*/
DateRangePicker.propTypes = {
    /** Show time picker */
    showTime: PropTypes.bool,
    /** On Change function */
    onChange: PropTypes.func,
    /** Value */
    value: PropTypes.array,
    /** Allow empty picker value */
    allowClear: PropTypes.bool,
    /** Modes of Time Picker */
    timePickerMode: PropTypes.oneOf([
        TIME_PICKER_MODES.DEFAULT,
        TIME_PICKER_MODES.ONLY_HOURS
    ]),
    /** Disabled predefined ranges */
    disabledRanges: PropTypes.array,
    /** Depend predefined ranges enabled mounts count */
    enabledMountsCount: PropTypes.number,
    /** Range max days count */
    rangeMaxDaysCount: PropTypes.number
}

export default memo(DateRangePicker);