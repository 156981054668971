//#region react
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams, useLocation } from "react-router-dom";
//#endregion

//#region actions
import {
    getAffiliateGroupMembers,
    setAffiliateGroupMembersFilters,
    setAffiliateGroupMembersSorting,
    deleteAffiliateGroupMember
} from "store/actions/portal/affiliates/groups/members.action";
import { getAffiliateGroupGeneralInfo } from "store/actions/portal/affiliates/groups/general.action";

//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";

import MembersModifyComponent from './members-modify.component';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { getTableColumns } from "./columns";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { AFFILIATE_GROUP_MEMBERS } from 'constants/pageName.constants';
import { AFFILIATE_GROUP_TYPE } from 'constants/affiliate.constants';
//#endregion

//#region types
import affiliateType from 'types/affiliate/generalInfo.type';
import affiliateGroupType from 'types/affiliate/group.type';
import sortingType from 'types/common/sorting.type';
//#endregion

import { useAuthSelector, userSelector } from 'core/stores/authStore';
import usePermissions from 'core/hooks/usePermission';

/** Affiliate Group Edit Page Members Tab Component */
const MembersComponent = ({
    members,
    getAffiliateGroupMembers,
    setAffiliateGroupMembersFilters,
    setAffiliateGroupMembersSorting,
    deleteAffiliateGroupMember,
    getAffiliateGroupGeneralInfo,
    isLoading,
    sorting,
    filters,
    generalInfo,
    total
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();
    const { search } = useLocation();

    const permissionUtils = usePermissions();

    const { userName } = useAuthSelector(userSelector);

    const groupType = Number((new URLSearchParams(search)).get("groupType"));

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: AFFILIATE_GROUP_MEMBERS });

    const [modifyPopup, setModifyPopup] = useState(false)

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_GROUPS, PERMISSION_ACTION.MODIFY )

    const canEdit = hasModifyPermission && ( generalInfo.createdBy === userName || generalInfo.editableByOtherUsers)

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setAffiliateGroupMembersFilters({
            ...filters,
            nameOrId: value
        })
    }

    const showModifyPopup = () => {
        setModifyPopup(true)
    }

    const hideModifyPopup = () => {
        setModifyPopup(false)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            includedColumns: includedColumns,
            constructForInclude: true,
            additionalProps: {
                t
            },
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (canEdit && groupType === AFFILIATE_GROUP_TYPE.STATIC) {
        tableRowActions.push({
            title: t('backoffice.common.remove'),
            confirmationMessage: t("backoffice.affiliates.doYouWantToRemoveAffiliateFromGroup"),
            confirmationTitle: t("backoffice.affiliates.removeAffiliate"),
            icon: "trash",
            showConfirmation: true,
            onClick: record => deleteAffiliateGroupMember(record.id, searchParams.id)
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        search: {
            placeholder: t("backoffice.affiliates.usernameOrId"),
            onSearch: handleSearchChange,
            loadFn: () => getAffiliateGroupMembers(searchParams.id),
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AFFILIATE_GROUP_MEMBERS
            }
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "edit",
            type: "primary",
            text: t("backoffice.affiliates.modifyMembers"),
            onClick: showModifyPopup,
            enabled: canEdit
        },
    }

    //#endregion

    /** Get group general info */
    useEffect(() => {
        getAffiliateGroupGeneralInfo(searchParams.id);
    }, [])

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={members}
                loadFn={nextPage => getAffiliateGroupMembers(searchParams.id, nextPage)}
                setSortingFn={setAffiliateGroupMembersSorting}
                sorting={sorting}
                total={total}
                actions={tableRowActions}
            />

            {
                modifyPopup && (
                    <MembersModifyComponent 
                        onClose={hideModifyPopup}
                        total={total}
                        groupType={groupType}
                    />
                )
            }
        </TabTableDashboardLayout>
    )
}

/** MembersComponent propTypes
    * PropTypes
*/
MembersComponent.propTypes = {
    /** Redux action to get affiliate group members */
    getAffiliateGroupMembers: PropTypes.func,
    /** Redux state property, is true when affiliate group members is loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the members of current editing affiliate group  */
    members: PropTypes.arrayOf(affiliateType),
    /** Redux action to set affiliate group members sorting */
    setAffiliateGroupMembersSorting: PropTypes.func,
    /** Redux action to set affiliate group members filters */
    setAffiliateGroupMembersFilters: PropTypes.func,
    /** Redux action to delete group member from affiliate group */
    deleteAffiliateGroupMember: PropTypes.func,
    /** Redux action to get affiliate group General info */
    getAffiliateGroupGeneralInfo: PropTypes.func,
    /** Redux state property, represents the sorting data of members of current editing affiliate group */
    sorting: sortingType,
    /** Redux state property, represents the filters data of members of current editing affiliate group */
    filters: PropTypes.object,
    /** Redux state property, represents the total numbers of members of current editing affiliate group */
    total: PropTypes.number,
    /** Redux state property, current editing affiliate group */
    generalInfo: affiliateGroupType
}

const mapDispatchToProps = dispatch => (
    {
        getAffiliateGroupMembers: (id, nextPage) => {
            dispatch(getAffiliateGroupMembers(id, nextPage));
        },

        setAffiliateGroupMembersSorting: sorting => {
            dispatch(setAffiliateGroupMembersSorting(sorting))
        },

        setAffiliateGroupMembersFilters: filters => {
            dispatch(setAffiliateGroupMembersFilters(filters))
        },

        deleteAffiliateGroupMember: (id, groupId) => {
            dispatch(deleteAffiliateGroupMember(id, groupId))
        },

        getAffiliateGroupGeneralInfo: id => {
            dispatch(getAffiliateGroupGeneralInfo(id));
        },
    }
)

const mapStateToProps = state => {
    return {
        members: state.affiliateGroups.edit.members.members,
        isLoading: state.affiliateGroups.isLoading,
        sorting: state.affiliateGroups.edit.members.sorting,
        filters: state.affiliateGroups.edit.members.filters,
        total: state.affiliateGroups.edit.members.total,
        generalInfo: state.affiliateGroups.edit.general
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(MembersComponent)