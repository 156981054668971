//#region react
import React, { useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getPendingInvoices,
    setPendingInvoicesSorting,
    setPendingInvoicesFilters
} from 'store/actions/portal/payment/invoices/pending.action';
import {
    rejectInvoice,
    exportInvoice,
    deleteInvoice,
    getProjectInvoiceGenerationType
} from "store/actions/portal/payment/invoices/invoices.action";
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from "./filters.component";
import InvoiceCreateComponent from '../invoice-create.component';
import InvoiceEditComponent from '../invoice-edit.component';
import InvoicePayComponent from '../invoice-pay.component';
import InvoicesTotals from "../totals";
import InvoiceDetails from '../invoiceDetails';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useFormat from 'hooks/useFormat';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { isMobile } from 'utils/common';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import ApiUrls from 'constants/api.constants';
import { INVOICE_HISTORY_TYPE } from 'constants/invoice.constants';
import { getTableColumns } from './columns';
import { EXPAND_TABLE_TYPES } from 'constants/common.constants';
import { INVOICE_GENERATION_TYPE } from 'constants/commission.constants';
import { PENDING_INVOICES } from 'constants/pageName.constants';
import { USER_ROLE } from 'constants/user.constants';
//#endregion

//#region types
import invoiceType from 'types/payment/invoice.type';
import sortingType from 'types/common/sorting.type';
//#endregion

import { globalProjectIdSelector, useAuthSelector, userRoleSelector } from 'core/stores/authStore';
import usePermissions from 'core/hooks/usePermission';

/** Pending Invoices Component */
const PendingInvoicesComponent = ({
    invoices,
    sorting,
    filters,
    total,
    isLoading,
    getPendingInvoices,
    setPendingInvoicesSorting,
    setPendingInvoicesFilters,
    rejectInvoice,
    exportInvoice,
    deleteInvoice,
    getProjectInvoiceGenerationType,
    invoiceGenerationType
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);
    const role = useAuthSelector(userRoleSelector)

    const { formatAmount } = useFormat();

    const [createPopup, setCreatePopup] = useState(false);

    const [editingItem, setEditingItem] = useState(null);

    const [payingItem, setPayingItem] = useState(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PENDING_INVOICES });


    /** Get invoice generation type */
    useEffect(() => {
        getProjectInvoiceGenerationType();
    }, [globalProjectId])

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.PAYMENTS_INVOICES, PERMISSION_ACTION.CREATE )
    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PAYMENTS_INVOICES, PERMISSION_ACTION.MODIFY )
    const hasDeletePermission = permissionUtils.has( PERMISSION_RESOURCE.PAYMENTS_INVOICES, PERMISSION_ACTION.DELETE )
    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.PAYMENTS_INVOICES, PERMISSION_ACTION.EXPORT )

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setPendingInvoicesFilters({
            ...filters,
            affiliateNameOrId: value
        })
    }

    const handleDateSearchChange = value => {
        setPendingInvoicesFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    // columnsThatCanBeIncluded and columnsForExport are only needed in the desktop version.
    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                formatAmount
            },
            t
        })
    }, [includedColumns, t]);

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasDataExportPermission) {
        tableRowActions.push({
            title: t('backoffice.invoices.downloadPDF'),
            icon: "file",
            onClick: record => exportInvoice(record.id, record.createdAt, record.affiliateId),
        })
    }


    if (hasModifyPermission) {
        tableRowActions.push({
            title: t('backoffice.invoices.pay'),
            icon: "pay",
            onClick: record => setPayingItem(record),
        })

        tableRowActions.push({
            title: t('backoffice.common.cancel'),
            confirmationMessage: t("backoffice.invoices.doYouWanttoCancelInvoice"),
            confirmationTitle: t("backoffice.invoices.cancelInvoice"),
            icon: "reject",
            showConfirmation: true,
            onClick: record => rejectInvoice(record.id, record.createdAt),
        })

        tableRowActions.push({
            title: t("backoffice.common.edit"),
            icon: "edit",
            onClick: record => setEditingItem(record),
        })
    }

    if (hasDeletePermission) {
        tableRowActions.push({
            title: t('backoffice.common.delete'),
            confirmationMessage: t("backoffice.invoices.doYouWanttoDeleteInvoice"),
            confirmationTitle: t("backoffice.invoices.deleteInvoice"),
            icon: "trash",
            showConfirmation: true,
            onClick: record => deleteInvoice(record.id, record.createdAt),
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        search: {
            onSearch: handleSearchChange,
            loadFn: getPendingInvoices,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.AFFILIATES,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.invoices.affiliateUserNameOrId"),
        },
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.common.dateAndTime'),
            showTime: true,
            loadFn: getPendingInvoices,
            value: [filters.from, filters.to]
        },
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            onClick: () => setCreatePopup(true),
            text: t("backoffice.invoices.createInvoice"),
            enabled: hasCreatePermission && (
                (role === USER_ROLE.AFFILIATE && invoiceGenerationType === INVOICE_GENERATION_TYPE.BY_AFFILIATE) ||
                (role !== USER_ROLE.AFFILIATE && invoiceGenerationType === INVOICE_GENERATION_TYPE.BY_COMPANY)
            )
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.invoices.pending"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_PENDING_INVOICES,
            filters: filters
        }
    }

    if (isMobile()) {
        headerPartsData.content = <InvoicesTotals type={INVOICE_HISTORY_TYPE.PENDING} />
    }

    //#endregion

    return (
        <TabTableDashboardLayout
            header={headerPartsData}
            extraContent={
                !isMobile() && (
                    <InvoicesTotals type={INVOICE_HISTORY_TYPE.PENDING} />
                )
            }
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={invoices}
                loadFn={getPendingInvoices}
                sorting={sorting}
                setSortingFn={setPendingInvoicesSorting}
                total={total}
                updateProps={[globalProjectId]}
                actions={tableRowActions}
                expandable={{
                    title: t("backoffice.invoices.requisiteDetails"),
                    type: EXPAND_TABLE_TYPES.CONTENT,
                    details: record => ({
                        data: record.invoiceRequisiteDetails,
                        content: (
                            <InvoiceDetails
                                details={
                                    [
                                        ...record.invoiceRequisiteDetails,
                                        { key: "attachmentPath", value: record.attachmentPath }
                                    ]
                                }
                                paymentId={record.paymentId}
                            />
                        ),
                    }),
                }}
            />

            {
                createPopup && (
                    <InvoiceCreateComponent
                        onClose={() => setCreatePopup(false)}
                    />
                )
            }

            {
                editingItem && (
                    <InvoiceEditComponent
                        onClose={() => setEditingItem(null)}
                        invoice={editingItem}
                    />
                )
            }

            {
                payingItem && (
                    <InvoicePayComponent
                        onClose={() => setPayingItem(null)}
                        invoice={payingItem}
                    />
                )
            }
        </TabTableDashboardLayout>
    )
}

/** PendingInvoicesComponent propTypes
    * PropTypes
*/
PendingInvoicesComponent.propTypes = {
    /** Redux state property, represents the array of pending invoices */
    invoices: PropTypes.arrayOf(invoiceType),
    /** Redux state property, pending invoices sorting details */
    sorting: sortingType,
    /** Redux state property, pending invoices filters */
    filters: PropTypes.object,
    /** Redux state property, pending invoices total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading pending invoices */
    isLoading: PropTypes.bool,
    /** Redux action to get pending invoices */
    getPendingInvoices: PropTypes.func,
    /** Redux action to set pending invoices sorting details */
    setPendingInvoicesSorting: PropTypes.func,
    /** Redux action to set pending invoices filters */
    setPendingInvoicesFilters: PropTypes.func,
    /** Redux action to reject invoice */
    rejectInvoice: PropTypes.func,
    /** Redux action to delete invoice */
    deleteInvoice: PropTypes.func,
    /** Redux action to export invoice */
    exportInvoice: PropTypes.func,
    /** Redux state property, project invoice generation type */
    invoiceGenerationType: PropTypes.oneOf(Object.values(INVOICE_GENERATION_TYPE)),
    /** Redux action to get project invoice generation type */
    getProjectInvoiceGenerationType: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getPendingInvoices: nextPage => {
            dispatch(getPendingInvoices(nextPage))
        },
        setPendingInvoicesSorting: sorting => {
            dispatch(setPendingInvoicesSorting(sorting));
        },
        setPendingInvoicesFilters: filters => {
            dispatch(setPendingInvoicesFilters(filters));
        },
        rejectInvoice: (id, createdAt) => {
            dispatch(rejectInvoice(id, createdAt));
        },

        deleteInvoice: (id, createdAt) => {
            dispatch(deleteInvoice(id, createdAt));
        },

        exportInvoice: (id, createdAt, affiliateId) => {
            dispatch(exportInvoice(id, createdAt, affiliateId));
        },

        getProjectInvoiceGenerationType: () => {
            dispatch(getProjectInvoiceGenerationType())
        }
    }
)

const mapStateToProps = state => {
    return {
        invoices: state.invoices.pending.invoices,
        total: state.invoices.pending.total,
        sorting: state.invoices.pending.sorting,
        filters: state.invoices.pending.filters,
        isLoading: state.invoices.isLoading,
        invoiceGenerationType: state.invoices.invoiceGenerationType
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PendingInvoicesComponent)