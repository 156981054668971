import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";

import { Form, Row, Col, Spin } from 'antd';

import TabFormDashboardLayout from "components/layouts/tab/form";

import Input from 'components/common/input';
import Select from 'components/common/select';
import Tooltip from 'components/common/tooltip';
import Icon from 'components/common/icon';
import CampaignActionsComponent from '../../campaign-actions.component';

import { DESCRIPTION_REGEX, NAME_REGEX } from "constants/regex.constants";
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import { PROJECT_BRAND_PRODUCT_TYPE } from 'constants/project.constants';
import { USER_STATE } from 'constants/user.constants';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { getCampaignGeneralInfo, saveCampaignGeneralInfo } from "store/actions/portal/marketingTools/campaigns/general.action";

import campaignGeneralInfoType from 'types/campaign/generalInfo.type';

import { flagsToBinary } from 'utils/common';
import { isFormChanged } from "utils/form";

import useAutosuggestion from 'hooks/useAutosuggestion';

import countries from 'systemData/countries';
import usePermissions from 'core/hooks/usePermission';


/** Company Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    generalInfo,
    getCampaignGeneralInfo,
    saveCampaignGeneralInfo,
    isSaving,
    isLoading,
    onTabChange
}) => {
    const { t } = useTranslation();
    const searchParams = useParams();

    const permissionUtils = usePermissions();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [isPrivate, setIsPrivate] = useState("");

    const [ affiliateNames ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.AFFILIATES,
        actionParams: { state: flagsToBinary([USER_STATE.ACTIVE, USER_STATE.LOCKED_LOGIN, USER_STATE.LOCKED_2_FA]) }
    })

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, PERMISSION_ACTION.MODIFY )

    /** Get general info */
    useEffect(() => {
        getCampaignGeneralInfo(searchParams.id);
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            name: generalInfo.name,
            isPrivate: generalInfo.isPrivate,
            affiliateIds: generalInfo.affiliates ? Object.keys(generalInfo.affiliates) : [],
            description: generalInfo.description,
            countryCodes: generalInfo.countryCodes
        });
        setIsPrivate(generalInfo.isPrivate)
    }, [generalInfo])

    /** Check is form changed
       * @function
       * @param {object} formValues - form current values
       * @returns {boolean}
       * @memberOf GeneralInfoComponent
   */
    const formChanged = formValues => {
        return isFormChanged(
            {
                ...formValues,
                countryCodes: formValues.countryCodes.length || generalInfo.countryCodes !== null ? formValues.countryCodes : null
            },
            {
                name: generalInfo.name,
                isPrivate: generalInfo.isPrivate,
                affiliateIds: generalInfo.affiliates ? Object.keys(generalInfo.affiliates) : [],
                description: generalInfo.description,
                countryCodes: generalInfo.countryCodes
            }
        )
    }

    /** Fires when form submitted
       * @function
       * @memberOf GeneralInfoComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveCampaignGeneralInfo({
                    ...data,
                    id: searchParams.id
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    const productName = generalInfo.product === PROJECT_BRAND_PRODUCT_TYPE.CASINO ?
        t(`backoffice.projects.casino`) : generalInfo.product === PROJECT_BRAND_PRODUCT_TYPE.SPORT ?
            t(`backoffice.projects.sport`) : generalInfo.product === PROJECT_BRAND_PRODUCT_TYPE.OTHER ?
                t(`backoffice.projects.other`) : "-";

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
            id={generalInfo.id}
            longId={generalInfo.longId}
            actions={<CampaignActionsComponent />}
        >
            <Spin
                spinning={isLoading}
                wrapperClassName="rt--form-spin"
            >
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(changed, formValues) => setIsFormTouched(formChanged({ ...formValues }))}
                >
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={`${t('backoffice.campaigns.name')} *`}
                                name="name"
                                rules={[
                                    { required: true, whitespace: true, message: t('backoffice.validation.fieldRequired') },
                                    { max: 30, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: NAME_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                className={'rt--general-form-item' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.campaigns.name')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.campaigns.name')}`}
                                    maxLength={30}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={
                                    <div className="rt--flex rt--align-center">
                                        {t('backoffice.campaigns.availablility')}
                                        <Tooltip
                                            title={t(`backoffice.campaigns.availablilityTooltip`)}
                                            trigger={["hover", "click"]}
                                            placement="bottomLeft"
                                            enableMobile={true}
                                        >
                                            <Icon name='info' size={14} className='rt--ml-6' />
                                        </Tooltip>
                                    </div>
                                }
                                name="isPrivate"
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') },
                                ]}
                                className={!hasModifyPermission ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={[
                                        { value: true, text: t('backoffice.campaigns.private') },
                                        { value: false, text: t('backoffice.campaigns.public') }
                                    ]}
                                    onChange={value => setIsPrivate(value)}
                                    placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.availablility')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                        {
                            isPrivate === true && (
                                <Col xs={24} sm={12} xl={6}>
                                    <Form.Item
                                        label={t('backoffice.campaigns.affiliates')}
                                        name="affiliateIds"
                                        className={!hasModifyPermission ? "rt--form-item-disabled" : ""}
                                    >
                                        <Select
                                            options={
                                                affiliateNames
                                                    .map(item => (
                                                        { value: item.id, text: item.name }
                                                    ))
                                            }
                                            placeholder={`${t("backoffice.common.select")} ${t('backoffice.campaigns.affiliates')}`}
                                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                            search={true}
                                            isMultiple={true}
                                            disabled={!hasModifyPermission}
                                        />
                                    </Form.Item>
                                </Col>
                            )
                        }
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.campaigns.brand')}
                                className='rt--general-form-item rt--form-item-disabled'
                            >
                                <Input
                                    value={generalInfo.brandName}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={`${t('backoffice.campaigns.country')} *`}
                                name="countryCodes"
                                className={!hasModifyPermission ? "rt--form-item-disabled" : ""}
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') },
                                ]}
                            >
                                <Select
                                    options={
                                        countries.map(c => (
                                            { value: c.iso2, text: t(`backoffice.countries.${c.iso2}`) }
                                        ))
                                    }
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.campaigns.country')}`}
                                    search={true}
                                    isMultiple={true}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} xl={6} >
                            <Form.Item
                                label={t('backoffice.campaigns.description')}
                                name="description"
                                rules={[
                                    { max: 1000, message: t('backoffice.validation.fieldInvalid') },
                                    { pattern: DESCRIPTION_REGEX, message: t('backoffice.validation.fieldInvalid') }
                                ]}
                                className={'rt--general-form-item' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.campaigns.description')}`}
                            >
                                <Input
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.campaigns.description')}`}
                                    maxLength={1000}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div>
                                <span className='rt--text-light rt--font-regular rt--font-small'>
                                    {
                                        `${t('backoffice.campaigns.url')}: `
                                    }
                                    <span
                                        className="rt--title rt--font-small rt--font-regular rt--link"
                                        onClick={() => window.open(`https://${generalInfo.url}`, "_blank")}
                                    >
                                        {
                                            generalInfo.url
                                        }
                                    </span>
                                </span>
                            </div>
                            <div>
                                <span className='rt--text-light rt--font-regular rt--font-small'>
                                    {
                                        `${t('backoffice.projects.product')}: ${productName}`
                                    }
                                </span>
                            </div>
                            <div>
                                <span className='rt--text-light rt--font-regular rt--font-small'>
                                    {
                                        `${t('backoffice.campaigns.language')}: ${generalInfo.languageCode ? t(`backoffice.languages.${generalInfo.languageCode.toLowerCase()}`) : "-"}`
                                    }
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux state property, current editing campaign */
    generalInfo: campaignGeneralInfoType,
    /** Redux action to get campaign General info */
    getCampaignGeneralInfo: PropTypes.func,
    /** Redux action to save campaign General info */
    saveCampaignGeneralInfo: PropTypes.func,
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getCampaignGeneralInfo: id => {
            dispatch(getCampaignGeneralInfo(id));
        },

        saveCampaignGeneralInfo: data => {
            dispatch(saveCampaignGeneralInfo(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.campaigns.edit.general,
        isSaving: state.campaigns.isSaving,
        isLoading: state.campaigns.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent)