import React from 'react';

import { useParams } from "react-router-dom";

import Sessions from '../../../../../sessions/sessions.component';

import { PERMISSION_RESOURCE } from "constants/permissions.constants";
import { USER_TYPE, USER_ROLE } from 'constants/user.constants';

import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import { useAuthSelector, userRoleSelector } from 'core/stores/authStore';

/** Affiliate Edit Page Sessions Tab Component */
const SessionsComponent = () => {
    const searchParams = useParams();

    const userInfo = useGlobalSelector(userInfoSelector);
    const role = useAuthSelector(userRoleSelector);

    const affiliateId = role === USER_ROLE.AFFILIATE ? userInfo.id : searchParams.id;

    return (
        <Sessions
            userRole={USER_TYPE.AFFILIATE}
            userId={affiliateId}
            permissionResource={PERMISSION_RESOURCE.AFFILIATE_SESSIONS}
        />
    )
}


export default SessionsComponent;
