//#region react
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useLocation, useNavigate, useSearchParams  } from "react-router-dom";
//#endregion

//#region actions
import {
    getProjectBrands,
    deleteProjectBrand,
} from "store/actions/portal/projects/brands.action";
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import BrandAddComponent from './brand-add.component';
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { addQueryStringParamsToUrl } from 'utils/queryString';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { BRANDS } from 'constants/pageName.constants';
import { tableColumns } from './columns';
//#endregion

//#region types
import brandType from 'types/project/brand.type';
//#endregion

import usePermissions from 'core/hooks/usePermission';

const ROW_UNIQUE_KEY_PROP = "id";

/** Project Edit Page Brands Tab Component */
const BrandListComponent = ({
    getProjectBrands,
    deleteProjectBrand,
    isLoading,
    isSaving,
    brands
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const { search, pathname, hash } = useLocation();
    const navigate = useNavigate();

    const [showAddPopup, setShowAddPopup] = useState(false);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: BRANDS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_BRANDS, PERMISSION_ACTION.CREATE );
    const hasDeletePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_BRANDS, PERMISSION_ACTION.DELETE );

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleRightArrowIconClick = (record) => {
        navigate(
            addQueryStringParamsToUrl(
                pathname, 
                search, 
                hash, 
                {
                    brandId: record.id,
                    brandName: record.name
                }
            )
        )
    }

    //#endregion

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            constructForInclude: true,
            includedColumns,
            t
        });
    }, [includedColumns , t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasDeletePermission) {
        tableRowActions.push({
            title: t('backoffice.common.delete'),
            confirmationMessage: t("backoffice.projects.doYouWanttoDeleteBrand"),
            confirmationTitle: t("backoffice.projects.deleteBrand"),
            icon: "trash",
            showConfirmation: true,
            onClick: record => deleteProjectBrand(record.id),
            disabled: record => record.campaignCount > 0
        })
    }

    tableRowActions.push({
        title: t("backoffice.common.deepView"),
        icon: "right",
        onClick: handleRightArrowIconClick,
    })

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            text: t("backoffice.projects.addBrand"),
            enabled: hasCreatePermission,
            onClick: () => setShowAddPopup(true),
        }
    }

    //#endregion

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading || isSaving}
                columns={mainTableColumns}
                data={brands}
                loadFn={getProjectBrands}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                noPagination={true}
                disableFullView={true}
                actions={tableRowActions}
            />

            {showAddPopup && (
                <BrandAddComponent
                    onClose={() => setShowAddPopup(false)}
                />
            )}
        </TabTableDashboardLayout>
    )
}

/** BrandListComponent propTypes
    * PropTypes
*/
BrandListComponent.propTypes = {
    /** Redux action to get project brands */
    getProjectBrands: PropTypes.func,
    /** Redux action to delete project brand */
    deleteProjectBrand: PropTypes.func,
    /** Redux state property, is true when project brands are loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when brand is adding or deleting */
    isSaving: PropTypes.bool,
    /** Redux state, represents the currencies of current editing project  */
    brands: PropTypes.arrayOf(brandType)
}

const mapDispatchToProps = dispatch => (
    {
        getProjectBrands: () => {
            dispatch(getProjectBrands());
        },

        deleteProjectBrand: brand => {
            dispatch(deleteProjectBrand(brand));
        }
    }
)

const mapStateToProps = state => {
    return {
        brands: state.projects.edit.brands,
        isLoading: state.projects.isLoading,
        isSaving: state.projects.isSaving,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandListComponent)