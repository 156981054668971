//#region react
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";
//#endregion

//#region actions
import {
    saveSystemPaymentFormTranslation,
    getSystemPaymentFormTranslation
} from "store/actions/portal/settings/systemPayments/systemPayments.action";
//#endregion

//#region components
import Table from "components/common/table";
//#endregion

//#region utils
import { isMobile } from "utils/common";
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import translationType from 'types/translation/translation.type';
//#endregion

import usePermissions from 'core/hooks/usePermission';

const TABLE_ROW_UNIQUE_KEY = "text";

/** System Payment Method Fields Translations Component */
const SystemPaymentMethodFormTranslations = ({
    languageCode,
    getSystemPaymentFormTranslation,
    saveSystemPaymentFormTranslation,
    translations
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const searchParams = useParams();
    
    const isMobileDevice = isMobile();

    useEffect(() => {
        getSystemPaymentFormTranslation(searchParams.id);
    }, [])

    // /** Mapped translations for table */
    const formTranslations = translations
        .map(tr => ({
            translationKey: tr.key,
            text: tr.key,
            value: tr.translations?.find(t => t.languageCode === languageCode)?.text ?? tr.text,
        }))

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.SYSTEM_PAYMENT_METHODS, PERMISSION_ACTION.MODIFY );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleTranslationValueChange = (newValue, translationKey) => {
        saveSystemPaymentFormTranslation({
            paymentId: searchParams.id,
            languageCode: languageCode,
            text: newValue,
            translationKey,
        });
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns } = tableColumnsCreator({
        mainColumns: getTableColumns,
        additionalProps: {
            onTranslationValueChange: handleTranslationValueChange,
            hasModifyPermission: hasModifyPermission,
            t
        }
    })

    //#endregion

    return (
        <>
            <span className={"rt--form-section-title rt--title rt--flex rt--mb-16 rt--font-bold rt--font-biger" + (isMobileDevice ? " rt--mt-24" : " rt--mt-16")}>
                {t("backoffice.payments.translations")}
            </span>

            {translations.length > 0 && (
                <Table
                    columns={mainTableColumns}
                    data={formTranslations}
                    uniqueKey={TABLE_ROW_UNIQUE_KEY}
                    noPagination={true}
                    disableFullView={true}
                />
            )}
        </>
    );
}

/** SystemPaymentMethodFormTranslations propTypes
    * PropTypes
*/
SystemPaymentMethodFormTranslations.propTypes = {
    /** The language code */
    languageCode: PropTypes.string,
    /** Redux action to save system payment form tranlation */
    saveSystemPaymentFormTranslation: PropTypes.func,
    /** Redux action to get system payment form tranlations */
    getSystemPaymentFormTranslation: PropTypes.func,
    /** Redux state property, Translations */
    translations: PropTypes.arrayOf(translationType)
}

const mapDispatchToProps = dispatch => (
    {
        getSystemPaymentFormTranslation: id => {
            dispatch(getSystemPaymentFormTranslation(id));
        },
        saveSystemPaymentFormTranslation: translation => {
            dispatch(saveSystemPaymentFormTranslation(translation));
        },
    }
)

const mapStateToProps = state => {
	return {
		translations: state.systemPayments.translations
	}
}


export default connect(mapStateToProps, mapDispatchToProps)(SystemPaymentMethodFormTranslations);