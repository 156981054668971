//#region react
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getProjectCurrencies,
    saveProjectCurrency,
    deleteProjectCurrency
} from "store/actions/portal/projects/currencies.action";
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import CurrencyAddEditComponent from "./currency-add-edit.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from "constants/permissions.constants";
import { PROJECT_CURRENCIES } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import currencyType from 'types/currency/currency.type';
//#endregion

import usePermissions from 'core/hooks/usePermission';

const ROW_UNIQUE_KEY_PROP = "code";

/** Project Edit Page Currencies Tab Component */
const CurrenciesComponent = ({
    getProjectCurrencies,
    saveProjectCurrency,
    deleteProjectCurrency,
    isLoading,
    currencies
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [addEditPopupData, setAddEditPopupData] = useState({ isVisible: false });

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PROJECT_CURRENCIES });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_CURRENCIES, PERMISSION_ACTION.CREATE );
    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_CURRENCIES, PERMISSION_ACTION.MODIFY );
    const hasDeletePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_CURRENCIES, PERMISSION_ACTION.DELETE );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleDefaultCurrencyChange = code => {
        const selectedCurrency = currencies.find(c => c.code === code);

        saveProjectCurrency({
            enabled: selectedCurrency.enabled,
            code: selectedCurrency.code,
            isDefault: true,
            rate: selectedCurrency.isOverridden ? selectedCurrency.rate : null
        })
    }

    const handleTableSwitcherChange = (isChecked, record) => {
        saveProjectCurrency({
            enabled: isChecked,
            code: record.code,
            isDefault: record.isDefault,
            rate: record.isOverridden ? record.rate : null
        })
    }

    const showAddEditPopup = (currencyCode) => {
        const addEditPopupData = {
            isVisible: true,
        }

        if (currencyCode) {
            addEditPopupData.currencyCode = currencyCode;
        }

        setAddEditPopupData(addEditPopupData);
    }

    const hideAddEditPopup = () => {
        setAddEditPopupData({
            isVisible: false,
        });
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                t
            },
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasDeletePermission) {
        tableRowActions.push({
            title: t('backoffice.common.delete'),
            confirmationMessage: t("backoffice.currencies.doYouWanttoDeleteCurrency"),
            confirmationTitle: t("backoffice.currencies.deleteCurrency"),
            icon: "trash",
            showConfirmation: true,
            disabled: record => !record.isDeletable,
            onClick: record => deleteProjectCurrency(record.code),
        })
    }

    if (hasModifyPermission) {

        tableRowActions.push({
            title: t('backoffice.common.setDefault'),
            icon: "default",
            onClick: record => handleDefaultCurrencyChange(record.code),
            disabled: record => record.isDefault || !record.enabled
        })

        tableRowActions.push({
            title: t('backoffice.common.edit'),
            icon: "edit",
            onClick: record => showAddEditPopup(record.code),
        })

    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            text: t("backoffice.currencies.addCurrency"),
            enabled: hasCreatePermission,
            onClick: () => showAddEditPopup(),
            disabled: currencies.length >= 20
        }
    }

    //#endregion

    // Close currency add/edit popup, after save
    useEffect(() => {
        hideAddEditPopup()
    }, [currencies])

    return (
        <TabTableDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={currencies}
                loadFn={getProjectCurrencies}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                noPagination={true}
                disableFullView={true}
                actions={tableRowActions}
                switcher={hasModifyPermission ? {
                    valueIndex: "enabled",
                    dataIndex: "enabled",
                    onChange: handleTableSwitcherChange,
                    disabled: record => !record.isDeletable,
                } : undefined}
            />

            {addEditPopupData.isVisible && (
                <CurrencyAddEditComponent
                    onClose={hideAddEditPopup}
                    editingCurrency={addEditPopupData.currencyCode}
                />
            )}
        </TabTableDashboardLayout>
    )
}

/** CurrenciesComponent propTypes
    * PropTypes
*/
CurrenciesComponent.propTypes = {
    /** Redux action to get project currencies */
    getProjectCurrencies: PropTypes.func,
    /** Redux action to edit project currency */
    saveProjectCurrency: PropTypes.func,
    /** Redux action to delete project currency */
    deleteProjectCurrency: PropTypes.func,
    /** Redux state property, is true when project currencies are loading */
    isLoading: PropTypes.bool,
    /** Redux state, represents the currencies of current editing project  */
    currencies: PropTypes.arrayOf(currencyType)
}

const mapDispatchToProps = dispatch => (
    {
        getProjectCurrencies: () => {
            dispatch(getProjectCurrencies());
        },

        saveProjectCurrency: currency => {
            dispatch(saveProjectCurrency(currency));
        },

        deleteProjectCurrency: code => {
            dispatch(deleteProjectCurrency(code));
        }
    }
)

const mapStateToProps = state => {
    return {
        currencies: state.projects.edit.currencies,
        isLoading: state.projects.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CurrenciesComponent)