import React, { Fragment, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Switch, Menu } from "antd";

import Table from "components/common/table";
import Select from "components/common/select";
import Icon from "components/common/icon";
import Confirmation from "components/common/confirmation";
import Dropdown from "components/common/dropdown";
import Tooltip from "components/common/tooltip";
import MainDashboardLayout from "components/layouts/main";

import TranslationCreateComponent from "./translation-create.component";

import {
    getPromoTranslations,
    getBOTranslations,
    savePromoTranslations,
    saveBOTranslation,
    resetTranslation,
    publishPromoTranslations,
    publishBOTranslations,
    unpublishPromoTranslations,
    getTranslationGroups,
    setTranslationsSystemDefault
} from "store/actions/portal/cms/translations/translations.action";

import { getSystemAvailableLanguages } from "store/actions/portal/common/common.action";
import { getProjectAvailableLanguages } from 'store/actions/portal/common/common.action';

import { isMobile } from "utils/common";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";
import { TRANSLATION_SOURCE_TYPE, TRANSLATION_TYPE, BO_GROUP_ID, PROMO_GROUP_ID } from "constants/translation.constants";

import translationGroupType from "types/translation/group.type";
import translationType from "types/translation/translation.type";
import usePermissions from "core/hooks/usePermission";

/** Translations Page Component */
const TranslationsComponent = ({
    getPromoTranslations,
    getBOTranslations,
    savePromoTranslations,
    saveBOTranslation,
    resetTranslation,
    publishPromoTranslations,
    publishBOTranslations,
    unpublishPromoTranslations,
    getTranslationGroups,
    setTranslationsSystemDefault,
    isLoading,
    isPublishing,
    isUnpublishing,
    translationGroups,
    translations,
    systemDefault,
    getSystemAvailableLanguages,
    getProjectAvailableLanguages,
    systemAvailableLanguages,
    projectAvailableLanguages,
    isProjectAvailableLanguagesLoading,
    isSystemAvailableLanguagesLoading,
    translationType
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [searchValue, setSearchValue] = useState("");

    /** State to show/hide translation creation popup */
    const [showCreatePopup, setShowCreatePopup] = useState(false);

    /** State to show/hide unpublish confirmation popup */
    const [showUnpublishPopup, setShowUnpublishPopup] = useState(false);

    const [selectedLanguages, setSelectedLanguages] = useState([]);
    const [selectedGroupID, setSelectedGroupID] = useState(null);

    const isSystemDefault = translationType === TRANSLATION_TYPE.BO || systemDefault;

    const groups = translationGroups[translationType];

    /** Load translation groups */
    useEffect(() => {
        getTranslationGroups(translationType);
    }, [])

    /** Load system available languages */
    useEffect(() => {
        if (isSystemDefault && Object.keys(systemAvailableLanguages).length === 0) {
            getSystemAvailableLanguages()
        }
    }, [])

    /** Load company available languages */
    useEffect(() => {
        if (!isSystemDefault && Object.keys(projectAvailableLanguages).length === 0) {
            getProjectAvailableLanguages()
        }
    }, [projectAvailableLanguages])

    /** Reset selected group Id */
    useEffect(() => {
        return () => {
            setSelectedGroupID(null);
        }
    }, [])

    /** Set languages */
    useEffect(() => {
        if (isSystemDefault && Object.keys(systemAvailableLanguages).length > 0) {
            setSelectedLanguages(Object.keys(systemAvailableLanguages).splice(0, isMobile() ? 1 : 2))
        } else if (!isSystemDefault && Object.keys(projectAvailableLanguages).length > 0) {
            setSelectedLanguages(Object.keys(projectAvailableLanguages).splice(0, isMobile() ? 1 : 2));
        }
    }, [projectAvailableLanguages, systemAvailableLanguages, isSystemDefault])

    /** Load translation on resource change */
    useEffect(() => {
        if (selectedGroupID && selectedLanguages.length > 0) {
            handleResourceDropdownChange();
        }
    }, [selectedGroupID, selectedLanguages])

    /** Set group value */
    useEffect(() => {
        if (
            ((isSystemDefault && Object.keys(systemAvailableLanguages).length) ||
                (!isSystemDefault && Object.keys(projectAvailableLanguages).length)) &&
            selectedLanguages.length > 0 && groups.length > 0
        ) {
            const grId = selectedGroupID || groups[0].id;
            setSelectedGroupID(grId);
        }
    }, [groups, selectedLanguages])


    /** Get languages to edit
       * @function
       * @returns {object}
       * @memberOf TranslationsComponent
   */
    const getLanguages = () => isProjectAvailableLanguagesLoading || isSystemAvailableLanguagesLoading || isSystemDefault ? systemAvailableLanguages : projectAvailableLanguages;


    /** Fires on groups dropdown change
       * @function
       * @memberOf TranslationsComponent
   */
    const handleResourceDropdownChange = () => {
        setSearchValue("");

        if (translationType === TRANSLATION_TYPE.PROMO) {
            getPromoTranslations(selectedGroupID, selectedLanguages, systemDefault);
        } else {
            getBOTranslations(selectedGroupID, selectedLanguages);
        }
    }

    /** Make translations data
       * @function
       * @returns {array}
       * @memberOf TranslationsComponent
   */
    const makeTranslationData = () => {
        let res = [...translations.filter(tr => !searchValue || tr.key.toLowerCase().includes(searchValue.toLowerCase()))];
        res = res.map(tr => {
            const obj = {};
            if (tr.translations) {
                tr.translations.forEach(t => {
                    obj[t.languageCode] = {
                        text: t.text,
                        source: t.source
                    }
                })
            }

            return {
                ...tr,
                translations: obj
            }
        })
        return res;
    }

    /** Make columns array depend on languages list
       * @function
       * @param {string} value - selected value
       * @returns {array}
       * @memberOf TranslationsComponent
   */
    const makeColumns = () => {
        const columns = [
            {
                title: t("backoffice.translations.keys"),
                dataIndex: "key",
                width: "25%",
                mobileLevel: 1
            },
            {
                title: t('backoffice.translations.systemDefault'),
                dataIndex: 'text',
                short: !isMobile(),
                middle: isMobile(),
                width: "25%",
                mobileLevel: 2
            }
        ];
        selectedLanguages.forEach((lang, index) => {
            columns.push({
                title: renderTranslationColumnTitle(lang, index),
                dataIndex: "translations." + lang + ".text",
                render: (value, record) => record?.translations?.[lang.toUpperCase()]?.text ?? "",
                editable: permissionUtils.has( PERMISSION_RESOURCE.TRANSLATION, PERMISSION_ACTION.MODIFY ),
                onEdit: (value, record) => {
                    const translation = {
                        key: record.key,
                        subGroupId: selectedGroupID,
                        languageCode: lang,
                        text: value
                    }
                    if (translationType === TRANSLATION_TYPE.PROMO) {
                        translation.isSystemDefault = isSystemDefault;
                        savePromoTranslations(translation)
                    } else {
                        saveBOTranslation(translation)
                    }
                },
                editableLabel: () => selectedLanguages[0] ? getLanguages()[selectedLanguages[0]] : t("backoffice.translations.translation"),
                editableTitle: () => t("backoffice.translations.translation"),
                maxLength: 100,
                extraButtons: [{
                    icon: "reload",
                    title: t("backoffice.translations.resetToDefault"),
                    disabled: record => (
                        !permissionUtils.has( PERMISSION_RESOURCE.TRANSLATION, PERMISSION_ACTION.MODIFY ) ||
                        record?.translations?.[lang.toUpperCase()]?.source !== TRANSLATION_SOURCE_TYPE.OVERRIDEN
                    ),
                    onClick: record => resetTranslation({
                        key: record.key,
                        groupId: translationType === TRANSLATION_TYPE.PROMO ? PROMO_GROUP_ID : BO_GROUP_ID,
                        subGroupId: selectedGroupID,
                        languageCode: lang
                    })
                }],
                width: "25%",
                mobileLevel: 3,
                mobileTitle: selectedLanguages[0] ? getLanguages()[selectedLanguages[0]] : t("backoffice.translations.translation"),
                mobileRender: (value, record) => {
                    const inputValue = record?.translations?.[lang.toUpperCase()]?.text ?? "";

                    return (
                        <Tooltip
                            title={inputValue}
                            trigger={["hover", "click"]}
                            placement="top"
                            enableMobile={true}
                        >
                            {inputValue}
                        </Tooltip>
                    )
                }
            })
        })

        return columns
    }

    /** Fires on system default switcher change
         * @function
         * @param {boolean} value
         * @memberOf TranslationsComponent
     */
    const handleSystemDefaultChange = value => {
        if (value && Object.keys(systemAvailableLanguages).length === 0) {
            getSystemAvailableLanguages()
        } else if (!value && Object.keys(projectAvailableLanguages).length === 0) {
            getProjectAvailableLanguages();
        }
        setTranslationsSystemDefault(value);
    }

    /** Close unpublishing confirmation popup after unpublish */
    useEffect(() => {
        if (!isUnpublishing) {
            setShowUnpublishPopup(false);
        }
    }, [isUnpublishing])

    /** Render table column header
         * @function
         * @param {string} lang - language for column
         * @param {number} index - column index
         * @returns {JSX}
         * @memberOf TranslationsComponent
    */
    const renderTranslationColumnTitle = (lang, index) => {
        let languages = Object.keys(getLanguages()).filter(l => !selectedLanguages.includes(l) || l === lang)

        return (
            <Dropdown
                getPopupContainer={trigger => trigger.parentElement}
                overlay={(
                    <Menu
                        selectedKeys={[]}
                        className="rt--table-header-dropdown rt--table-header-dropdown-translations"
                        placement="bottomLeft"
                        trigger={['click']}
                    >
                        {
                            languages.map(l => (
                                <Menu.Item
                                    key={l}
                                    onClick={e => {
                                        const update = selectedLanguages.map((l, i) => i === index ? e.key : l);
                                        setSelectedLanguages(update);
                                    }}
                                >
                                    {
                                        t("backoffice.languages." + l)
                                    }
                                </Menu.Item>
                            ))
                        }
                    </Menu>
                )}
                disabled={false}
            >
                <div
                    className="rt--table-header-dropdown-content rt--flex-equal rt--flex rt--align-center rt--cursor-pointer"
                >
                    <Icon name="translations" />
                    <span className="rt--pl-8 rt--pr-4 rt--title rt--font-normal rt--font-bold">{t("backoffice.languages." + selectedLanguages[index])}</span>
                    <Icon name="down" />

                    {
                        permissionUtils.has( PERMISSION_RESOURCE.TRANSLATION, PERMISSION_ACTION.MODIFY ) && (
                            <div
                                className="rt--table-header-dropdown-content-actions rt--flex-inline rt--align-center rt--justify-end"
                            >
                                {/* {
                                    (
                                        !systemDefault && translationType === TRANSLATION_TYPE.PROMO 
                                    ) && (
                                        <div className="rt--table-header-dropdown-content-actions-item rt--flex rt--align-center rt--justify-center rt--mr-4"
                                            onClick={e => {
                                                e.stopPropagation();
                                                setShowUnpublishPopup(selectedLanguages[index])
                                            }}
                                        >
                                            <Tooltip
                                                title={t('backoffice.translations.unpublish')}
                                            >
                                                <Icon name="unpublish" />
                                            </Tooltip>
                                        </div>
                                    )
                                } */}
                                {
                                    translationType === TRANSLATION_TYPE.BO && (
                                        <div className="rt--table-header-dropdown-content-actions-item rt--flex rt--align-center rt--justify-center"
                                            onClick={e => {
                                                e.stopPropagation();
                                                if (translationType === TRANSLATION_TYPE.PROMO) {
                                                    publishPromoTranslations(systemDefault, selectedLanguages[index])
                                                } else {
                                                    publishBOTranslations(selectedLanguages[index])
                                                }
                                            }}
                                        >
                                            <Tooltip
                                                title={t('backoffice.translations.publish')}
                                            >
                                                <Icon name="publish" />
                                            </Tooltip>
                                        </div>
                                    )
                                }



                            </div>
                        )
                    }

                </div>
            </Dropdown>
        )
    }

    /** Render table column header
         * @function
         * @returns {JSX}
         * @memberOf TranslationsComponent
    */
    const renderSystemDefaultSwitcher = () => (
        <div>
            <div className="rt--switcher rt--flex-inline rt--align-center">
                <Form.Item
                    className='rt--form-item-without-margin'
                >
                    <Switch
                        checked={systemDefault}
                        onChange={value => handleSystemDefaultChange(value)}
                    />
                </Form.Item>
                <div className='rt--flex rt--align-center'>
                    <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.translations.systemDefaultTranslations')}</label>
                </div>
            </div>
        </div>
    )

    /** Render languages select for mobile
         * @function
         * @returns {JSX}
         * @memberOf TranslationsComponent
    */

    const renderLanguagesDropdown = () => {
        const languages = Object.keys(getLanguages());
        return (
            <div className="rt--mb-12">
                <Form.Item
                    className='rt--form-item-without-margin'
                >
                    <Select
                        options={languages.map(lang => (
                            { value: lang, text: t("backoffice.languages." + lang) }
                        ))}
                        placeholder={`${t("backoffice.common.select")} ${t("backoffice.translations.language")}`}
                        onChange={e => setSelectedLanguages([e])}
                        value={selectedLanguages[0] ? selectedLanguages[0] : languages[0]}
                        getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                    />
                </Form.Item>

            </div>
        )
    }

    return (
        <MainDashboardLayout
            header={
                {
                    search: {
                        onSearch: value => setSearchValue(value),
                        placeholder: t("backoffice.common.search")
                    },

                    buttons: [
                        {
                            icon: "publish",
                            type: "primary",
                            onClick: () => {
                                translationType === TRANSLATION_TYPE.PROMO
                                    ? publishPromoTranslations(systemDefault)
                                    : publishBOTranslations()
                            },
                            text: t("backoffice.translations.publish"),
                            enabled: permissionUtils.has( PERMISSION_RESOURCE.TRANSLATION, PERMISSION_ACTION.MODIFY ) && translationType === TRANSLATION_TYPE.BO,
                            disabled: isPublishing
                        },

                        // {
                        //     icon: "unpublish",
                        //     type: "danger",
                        //     className: 'rt--button-secondary',
                        //     onClick: () => setShowUnpublishPopup(true),
                        //     text: t("backoffice.translations.unpublish"),
                        //     enabled: !systemDefault && translationType === TRANSLATION_TYPE.PROMO && permissionUtils.has( PERMISSION_RESOURCE.TRANSLATION, PERMISSION_ACTION.MODIFY ),
                        //     disabled: isUnpublishing
                        // },

                        {
                            icon: "plus",
                            type: "primary",
                            onClick: () => setShowCreatePopup(true),
                            text: t("backoffice.translations.createTranslation"),
                            enabled: isSystemDefault && permissionUtils.has( PERMISSION_RESOURCE.TRANSLATION, PERMISSION_ACTION.CREATE )
                        }
                    ],

                    breadcrumbs: {
                        items: [
                            {
                                title: (
                                    translationType === TRANSLATION_TYPE.PROMO
                                        ? t('backoffice.menu.promoTranslations')
                                        : t('backoffice.menu.boTranslations')
                                )
                            }
                        ]
                    }
                }
            }

            dropdowns={
                [
                    {
                        placeholder: t("backoffice.translations.resource"),
                        value: selectedGroupID,
                        onChange: value => setSelectedGroupID(value),
                        items: groups.map(gr => ({
                            key: gr.id,
                            value: gr.name,
                            subs: (gr.subGroups || []).map(g => ({
                                key: g.id,
                                value: g.name,
                            }))
                        })),
                        search: translationType === TRANSLATION_TYPE.BO
                    }
                ]
            }

            extraContent={
                {
                    content: (
                        <Fragment>
                            {isMobile() && renderLanguagesDropdown()}
                            { TRANSLATION_TYPE.PROMO === translationType && renderSystemDefaultSwitcher()}
                        </Fragment>
                    )
                }
            }
        >
            <Table
                loading={isLoading || isPublishing || isUnpublishing}
                columns={makeColumns()}
                data={makeTranslationData()}
                noPagination={true}
                uniqueKey="key"
                disableFullView={true}
                noAction={true}
            />


            {
                showCreatePopup && (
                    <TranslationCreateComponent
                        onClose={() => setShowCreatePopup(false)}
                        groupId={translationType === TRANSLATION_TYPE.PROMO ? PROMO_GROUP_ID : BO_GROUP_ID}
                        subGroupId={selectedGroupID}
                        languageCodes={selectedLanguages}
                        translationType={translationType}
                    />
                )
            }

            {
                showUnpublishPopup && (
                    <Confirmation
                        title={t('backoffice.translations.unpublish')}
                        message={t('backoffice.translations.unpublishMessage')}
                        onOk={() => {
                            if (translationType === TRANSLATION_TYPE.PROMO) {
                                unpublishPromoTranslations(showUnpublishPopup !== true ? showUnpublishPopup : null)
                            }
                        }}
                        onCancel={() => setShowUnpublishPopup(false)}
                        isVisible={true}
                    />
                )
            }
        </MainDashboardLayout>
    )
};

/** TranslationsComponent propTypes
 * PropTypes
*/
TranslationsComponent.propTypes = {
    /** Redux action to get promo translations */
    getPromoTranslations: PropTypes.func,
    /** Redux action to get BO translations */
    getBOTranslations: PropTypes.func,
    /** Redux action to save promo translations */
    savePromoTranslations: PropTypes.func,
    /** Redux action to save BO translations */
    saveBOTranslation: PropTypes.func,
    /** Redux action to reset translations */
    resetTranslation: PropTypes.func,
    /** Redux action to publish promo translations */
    publishPromoTranslations: PropTypes.func,
    /** Redux action to publish BO translations */
    publishBOTranslations: PropTypes.func,
    /** Redux action to unpublish promo translations */
    unpublishPromoTranslations: PropTypes.func,
    /** Redux action to get translation groups */
    getTranslationGroups: PropTypes.func,
    /** Redux action to set system default translation */
    setTranslationsSystemDefault: PropTypes.func,
    /** Redux state property, is true when loading translations */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when publishing translations */
    isPublishing: PropTypes.bool,
    /** Redux state property, is true when unpublishing translation */
    isUnpublishing: PropTypes.bool,
    /** Redux state property, translation groups */
    translationGroups: PropTypes.objectOf(PropTypes.arrayOf(translationGroupType)),
    /** Redux state property, translations */
    translations: PropTypes.arrayOf(translationType),
    /** Redux state property, is true when system default translations is enabled */
    systemDefault: PropTypes.bool,
    /** Redux action to get system available languages */
    getSystemAvailableLanguages: PropTypes.func,
    /** Redux action to get project available languages */
    getProjectAvailableLanguages: PropTypes.func,
    /** Redux state property, represents the object of available system languages */
    systemAvailableLanguages: PropTypes.object,
    /** Redux state property, represents the object of project available  languages */
    projectAvailableLanguages: PropTypes.object,
    /** Redux state property, is true when project available languages are loading*/
    isProjectAvailableLanguagesLoading: PropTypes.bool,
    /** Redux state property, is true when system available languages are loading*/
    isSystemAvailableLanguagesLoading: PropTypes.bool,
    /** Translation type (promo/bo) */
    translationType: PropTypes.oneOf(Object.values(TRANSLATION_TYPE))
};

const mapDispatchToProps = dispatch => ({
    getPromoTranslations: (subGroupId, languageCodes, isSystemDefault) => {
        dispatch(getPromoTranslations(subGroupId, languageCodes, isSystemDefault));
    },
    getBOTranslations: (subGroupId, languageCodes) => {
        dispatch(getBOTranslations(subGroupId, languageCodes));
    },
    savePromoTranslations: translation => {
        dispatch(savePromoTranslations(translation))
    },
    saveBOTranslation: translation => {
        dispatch(saveBOTranslation(translation))
    },
    resetTranslation: translation => {
        dispatch(resetTranslation(translation))
    },
    getTranslationGroups: translationType => {
        dispatch(getTranslationGroups(translationType))
    },
    setTranslationsSystemDefault: systemDefault => {
        dispatch(setTranslationsSystemDefault(systemDefault))
    },
    publishBOTranslations: language => {
        dispatch(publishBOTranslations(language))
    },
    publishPromoTranslations: (isSystemDefault, language) => {
        dispatch(publishPromoTranslations(isSystemDefault, language))
    },
    unpublishPromoTranslations: languageCode => {
        dispatch(unpublishPromoTranslations(languageCode))
    },
    getSystemAvailableLanguages: () => {
        dispatch(getSystemAvailableLanguages())
    },
    getProjectAvailableLanguages: () => {
        dispatch(getProjectAvailableLanguages())
    },
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.translations.isLoading,
        isPublishing: state.translations.isPublishing,
        isUnpublishing: state.translations.isUnpublishing,
        translations: state.translations.translations,
        translationGroups: state.translations.groups,
        systemDefault: state.translations.systemDefault,
        systemAvailableLanguages: state.common.systemAvailableLanguages,
        projectAvailableLanguages: state.common.projectAvailableLanguages,
        isProjectAvailableLanguagesLoading: state.common.isProjectAvailableLanguagesLoading,
        isSystemAvailableLanguagesLoading: state.systemLanguages.isSystemAvailableLanguagesLoading,
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TranslationsComponent);
