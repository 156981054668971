//#region react
import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "core/hooks/useTranslation";
import { useNavigate } from 'react-router-dom';
//#endregion

//#region actions
import {
    getProjects,
    setProjectsSorting,
    setProjectsFilters
} from "store/actions/portal/projects/projects.action";
//#endregion

//#region components
import Table from "components/common/table";
import MainDashboardLayout from "components/layouts/main";
import ProjectCreateComponent from "./project-create.component";
import Filters from "./filters.component";
//#endregion

//#region hooks
import useIncludedColumns from "hooks/useIncludedColumns";
//#endregion

//#region utils
import { hasProjectEditPageAccess } from 'utils/pageAccess';
import { tableColumnsCreator } from "utils/tableColumnsCreator";
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import ROUTES from "core/constants/routes/routes";
import ApiUrls from 'constants/api.constants';
import { AUTOSUGGESTION_TYPE } from "constants/autosuggestion.constants";
import { PROJECTS } from "constants/pageName.constants";
import { tableColumns } from "./columns";
//#endregion

//#region types
import projectType from "types/project/project.type";
import sortingType from "types/common/sorting.type";
//#endregion

import { authStore, globalCompanyIdSelector, useAuthSelector } from "core/stores/authStore";
import usePermissions from "core/hooks/usePermission";

const PROJECTS_EDIT_PAGE_PERMISSION_RESOURCES = [
    PERMISSION_RESOURCE.PROJECT_GENERALINFO,
    PERMISSION_RESOURCE.PROJECT_BRANDS,
    PERMISSION_RESOURCE.PROJECT_PROMO,
    PERMISSION_RESOURCE.PROJECT_CURRENCIES,
    PERMISSION_RESOURCE.PROJECT_LANGUAGE_SETTINGS,
    PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN,
    PERMISSION_RESOURCE.PROJECT_REGISTRATION_CONFIGURATION,
    PERMISSION_RESOURCE.PROJECT_PAYMENT_METHODS,
    PERMISSION_RESOURCE.PROJECT_PAYOUT_SETTINGS,
    PERMISSION_RESOURCE.PROJECT_REFERRAL_SETTINGS
]

/** Projects Page Component */
const ProjectsComponent = ({
    getProjects,
    projects,
    isLoading,
    total,
    setProjectsSorting,
    setProjectsFilters,
    sorting,
    filters
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();

    const permissionUtils = usePermissions();

    const globalCompanyId = useAuthSelector(globalCompanyIdSelector);

    const [isCreatePopupVisible, setIsCreatePopupVisible] = useState(null);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: PROJECTS });

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT, PERMISSION_ACTION.EXPORT);
    const hasProjectCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT, PERMISSION_ACTION.CREATE);

    const canNavigateToProjectsEditPage = permissionUtils.some(
        ...PROJECTS_EDIT_PAGE_PERMISSION_RESOURCES.map(resource => ({
            resource,
            action: PERMISSION_ACTION.VIEW
        }))
    )

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = value => {
        setProjectsFilters({
            ...filters,
            nameOrId: value
        })
    }

    const handleRightArrowIconClick = (record) => {
        authStore.changeProject({
            companyId: globalCompanyId,
            projectId: record.id
        }).then(() => {
            navigate(
                `${ROUTES.PROJECTS_EDIT}/${record.id}` +
                `?name=${record.name}`
            )
        });
    }

    const showCreatePopup = () => {
        setIsCreatePopupVisible(true)
    }

    const hideCreatePopup = () => {
        setIsCreatePopupVisible(false)
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded,
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: tableColumns,
            constructForExport: true,
            constructForInclude: true,
            includedColumns,
            t
        });
    }, [includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (canNavigateToProjectsEditPage) {
        tableRowActions.push({
            title: t('backoffice.common.deepView'),
            icon: "right",
            onClick: handleRightArrowIconClick,
            disabled: () => !hasProjectEditPageAccess(permissionUtils)
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            placeholder: t("backoffice.projects.projectNameOrId"),
            loadFn: getProjects,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.PROJECT,
                dependencies: [globalCompanyId]
            },
            onSearch: handleSearchChange,
        },
        buttons: [{
            icon: "plus",
            type: "primary",
            onClick: showCreatePopup,
            text: t("backoffice.projects.createProject"),
            enabled: hasProjectCreatePermission
        }],
        breadcrumbs: {
            items: [{ title: t('backoffice.menu.projects') }]
        }
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.menu.projects"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_PROJECTS,
            filters: filters,
        }
    }

    //#endregion

    return (
        <MainDashboardLayout header={headerPartsData}>
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={projects}
                loadFn={getProjects}
                sorting={sorting}
                setSortingFn={setProjectsSorting}
                total={total}
                updateProps={[globalCompanyId]}
                actions={tableRowActions}
            />

            {isCreatePopupVisible && <ProjectCreateComponent onClose={hideCreatePopup} />}
        </MainDashboardLayout>
    )
};

/** ProjectsComponent propTypes
 * PropTypes
*/
ProjectsComponent.propTypes = {
    /** Redux action to get projects */
    getProjects: PropTypes.func,
    /** Redux state property, represents the array of projects  */
    projects: PropTypes.arrayOf(projectType),
    /** Redux state property, is true when loading projects */
    isLoading: PropTypes.bool,
    /** Redux state property, projects total count */
    total: PropTypes.number,
    /** Redux action to set projects sorting details */
    setProjectsSorting: PropTypes.func,
    /** Redux action to set projects sorting details */
    setProjectsFilters: PropTypes.func,
    /** Redux state property, projects sorting details */
    sorting: sortingType,
    /** Redux state property, projects filters */
    filters: PropTypes.object
};

const mapDispatchToProps = dispatch => ({
    getProjects: nextPage => {
        dispatch(getProjects(nextPage));
    },
    setProjectsSorting: sorting => {
        dispatch(setProjectsSorting(sorting));
    },
    setProjectsFilters: filters => {
        dispatch(setProjectsFilters(filters));
    }
});

const mapStateToProps = (state) => {
    return {
        isLoading: state.projects.isLoading,
        projects: state.projects.projects,
        total: state.projects.total,
        sorting: state.projects.sorting,
        filters: state.projects.filters
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ProjectsComponent);
