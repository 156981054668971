import React, { Fragment, useMemo } from 'react';

import PropTypes from 'prop-types';

import Affilliate from "./affiliate";
import Alert from "./alert";
import ArrowDownRight from "./arrow-down-right";
import ArrowUpRight from "./arrow-up-right";
import Avatar from "./avatar";
import Block from "./block";
import Cancel from "./cancel";
import Close from "./close";
import CloseTag from "./close-tag";
import Cms from "./cms";
import Code from "./code";
import Collapse from "./collapse";
import CollapseAll from "./collapse-all";
import Communication from "./communication";
import Companies from "./companies";
import Copy from "./copy";
import Currency from "./currency";
import Dashboard from "./dashboard";
import Date from "./date";
import Decline from "./decline";
import Default from "./default";
import Delete from "./delete";
import Desktop from "./desktop";
import DirectLink from "./direct-link";
import Disable from "./disable";
import Down from "./down";
import DownSmall from "./down-small";
import Download from "./download";
import Edit from "./edit";
import Ellipsis from "./ellipsis";
import Empty from "./empty";
import Enable from "./enable";
import Expand from "./expand";
import ExpandAll from "./expand-all";
import Eye from "./eye";
import File from "./file";
import Filter from "./filter";
import Globe from "./globe";
import Grid from "./grid";
import Home from "./home";
import Info from "./info";
import Languages from "./languages";
import Left from "./left";
import Lock from "./lock";
import LoginAsAffiliate from "./loginAsAffiliate";
import Logout from "./logout";
import Mediatools from "./mediatools";
import Menu from "./menu";
import Mobile from "./mobile";
import More from "./more";
import Notification from "./notification";
import Ok from "./ok";
import Pallet from "./pallet";
import Password from "./password";
import Pay from "./pay";
import Payments from "./payments";
import Permission from "./permission";
import Picture from "./picture";
import Player from "./player";
import Plus from "./plus";
import PointingArrow from "./pointing-arrow";
import Publish from "./publish";
import Profile from "./profile";
import Regex from "./regex";
import Reject from "./reject";
import Reload from "./reload";
import Rename from "./rename";
import Report from "./report";
import Reset from "./reset";
import Right from "./right";
import Search from "./search";
import SelectDown from "./select-down";
import Send from "./send";
import Settings from "./settings";
import SortDown from "./sort-down";
import SortUp from "./sort-up";
import Speaker from "./speaker";
import Success from "./success";
import Tablet from "./tablet";
import Test from "./test";
import Time from "./time";
import Transfer from "./transfer";
import Translations from "./translations";
import Trash from "./trash";
import Unpublish from "./unpublish";
import Up from "./up";
import Upload from "./upload";
import UserAvatar from "./user-avatar";
import Userlogs from "./userlogs";
import Users from "./users";
import Wallet from "./wallet";
import Warning from "./warning";
import PlayerCount from './playerCount'
import SendDashed from './sendDashed' 
import MarketingSend from './marketingSend'
import Visible from './visible'
import Email from './email'
import EmailRead from './emailRead'

/** Icon Component */
const Icon = ({
    name,
    size = 24,
    color = "var(--icon-color)",
    className = "",
    onClick
}) => {
    const IconComponent = useMemo(() => {
        switch (name) {
            case "affiliate":
                return Affilliate;
            case "alert":
                return Alert;
            case "arrow-down-right":
                return ArrowDownRight;
            case "arrow-up-right":
                return ArrowUpRight;
            case "avatar":
                return Avatar;
            case "block":
                return Block;
            case "cancel":
                return Cancel;
            case "close":
                return Close;
            case "close-tag":
                return CloseTag;
            case "cms":
                return Cms;
            case "code":
                return Code;
            case "collapse":
                return Collapse;
            case "collapse-all":
                return CollapseAll;
            case "communication":
                return Communication;
            case "companies":
                return Companies;
            case "copy":
                return Copy;
            case "currency":
                return Currency;
            case "dashboard":
                return Dashboard;
            case "date":
                return Date;
            case "decline":
                return Decline;
            case "default":
                return Default;
            case "delete":
                return Delete;
            case "desktop":
                return Desktop;
            case "direct-link":
                return DirectLink;
            case "disable":
                return Disable;
            case "down":
                return Down;
            case "down-small":
                return DownSmall;
            case "download":
                return Download;
            case "edit":
                return Edit;
            case "ellipsis":
                return Ellipsis;
            case "empty":
                return Empty;
            case "enable":
                return Enable;
            case "expand":
                return Expand;
            case "expand-all":
                return ExpandAll;
            case "eye":
                return Eye;
            case "file":
                return File;
            case "filter":
                return Filter;
            case "globe":
                return Globe;
            case "grid":
                return Grid;
            case "home":
                return Home;
            case "info":
                return Info;
            case "languages":
                return Languages;
            case "left":
                return Left;
            case "lock":
                return Lock;
            case "loginAsAffiliate":
                return LoginAsAffiliate;
            case "logout":
                return Logout;
            case "mediatools":
                return Mediatools;
            case "menu":
                return Menu;
            case "mobile":
                return Mobile;
            case "more":
                return More;
            case "notification":
                return Notification;
            case "ok":
                return Ok;
            case "pallet":
                return Pallet;
            case "password":
                return Password;
            case "pay":
                return Pay;
            case "payments":
                return Payments;
            case "permission":
                return Permission;
            case "picture":
                return Picture;
            case "player":
                return Player;
            case "plus":
                return Plus;
            case "pointing-arrow":
                return PointingArrow;
            case "publish":
                return Publish;
            case "profile":
                return Profile;
            case "regex":
                return Regex;
            case "reject":
                return Reject;
            case "reload":
                return Reload;
            case "rename":
                return Rename;
            case "report":
                return Report;
            case "reset":
                return Reset;
            case "right":
                return Right;
            case "search":
                return Search;
            case "select-down":
                return SelectDown;
            case "send":
                return Send;
            case "settings":
                return Settings;
            case "sort-down":
                return SortDown;
            case "sort-up":
                return SortUp;
            case "speaker":
                return Speaker;
            case "success":
                return Success;
            case "tablet":
                return Tablet;
            case "test":
                return Test;
            case "time":
                return Time;
            case "transfer":
                return Transfer;
            case "translations":
                return Translations;
            case "trash":
                return Trash;
            case "unpublish":
                return Unpublish;
            case "up":
                return Up;
            case "upload":
                return Upload;
            case "user-avatar":
                return UserAvatar;
            case "userlogs":
                return Userlogs;
            case "users":
                return Users;
            case "wallet":
                return Wallet;
            case "warning":
                return Warning;
            case "playerCount":
                return PlayerCount;
            case "sendDashed":
                return SendDashed;
            case "marketingSend":
                return MarketingSend;
            case "visible":
                return Visible;
            case "email":
                return Email;
            case "email-read":
                return EmailRead;
            default:
                return Fragment
        }
    }, [name]);

    return (
        <IconComponent
            width={size}
            height={size}
            fill={color}
            className={`icon-${name} ${className}`}
            onClick={onClick}
        />
    );
};

/** Icon propTypes
    * PropTypes
*/
Icon.propTypes = {
    /** Icon name */
    name: PropTypes.string,
    /** Icon size */
    size: PropTypes.number,
    /** Icon color */
    color: PropTypes.string,
    /** Classname */
    className: PropTypes.string,
    /** Click handler */
    onClick: PropTypes.func
}

export default Icon;