import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams } from "react-router-dom";

import EntityActions from "components/common/entityActions";

import {
    activateCampaign,
    deactivateCampaign
} from "store/actions/portal/marketingTools/campaigns/campaigns.action";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { CAMPAIGN_STATE } from 'constants/campaign.constants';

import commissionPlanType from 'types/commissionPlan/commissionPlan.type';
import usePermissions from 'core/hooks/usePermission';

/** Campaign Actions Button Component */
const CampaignActionsComponent = ({
    activateCampaign,
    deactivateCampaign,
    generalInfo
}) => {

    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const searchParams = useParams();

    /** Actions list */
    const actions = [

        /** Deactivate/Activate */
        {
            key: "block",
            buttonType: generalInfo.state === CAMPAIGN_STATE.ACTIVE ? "primary" : "secondary",
            buttonClassName: generalInfo.state === CAMPAIGN_STATE.ACTIVE ? "rt--button-danger" : "rt--button-secondary",
            title: generalInfo.state !== CAMPAIGN_STATE.ACTIVE ? t('backoffice.campaigns.activate') : t('backoffice.campaigns.deactivate'),
            onClick: () => {
                if(generalInfo.state === CAMPAIGN_STATE.ACTIVE){
                    deactivateCampaign(searchParams.id)
                } else {
                    activateCampaign(searchParams.id)
                }
            },
            icon: "block",
            isConfirmation: true,
            confirmationMessage: generalInfo.state === CAMPAIGN_STATE.ACTIVE ? t('backoffice.campaigns.campaignDeactivateInfo') : t('backoffice.campaigns.campaignActivateInfo'),
            disabled: (
                !permissionUtils.has( PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, PERMISSION_ACTION.MODIFY )
            )
        }
    ]

    return (
        <Fragment>
            <EntityActions
                actions={actions}
            />
        </Fragment>
    )
}

/** CampaignActionsComponent propTypes
    * PropTypes
*/
CampaignActionsComponent.propTypes = {
    /** Redux state property, current editing campaign general info */
    generalInfo: commissionPlanType,
    /** Redux action to activate campaign */
    activateCampaign: PropTypes.func,
    /** Redux action to deactivate campaign */
    deactivateCampaign: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        activateCampaign: id => {
            dispatch(activateCampaign(id));
        },
        deactivateCampaign: id => {
            dispatch(deactivateCampaign(id));
        }
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.campaigns.edit.general,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CampaignActionsComponent)