import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from 'core/hooks/useTranslation';
import { useLocation, useNavigate } from "react-router-dom";

import { Col, Form, Row, Spin, DatePicker } from 'antd';

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Icon from 'components/common/icon';
import Select from 'components/common/select';
import Input from 'components/common/input';
import Status from 'components/common/status';

import { getNewsletterGeneralInfo, saveNewsletterGeneralInfo } from 'store/actions/portal/communications/newsletter/general.action';

import useUnsavedChangesConfirmation from 'hooks/useUnsavedChangesConfirmation';
import useFormat from 'hooks/useFormat';
import useDate from 'hooks/useDate';

import { isFormChanged } from "utils/form";
import { isMobile } from "utils/common";
import { deleteQueryStringParamsToUrl } from 'utils/queryString';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';
import { STATUS_TYPES } from 'constants/status.constants';

import newsletterType from 'types/newsletter/newsletter.type';
import usePermissions from 'core/hooks/usePermission';

const GeneralInfoComponent = ({
    isLoading,
    isSaving,
    getNewsletterGeneralInfo,
    saveNewsletterGeneralInfo,
    generalInfo,
    onTabChange
}) => {
    const { t } = useTranslation();
    const { search, hash, pathname } = useLocation();
    const navigate = useNavigate();

    const permissionUtils = usePermissions();

    const { formatNumber } = useFormat();

    const { dateService } = useDate();

    const [isFormTouched, setIsFormTouched] = useState(false);

    const navigateWithConfirmation = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const currentNewsletterId = (new URLSearchParams(search)).get("newsletterId");

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.COMMUNICATION_NEWSLETTER, PERMISSION_ACTION.MODIFY );

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;


    const statistics = [
        {
            title: 'backoffice.communication.received',
            count: generalInfo.generalInfo?.receivedCount,
            name: 'email',
            className: 'rt--statistic-received rt--statistic-received-count'
        },
        {
            title: 'backoffice.communication.clicked',
            count: generalInfo.generalInfo?.clickedCount,
            name: 'marketingSend',
            className: 'rt--statistic-clicked rt--statistic-clicked-count'
        },
        {
            title: 'backoffice.communication.opened',
            count: generalInfo.generalInfo?.openedCount,
            name: 'email-read',
            className: 'rt--statistic-opened rt--statistic-opened-count'

        }]

    /** Load general info */
    useEffect(() => {
        getNewsletterGeneralInfo(currentNewsletterId)
    }, [])

    /** Set default values */
    useEffect(() => {
        setFieldsValue({
            scheduledDate: generalInfo.scheduledDate ? dateService.makeUtcDateToLocal(generalInfo.scheduledDate) : null
        })
    }, [generalInfo])

    /** Fires when form submitted
      * @function
      * @memberOf GeneralInfoComponent
  */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveNewsletterGeneralInfo({
                    ...data,
                    id: currentNewsletterId
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    const canChangeSchedule = generalInfo.scheduledDate && dateService.getDifference(dateService.makeUtcDateToLocal(generalInfo.scheduledDate), dateService.makeUtcDateToLocal(dateService.getNow()), 'minutes') >= 5;

    return (
        <SubTabFormDashboardLayout
            buttons={[
                {
                    type: "primary",
                    onClick: handleForm,
                    text: t("backoffice.common.save"),
                    enabled: hasModifyPermission,
                    loading: isSaving,
                    disabled: !isFormTouched
                },
                {
                    type: "secondary",
                    onClick: () => {
                        const url = deleteQueryStringParamsToUrl(pathname, search, hash, ["newsletterId", "newsletterName"]);
                        navigateWithConfirmation(url, { replace: true })
                    },
                    text: (
                        <span className='rt--back-button'>
                            <Icon name="left" size={16} />
                            <span>
                                {t("backoffice.common.back")}
                            </span>
                        </span>
                    ),
                    enabled: true,
                    className: "rt--button-secondary",
                    placement: "left",
                },
            ]}
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={(changed, formValues) => setIsFormTouched(
                        isFormChanged(
                            { 
                                scheduledDate: formValues.scheduledDate ? dateService.makeUtcDateToLocal(formValues.scheduledDate) : ""
                            }, 
                            {
                                scheduledDate: generalInfo.scheduledDate ? dateService.makeUtcDateToLocal(generalInfo.scheduledDate) : ""
                            }
                        )
                    )}
                >
                  <Row gutter={[16, 0]}>
                        {statistics.map((stat, index) => (
                            <Col key={index} span={24} lg={6}>
                                <div className={`rt--statistic rt--statistic-${stat.name} rt--flex rt--align-center rt--justify-between rt--pl-28 ` + (isMobile() ? 'rt--mb-16 rt--pr-14' : 'rt--mb-24 rt--pr-34')}>
                                    <div className='rt--statistic-content rt--flex rt--flex-col rt--flex-equal'>
                                        <span className='rt--statistic-title rt--font-extra-bold rt--font-small'>
                                            {t(stat.title)}
                                        </span>
                                        <span className={`rt--statistic-count rt--font-extra-bold rt--font-bigest`}
                                            title={`${formatNumber(stat.count)}`}>
                                            {formatNumber(stat.count)}
                                        </span>
                                    </div>
                                    <div className='rt--statistic-icon-background rt--flex rt--align-center rt--justify-center'>
                                        <div className={`rt--statistic-icon rt--flex rt--align-center rt--justify-center`}>
                                            <Icon className="rt--font-28" name={stat.name} />
                                        </div>
                                    </div>
                                </div>
                            </Col>
                        ))}
                    </Row>

                    <Row gutter={[16, 0]}>
                        <Col span={24} lg={6}>
                            <Form.Item
                                label={`${t('backoffice.communication.chooseNewsletter')} *`}
                                className="rt--form-item-disabled"
                            >
                                <Select
                                    options={[]}
                                    placeholder={`${t('backoffice.common.select')} ${t('backoffice.communication.newsletter')}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    disabled={true}
                                    value={generalInfo.name}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={6}>
                            <Form.Item
                                label={`${t('backoffice.communication.subject')} *`}
                                className='rt--general-form-item rt--form-item-disabled'
                            >
                                <Input
                                    value={generalInfo.subject}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={6}>
                            <Form.Item
                                label={`${t('backoffice.communication.from')} *`}
                                className='rt--general-form-item rt--form-item-disabled'
                            >
                                <Input
                                    value={generalInfo.from}
                                    disabled={true}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col span={24} lg={6}>
                            <Form.Item
                                label={`${t('backoffice.communication.scheduleDate')} *`}
                                name="scheduledDate"
                                className={!canChangeSchedule ? "rt--form-item-disabled" : ""}
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') },
                                ]}
                            >
                                <DatePicker
                                    className="rt--datepicker"
                                    dropdownClassName='rt--datepicker-popup rt--datepicker-withtime-popup'
                                    format={`${dateService.getFormat(true)}`}
                                    showTime={{ format: dateService.getFormat(true, true) }}
                                    disabledDate={d => dateService.isBefore(d, dateService.getNow(true))}
                                    showToday={false}
                                    showNow={false}
                                    allowClear={false}
                                    placeholder={t('backoffice.common.selectDate')}
                                    suffixIcon={<Icon name='date' />}
                                    getPopupContainer={isMobile() ? '' : () => document.getElementsByClassName("rt--portal-layout")[0]}
                                    inputReadOnly={true}
                                    disabled={!canChangeSchedule}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={6}>
                            <Form.Item
                                label=" "
                            >
                                <Status type={STATUS_TYPES.NEWSLETTER} status={generalInfo.generalInfo?.status} />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    );
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Redux state property, is true when newsletter is loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when newsletter is saving */
    isSaving: PropTypes.bool,
    /** Redux action to get newsletter general info */
    getNewsletterGeneralInfo: PropTypes.func,
    /** Redux action to save newsletter general info */
    saveNewsletterGeneralInfo: PropTypes.func,
    /** Redux state property, the general info */
    generalInfo: newsletterType,
    /** On Tab change */
    onTabChange: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getNewsletterGeneralInfo: id => {
            dispatch(getNewsletterGeneralInfo(id))
        },

        saveNewsletterGeneralInfo: info => {
            dispatch(saveNewsletterGeneralInfo(info))
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.newsletter.isSaving,
        isLoading: state.newsletter.isLoading,
        generalInfo: state.newsletter.newsletters.edit.general,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GeneralInfoComponent);