import React from "react";

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from "components/common/tabs";

import ClickReportComponent from "./clickReport/clickReport.component";
import MediaReportComponent from "./mediaReport/mediaReport.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

/** Marketing Reports Page Component */
const MarketingReportsComponent = () => {

    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.reports.clicks"),
            permissions: [{ resource: PERMISSION_RESOURCE.REPORT_CLICKS, action: PERMISSION_ACTION.VIEW }],
            component: <ClickReportComponent />
        },

        {
            title: t("backoffice.reports.medias"),
            permissions: [{ resource: PERMISSION_RESOURCE.REPORT_MEDIA_PERFORMANCE, action: PERMISSION_ACTION.VIEW }],
            component: <MediaReportComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={[
                { title: t('backoffice.menu.reports') },
                { title: t('backoffice.menu.marketing') }
            ]}
        />
    )
};

export default MarketingReportsComponent;
