//#region react
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
//#endregion

//#region actions
import {
    getClickReport,
    setClickReportSorting,
    setClickReportFilters
} from 'store/actions/portal/reports/marketing/clickReport.action';
//#endregion

//#region components
import TabTableDashboardLayout from "components/layouts/tab/table";
import Table from "components/common/table";
import Filters from "./filters.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useAccess from 'hooks/useAccess';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
//#endregion

//#region constants
import {
    PERMISSION_RESOURCE,
    PERMISSION_ACTION
} from 'constants/permissions.constants';
import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';
import ApiUrls from 'constants/api.constants';
import { CLICKS_REPORT } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import clickReportType from 'types/reports/clickReport.type';
import sortingType from 'types/common/sorting.type';
//#endregion

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';
import usePermissions from 'core/hooks/usePermission';

/** Click Report Component */
const ClickReportComponent = ({
    report,
    sorting,
    filters,
    total,
    isLoading,
    getClickReport,
    setClickReportSorting,
    setClickReportFilters
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: CLICKS_REPORT });

    const { hasAccess } = useAccess()

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//

    const hasDataExportPermission = permissionUtils.has( PERMISSION_RESOURCE.REPORT_CLICKS, PERMISSION_ACTION.EXPORT );

    //#endregion

    //#region --------------------------------------- HANDLERS ----------------------------------------//

    const handleSearchChange = (value, item) => {
        setClickReportFilters({
            ...filters,
            mediaNameOrId: value ? item ? item.id : value : ""
        })
    }

    const handleDateSearchChange = value => {
        setClickReportFilters({
            ...filters,
            from: value[0],
            to: value[1]
        })
    }

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const {
        mainTableColumns,
        columnsForExport,
        columnsThatCanBeIncluded
    } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForExport: true,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                hasAccess,
                t
            },
            t
        })
    }, [includedColumns, hasAccess, t]);

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        filters: <Filters />,
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        search: {
            onSearch: handleSearchChange,
            loadFn: getClickReport,
            autoSuggestion: {
                type: AUTOSUGGESTION_TYPE.MEDIA,
                dependencies: [globalProjectId]
            },
            placeholder: t("backoffice.reports.mediaNameOrId"),
            showId: true
        },
        date: {
            onSearch: handleDateSearchChange,
            placeholder: t('backoffice.reports.clickDate'),
            showTime: true,
            allowClear: false,
            enabledMountsCount: 12,
            loadFn: getClickReport,
            value: [filters.from, filters.to]
        },
    }

    if (hasDataExportPermission) {
        headerPartsData.export = {
            tableName: t("backoffice.reports.clicks"),
            columns: columnsForExport,
            url: ApiUrls.EXPORT_CLICK_REPORT,
            filters: filters
        }
    }

    //#endregion
    
    return (
        <TabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading}
                columns={mainTableColumns}
                data={report}
                loadFn={getClickReport}
                sorting={sorting}
                setSortingFn={setClickReportSorting}
                filters={filters}
                setFiltersFn={setClickReportFilters}
                total={total}
                updateProps={[globalProjectId]}
                enableReload={true}
            />

        </TabTableDashboardLayout>
    )
}

/** ClickReportComponent propTypes
    * PropTypes
*/
ClickReportComponent.propTypes = {
    /** Redux state property, represents the array of click report */
    report: PropTypes.arrayOf(clickReportType),
    /** Redux state property, click report sorting details */
    sorting: sortingType,
    /** Redux state property, click report filters */
    filters: PropTypes.object,
    /** Redux state property, click report total count */
    total: PropTypes.number,
    /** Redux state property, is true when loading click report */
    isLoading: PropTypes.bool,
    /** Redux action to get click report */
    getClickReport: PropTypes.func,
    /** Redux action to set click report sorting details */
    setClickReportSorting: PropTypes.func,
    /** Redux action to set click report filters */
    setClickReportFilters: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getClickReport: nextPage => {
            dispatch(getClickReport(nextPage))
        },
        setClickReportSorting: sorting => {
            dispatch(setClickReportSorting(sorting));
        },
        setClickReportFilters: (filters, keepPage) => {
            dispatch(setClickReportFilters(filters, keepPage));
        }
    }
)

const mapStateToProps = state => {
    return {
        report: state.marketingReports.clickReport.report,
        total: state.marketingReports.clickReport.total,
        sorting: state.marketingReports.clickReport.sorting,
        filters: state.marketingReports.clickReport.filters,
        isLoading: state.marketingReports.isLoading
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClickReportComponent)