import React, { useState } from "react";

import { Menu as AntMenu } from "antd";

import { LOGO_TYPE } from "core/constants/common/logo";

import useDevice from "core/hooks/useDevice";

import Icon from "core/ui-kit/icon";

import useLogo from "core/hooks/useLogo";



const LeftBar = ({ items, handleMenuClick, selectedPostId }) => {
  const [openedKeys, setOpenedKeys] = useState([]);

  const { isMobile } = useDevice();

  const { logos } = useLogo();

  const mappedMenuItems = items.map((item) => ({
    key: item.id,
    label: item.title,
  }));

  const onMenuClick = ({ key }) => {
    document.body.classList.remove("rt--body-open-sidebar");
    handleMenuClick(key);
  };

  return (
    <div className="rt--guide-layout-left-bar">
      <div className="rt--guide-layout-left-bar-content">
        {isMobile && (
          <div className="lu rt--flex rt--align-center rt--justify-between rt--pl-10 rt--pt-10 rt--pr-10 rt--pb-10 rt--mb-24">
            <div className="rt--guide-layout-header-logo">
              <img
                alt="logo"
                src={`${import.meta.env.SYSTEM_CDN_URL}/${
                  logos[LOGO_TYPE.LOGO]
                }`}
              />
            </div>

            <div className="rt--guide-layout-header-logo">
              <Icon
                name="close"
                size={30}
                color="white"
                onClick={() =>
                  document.body.classList.remove("rt--body-open-sidebar")
                }
              />
            </div>
          </div>
        )}

        <div className="rt--guide-layout-left-bar-content-menu">
          <AntMenu
            mode="inline"
            expandIcon={
              <Icon name="down" className="rt--sidebar-content-menu-down" />
            }
            openKeys={openedKeys}
            onOpenChange={(keys) => {
              setOpenedKeys(
                keys.filter((k) => k.length === 1 || keys.includes(k[0]))
              );
            }}
            onClick={onMenuClick}
            selectedKeys={[selectedPostId]}
          >
            {mappedMenuItems.map((item) => (
              <AntMenu.Item key={item.key} title={item.label}>
                {item.label}
              </AntMenu.Item>
            ))}
          </AntMenu>
        </div>
      </div>
    </div>
  );
};

export default LeftBar;
