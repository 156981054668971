import { COMMISSION_PLAN_TYPE } from "constants/commission.constants";

export const getTableColumns = additionalProps => {
    const { t } = additionalProps;

    return [
        {
            title: "backoffice.common.id",
            dataIndex: "longId",
            copy: true,
            mobileLevel: 1
        },
        {
            title: "backoffice.commissionplans.name",
            dataIndex: "name",
            mobileLevel: 2
        },
        {
            title: "backoffice.commissionplans.commissionType",
            dataIndex: "type",
            render: value => value === COMMISSION_PLAN_TYPE.REVSHARE ? t("backoffice.commissionplans.revshare") :
                        value === COMMISSION_PLAN_TYPE.CPA ? t("backoffice.commissionplans.cpa") : 
                        value === COMMISSION_PLAN_TYPE.FIX ? t("backoffice.commissionplans.fix") : "",
            mobileLevel: 3
        },
        {
            title: "backoffice.commissionplans.currency",
            dataIndex: "currencyCode",
            mobileLevel: 4
        }
    ];
}