import axios from "core/lib/axios";
import Methods from 'constants/methods.constants';

export const doUploadRequest = ({ action, onSuccess, onError, onProgress, file, data }) => {
    const formData = new FormData();
    formData.append('file', file);
    if (data) {
        Object.keys(data).forEach(k => {
            formData.append(k, data[k]);
        })
    }

    //const cancelTokenSource = axios.CancelToken.source();

    axios({
        url: action,
        method: Methods.POST,
        headers: {
            "content-type": 'multipart/form-data; boundary=----WebKitFormBoundaryqTqJIxvkWFYqvP5s'
        },
       // cancelToken: cancelTokenSource.token,
        onUploadProgress: event => {
            onProgress(event);
        },
        data: formData
    }).then(({ data, status }) => {
        if (status === 200) {
            onSuccess(data, file)
        } else {
            onError(data, file)
        }
    }).catch(ex => {
        onError()
    })

    return cancelTokenSource;
}