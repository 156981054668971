import React from "react";

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from 'components/common/tabs';

import PendingComponent from "./pending/pending.component";
import HistoryComponent from "./history/history.component";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from "constants/permissions.constants";

/** Requests Page Component */
const RequestsComponent = () => {

    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.menu.pendingRequests"),
            permissions: [{ resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS, action: PERMISSION_ACTION.VIEW }],
            component: <PendingComponent/>
        },
        {
            title: t("backoffice.users.history"),
            permissions: [{ resource: PERMISSION_RESOURCE.PERMISSION_REQUESTS, action: PERMISSION_ACTION.VIEW }],
            component: <HistoryComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={{ title: t('backoffice.menu.requests') }}
        />
    )
};

export default RequestsComponent
