import React, { forwardRef, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import { Input as AntInput } from 'antd';

import Icon from "core/ui-kit/icon";

import useDevice from 'core/hooks/useDevice';

import isFunction from 'core/helpers/typeChecks/isFunction';
import isNull from 'core/helpers/typeChecks/isNull';
import isUndefined from 'core/helpers/typeChecks/isUndefined';

import InputUrl from './inputUrl';
import InputNumber from './inputNumber';

import { INPUT_TYPE } from 'core/constants/component/input';

const Input = forwardRef(({
	value,
	placeholder,
	type=INPUT_TYPE.TEXT,
    onChange,
	...rest
}, ref) => {

    const inputRef = useRef(null);

    const { isMobile, isIOS, osVersion } = useDevice();

    const hidePlaceholder = isMobile && isIOS && osVersion >= 16;

    useEffect(() => {
        let inputElement;
        if(type === INPUT_TYPE.TEXTAREA){
            inputElement = inputRef.current?.resizableTextArea?.textArea;
        } else {
            inputElement = inputRef.current?.input;
        }
		if (inputElement) {
			if (value !== '' && !isNull(value) && !isUndefined(value)) {
				inputElement.classList.add("rt--hide-placeholder")
			} else {
				inputElement.classList.remove("rt--hide-placeholder")
			}
		}
	}, [value])

    const assignToExternalRef = elem => {
		inputRef.current = elem;

		if (!Boolean(ref)) {
			return;
		}

		if (isFunction(ref)) {
			ref(elem)
		} else {
			ref.current = elem;
		}
	}

    return (
        <div 
            className='rt--input-wrapper'
            data-placeholder={placeholder}
        >
            {
                type === INPUT_TYPE.PASSWORD ? (
                    <AntInput.Password
                        ref={assignToExternalRef}
                        {...rest}
                        value={value}
                        onChange={onChange}
                        placeholder={hidePlaceholder ? null : placeholder}
                    />
                ) : type === INPUT_TYPE.SEARCH ? (
                    <AntInput
                        ref={assignToExternalRef}
                        {...rest}
                        value={value}
                        onChange={onChange}
                        placeholder={hidePlaceholder ? null : placeholder}
                        prefix={<Icon name="search" />}
                    />
                ) : type === INPUT_TYPE.URL ? (
                    <InputUrl
                        ref={assignToExternalRef}
                        value={value}
                        onChange={onChange}
                        placeholder={hidePlaceholder ? null : placeholder}
                        {...rest}
                    />
                ) : type === INPUT_TYPE.NUMBER ? (
                    <InputNumber
                        ref={assignToExternalRef}
                        value={value}
                        onChange={onChange}
                        placeholder={hidePlaceholder ? null : placeholder}
                        {...rest}
                    />
                ) : type === INPUT_TYPE.TEXTAREA ? (
                    <AntInput.TextArea
                        ref={assignToExternalRef}
                        value={value}
                        onChange={onChange}
                        placeholder={hidePlaceholder ? null : placeholder}
                        rows={4}
                        {...rest}
                    />
                ) : (
                    <AntInput
                        ref={assignToExternalRef}
                        {...rest}
                        value={value}
                        onChange={onChange}
                        placeholder={hidePlaceholder ? null : placeholder}
                    />
                )
            }
        </div>
    )
})

Input.propTypes = {
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	placeholder: PropTypes.string,
	type: PropTypes.oneOf(Object.values(INPUT_TYPE)),
}

export default Input;