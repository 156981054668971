import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useParams, useLocation } from "react-router-dom";

import EntityActions from "components/common/entityActions";
import SetPasswordComponent from "components/common/setPassword";

import { changeAffiliateManagerState, unlockAffiliateManager } from "store/actions/portal/userManagement/affiliateManagers/affiliateManagers.action";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { USER_STATE } from 'constants/user.constants';

import userGeneralInfoType from "types/user/generalInfo.type";

import { globalCompanyIdSelector, useAuthSelector } from 'core/stores/authStore';
import { useGlobalSelector, userInfoSelector } from 'core/stores/globalStore';
import usePermissions from 'core/hooks/usePermission';

/** Affiliate Manager Actions Button Component */
const AffiliateManagerActionsComponent = ({
    generalInfo,
    unlockAffiliateManager,
    changeAffiliateManagerState
}) => {

    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const globalCompanyId = useAuthSelector(globalCompanyIdSelector);
    const userInfo = useGlobalSelector(userInfoSelector);

    const searchParams = useParams();

    /** Flag to show/hide set password popup */
    const [showSetPasswordPopup, setShowSetPasswordPopup] = useState(false);

    const canEdit = (
        permissionUtils.has( PERMISSION_RESOURCE.AFFILIATE_MANAGER, PERMISSION_ACTION.MODIFY ) &&
        searchParams.id !== userInfo.id
    )

    /** Actions list */
    const actions = [
        /** Set Password */
        {
            key: "setPassword",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.setPassword'),
            icon: "password",
            onClick: () => setShowSetPasswordPopup(true),
            disabled: !canEdit || generalInfo.state === USER_STATE.BLOCKED
        },
        /** Block/Unblock */
        {
            key: "block",
            buttonType: "secondary",
            title: generalInfo.state === USER_STATE.BLOCKED ? t('backoffice.common.unblock') : t('backoffice.common.block'),
            buttonClassName: generalInfo.state === USER_STATE.BLOCKED ? "rt--button-secondary" : "rt--button-danger",
            onClick: reason => {
                changeAffiliateManagerState({
                    id: searchParams.id,
                    enabled: generalInfo.state === USER_STATE.BLOCKED,
                    reason: reason
                })
            },
            icon: "block",
            isStatusChange: true,
            disabled: !canEdit
        },
        /** Unlock */
        {
            key: "unlock",
            buttonType: "secondary",
            buttonClassName: "rt--button-secondary",
            title: t('backoffice.common.unlock'),
            icon: "lock",
            onClick: () => {
                unlockAffiliateManager(searchParams.id)
            },
            isConfirmation: true,
            confirmationMessage: t('backoffice.users.doYouWanttoUnlockAffiliateManager'),
            disabled: !canEdit || ( generalInfo.state !== USER_STATE.LOCKED_2_FA && generalInfo.state !== USER_STATE.LOCKED_LOGIN )
        },
    
    ]

    return (
        <Fragment>
            <EntityActions 
                actions={actions}
            />

            {
                showSetPasswordPopup && (
                    <SetPasswordComponent
                        onClose={() => setShowSetPasswordPopup(false)}
                        companyId={globalCompanyId}
                    />
                )
            }
        </Fragment>
        
    )
}

/** AffiliateManagerActionsComponent propTypes
    * PropTypes
*/
AffiliateManagerActionsComponent.propTypes = {
    /** Redux state, represents the general info of current editing affiliate manager  */
    generalInfo: userGeneralInfoType,
    /** Redux action to unlock affiliate manager */
    unlockAffiliateManager: PropTypes.func,
    /** Redux action to block/unblock affiliate managers */
    changeAffiliateManagerState: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        unlockAffiliateManager: id => {
            dispatch(unlockAffiliateManager(id));
        },

        changeAffiliateManagerState: data => {
            dispatch(changeAffiliateManagerState(data));
        },
    }
)

const mapStateToProps = state => {
    return {
        generalInfo: state.affiliateManagers.edit.general,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateManagerActionsComponent)