import httpService from "core/services/httpService";

import API_URL from "core/constants/api/apiUrl";

const projectService = {
    getPostbackSettings(signal){
        return httpService.get({
            url: API_URL.GET_POSTBACK_SETTINGS,
            signal
        }).then(({ data: { value } }) => {
            return value;
        })
    },

    savePostbackSettings(data, signal){
        return httpService.post({
            url: API_URL.SAVE_POSTBACK_SETTINGS,
            signal,
            requestBody: data
        }).then(({ data: { value, message } }) => {
            return { value, message};
        })
    }
}

export default projectService