const API_URL = {
	AUTHENTICATE: "/Account/Login",
	REFRESH_TOKEN: "/Account/RefreshToken",
	AUTHENTICATE_QR: "/Account/GoogleAuthenticatorQR",
	AUTHENTICATE_TOKEN: "/Account/GoogleAuthenticateToken",
	RESET_PASSWORD: "/Account/ResetPassword",
	SET_PASSWORD: "/Account/SetPassword",
	VERIFY_EMAIL: "/Account/VerifyEmail",
	FORGOT_PASSWORD: "/Account/ForgotPassword",
	GET_ACCOUNT_PROJECT_ACCESS: "/Account/ProjectsAccess",
	ADD_ACCOUNT_PROJECT_ACCESS: "/Account/AddProjectAccess",
	DELETE_ACCOUNT_PROJECT_ACCESS: "/Account/DeleteProjectAccess",
	GET_ACCOUNT_PASSWORD_SETTINGS: "/Account/PasswordSettings",
	GET_ACCOUNT_RESET_PASSWORD_SETTINGS: "/Account/ResetPasswordSettings",
	UPLOAD_AVATAR: "/Account/UploadAvatar",
	UPLOAD_PROFILE_AVATAR: "/Account/UploadProfileAvatar",
	DELETE_ACCOUNT_AVATAR: "/Account/DeleteAvatar",
	DELETE_ACCOUNT_PROFILE_AVATAR: "/Account/DeleteProfileAvatar",
	CHANGE_ACCOUNT_STATE: "/Account/ChangeState",
	FORCE_LOGOUT_ACCOUNT: "/Account/ForceLogout",
	GET_USER_INFO: "/Account/Info",
	GET_PROFILE: "/Account/Profile",
	SAVE_PROFILE: "/Account/SaveProfile",
	GET_FORMAT_SETTINGS: "/Account/GetFormattingSetting",
	SAVE_FORMAT_SETTINGS: "/Account/SaveFormattingSetting",
	CHANGE_PASSWORD: "/Account/ChangePassword",
	CHANGE_ACCOUNT_CURRENCY: "/Account/ChangeCurrency",
	CHANGE_ACCOUNT_PROJECT: "/Account/ChangeProject",
	CHANGE_ACCOUNT_PASSWORD: "/Account/ChangeUsersPassword",
	UNLOCK_ACCOUNT: "/Account/ResetFailedLoginAttemptsState",
	SWITCH_ACCOUNT: "/Account/Switch",
	SWITCH_BACK_ACCOUNT: "/Account/SwitchBack",
    GET_CURRENCIES: "/Account/Currencies",

	GET_CHAT_ACCOUNT: "/Chat/Account",
	GET_CHAT_STATUS: "/Chat/Status",

	GET_COMPANIES: "/Company/All",
	GET_ALL_COMPANIES: "/Company/Available",
	CREATE_COMPANY: "/Company/Create",
	GET_COMPANY_GENERAL_INFO: "/Company/GeneralInfo",
	SAVE_COMPANY_GENERAL_INFO: "/Company/SaveGeneralInfo",

	COMPANY_GET_PASSWORD_SETTINGS: "/Company/PasswordSettings",
	COMPANY_SAVE_PASSWORD_SETTINGS: "/Company/SavePasswordSettings",
	COMPANY_GET_TOKEN_SETTINGS: "/Company/TokenSettings",
	COMPANY_SAVE_TOKEN_SETTINGS: "/Company/SaveTokenSettings",
	COMPANY_GET_AUTHENTICATION_SETTINGS: "/Company/AuthenticationSettings",
	COMPANY_SAVE_AUTHENTICATION_SETTINGS: "/Company/SaveAuthenticationSettings",

	CREATE_SYSTEM_CURRENCY: "/SystemCurrency/Create",
	GET_SYSTEM_CURRENCIES: "/SystemCurrency/All",
	EXPORT_SYSTEM_CURRENCIES: "/SystemCurrency/Export",
	GET_SYSTEM_AVAILABLE_CURRENCIES: "/SystemCurrency/SystemAvailable",
	SAVE_SYSTEM_CURRENCY: "/SystemCurrency/Save",
	GET_AVAILABLE_CURRENCIES: "/SystemCurrency/Available",

	CREATE_SYSTEM_LANGUAGE: "/SystemLanguage/Create",
	GET_SYSTEM_LANGUAGES: "/SystemLanguage/All",
	EXPORT_SYSTEM_LANGUAGES: "/SystemLanguage/Export",
	GET_SYSTEM_AVAILABLE_LANGUAGES: "/SystemLanguage/SystemAvailable",
	GET_SYSTEM_AVAILABLE_PUBLISHED_LANGUAGES: "/SystemLanguage/SystemAvailablePublished",
	GET_AVAILABLE_LANGUAGES: "/SystemLanguage/Available",

	GET_SYSTEM_PAYMENT_METHOD: "/Payment/SystemPaymentMethod",
	GET_SYSTEM_PAYMENT_METHODS: "/Payment/SystemPaymentMethods",
	EXPORT_SYSTEM_PAYMENT_METHODS: "/Payment/ExportSystemPaymentMethods",
	CREATE_SYSTEM_PAYMENT_METHOD: "/Payment/CreateSystemPaymentMethod",
	SAVE_SYSTEM_PAYMENT_METHOD: "/Payment/SaveSystemPaymentMethod",
	DELETE_SYSTEM_PAYMENT_METHOD: "/Payment/DeleteSystemPaymentMethod",
	UPLOAD_SYSTEM_PAYMENT_LOGO: "/Payment/UploadSystemPaymentMethodLogo",
	GET_SYSTEM_PAYMENT_METHOD_FORM_TRANSLATIONS: "/Payment/SystemPaymentMethodFormTranslations",
	SAVE_SYSTEM_PAYMENT_METHOD_FORM_TRANSLATIONS: "/Payment/SaveSystemPaymentMethodFormTranslations",

	GET_AFFILIATES: "/Affiliate/All",
	CREATE_AFFILIATE: "/Affiliate/Create",
	EXPORT_AFFILIATES: "/Affiliate/Export",
	APPROVE_AFFILIATE: "/Affiliate/Approve",
	DECLINE_AFFILIATE: "/Affiliate/Decline",
	FORCE_LOGOUT_AFFILIATE: "/Affiliate/ForceLogout",
	GET_AFFILIATE_GENERAL_INFO: "/Affiliate/GeneralInfo",
	SAVE_AFFILIATE_GENERAL_INFO: "/Affiliate/SaveGeneralInfo",
	GET_AFFILIATE_ADDITIONAL_INFO: "/Affiliate/AdditionalInfo",
	SAVE_AFFILIATE_ADDITIONAL_INFO: "/Affiliate/SaveAdditionalInfo",
	GET_AFFILIATE_TRAFFIC_SOURCES: "/Affiliate/TrafficSources",
	SAVE_AFFILIATE_TRAFFIC_SOURCES: "/Affiliate/SaveTrafficSource",
	ADD_AFFILIATE_TRAFFIC_SOURCE: "/Affiliate/AddTrafficSource",
	DELETE_AFFILIATE_TRAFFIC_SOURCE: "/Affiliate/DeleteTrafficSource",
	GET_AFFILIATE_SUB_AFFILIATES: "/Affiliate/SubAffiliates",
    GET_AFFILIATE_NEGATIVE_CARRY_OVER: "/Affiliate/NegativeCarryOverSetting",
    SAVE_AFFILIATE_NEGATIVE_CARRY_OVER: "/Affiliate/SaveNegativeCarryOverSetting",

	GET_AFFILIATE_GROUPS: "/Affiliate/Groups",
	CREATE_AFFILIATE_GROUP: "/Affiliate/CreateGroup",
	REMOVE_AFFILIATE_GROUP: "/Affiliate/RemoveGroup",
	GET_AFFILIATE_GROUP_AVAILABLE_MEMBERS: "/Affiliate/MembersAvailableForGroup",
	GET_AFFILIATE_GROUP_GENERAL_INFO: "/Affiliate/GroupGeneralInfo",
	SAVE_AFFILIATE_GROUP_GENERAL_INFO: "/Affiliate/SaveGroupGeneralInfo",
	GET_AFFILIATE_GROUP_MEMBERS: "/Affiliate/GroupMembers",
	GET_AFFILIATE_GROUP_FILTER: "/Affiliate/GetGroupFilter",
	SAVE_AFFILIATE_GROUP_FILTER: "/Affiliate/SaveGroupFilter",
	SAVE_AFFILIATE_GROUP_MEMBERS: "/Affiliate/SaveGroupMembers",

	GET_AFFILIATE_PAYMENT_METHODS: "/Payment/AffiliatePaymentMethods",
	GET_AFFILIATE_AVAILABLE_PAYMENT_METHODS: "/Payment/AvailableAffiliatePaymentMethods",
	GET_AFFILIATE_PAYMENT_METHOD_DETAILS: "/Payment/AffiliatePaymentMethodDetails",
	ADD_AFFILIATE_PAYMENT_METHOD: "/Payment/AddAffiliatePaymentMethod",
	SAVE_AFFILIATE_PAYMENT_METHOD: "/Payment/SaveAffiliatePaymentMethodDetails",
	DELETE_AFFILIATE_PAYMENT_METHOD: "/Payment/DeleteAffiliatePaymentMethod",
    SET_AFFILIATE_DEFAULT_PAYMENT_METHOD: "/Payment/SetDefaultAffiliatePaymentMethod",

	GET_COMMISSION_PLANS: "/CommissionPlan/All",
	CREATE_COMMISSION_PLAN: "/CommissionPlan/Create",
	GET_COMMISSION_PLAN_GENERAL_INFO: "/CommissionPlan/GeneralInfo",
	SAVE_COMMISSION_PLAN_REV_SHARE: "/CommissionPlan/SaveRevShareGeneralInfo",
	SAVE_COMMISSION_PLAN_CPA: "/CommissionPlan/SaveCPAGeneralInfo",
	SAVE_COMMISSION_PLAN_FIX: "/CommissionPlan/SaveFixGeneralInfo",
	CHANGE_COMMISSION_PLAN_STATE: "/CommissionPlan/ChangeStatus",
	DELETE_COMMISSION_PLAN: "/CommissionPlan/Delete",
	REMOVE_COMMISSION_PLAN: "/CommissionPlan/Remove",
	GET_AVAILABLE_COMMISSION_PLANS: "/CommissionPlan/Available",
	ADD_COMMISSION_PLAN: "/CommissionPlan/Add",

	RECALCULATE_COMMISSION_PLAN: "/CommissionPlan/Recalculate",

	GET_COMMISSION_CALCULATION_HISTORY: "/CommissionPlan/CalculationHistory",
	EXPORT_COMMISSION_CALCULATION_HISTORY: "/CommissionPlan/ExportHistory",
	GET_COMMISSION_CALCULATION_APPROVALS: "/CommissionPlan/CalculationApprovals",
	EXPORT_COMMISSION_CALCULATION_APPROVALS: "/CommissionPlan/ExportCalculationApprovals",
	APPROVE_COMMISSION_CALCULATION: "/CommissionPlan/Approve",

	GET_AFFILIATE_WALLETS: "/Wallet/AffiliateWallets",

	AFFILIATE_DEPOSIT_TO_EARNING_BALANCE: "/Wallet/AffiliateDepositToEarningBalance",
	AFFILIATE_WITHDRAW_FROM_EARNING_BALANCE: "/Wallet/AffiliateWithdrawFromEarningBalance",
	WALLET_ROLLBACK_TRANSACTION: "/Wallet/RollbackTransaction",

	GET_PLAYERS: "/Player/All",
	EXPORT_PLAYERS: "/Player/Export",
	GET_PLAYER_GENERAL_INFO: "/Player/GeneralInfo",
	GET_PLAYER_GENERAL_INFO_BY_EXTERNAL_ID: "/Player/GeneralInfoByExternalId",
	GET_PLAYER_BETS: "/Player/Bets",
	EXPORT_PLAYER_BETS: "/Player/ExportBets",

	GET_PENDING_INVOICES: "/Invoice/PendingHistory",
	GET_INVOICES_HISTORY: "/Invoice/History",
	EXPORT_PENDING_INVOICES: "/Invoice/ExportPendingHistory",
	EXPORT_INVOICES_HISTORY: "/Invoice/ExportHistory",
	EXPORT_INVOICE: "/Invoice/ExportInvoice",
	CREATE_INVOICE: "/Invoice/Create",
	APPROVE_INVOICE: "/Invoice/Approve",
	REJECT_INVOICE: "/Invoice/Reject",
	DELETE_INVOICE: "/Invoice/Delete",
	SAVE_INVOICE_GENERAL_INFO: "/Invoice/SaveGeneralInfo",
	GET_INVOICE_AVAILABLE_PAYMENTS: "/Invoice/AvailablePayments",
	GET_INVOICES_TOTALS: "/Invoice/InvoiceTotals",

	GET_CLICK_REPORT: "/Report/Clicks",
	EXPORT_CLICK_REPORT: "/Report/ExportClicks",

	GET_MEDIA_REPORT: "/Report/MediaPerformance",
	EXPORT_MEDIA_REPORT: "/Report/ExportMediaPerformance",
    GET_MEDIA_REPORT_TOTALS: "/Report/MediaPerformanceTotals",

	GET_AFFILIATE_PERFORMANCE_REPORT: "/Report/AffiliatePerformance",
	EXPORT_AFFILIATE_PERFORMANCE_REPORT: "/Report/ExportAffiliatePerformance",
	GET_AFFILIATE_PERFORMANCE_REPORT_TOTALS: "/Report/AffiliatePerformanceTotals",

	GET_PLAYER_PERFORMANCE_REPORT: "/Report/PlayerPerformance",
	EXPORT_PLAYER_PERFORMANCE_REPORT: "/Report/ExportPlayerPerformance",
	GET_PLAYER_PERFORMANCE_REPORT_TOTALS: "/Report/PlayerPerformanceTotals",

	GET_AGGREGATED_DATA: "/Report/AggegationHistory",
	EXPORT_AGGREGATED_DATA: "/Report/ExportAggegationHistory",

	GET_WALLET_HISTORY: "/Report/WalletsHistory",
	EXPORT_WALLET_HISTORY: "/Report/ExportWalletsHistory",

    GET_POSTBACKS: "/Postback/All",
    EXPORT_POSTBACKS: "/Postback/Export",
    CREATE_POSTBACK: "/Postback/Create",
    GET_POSTBACK_GENERAL_INFO: "/Postback/GeneralInfo",
    SAVE_POSTBACK_GENERAL_INFO: "/Postback/SaveGeneralInfo",
    CHANGE_POSTBACK_STATE: "/Postback/ChangeState",
    DELETE_POSTBACK: "/Postback/Delete",
    GET_POSTBACK_LOGS: "/Postback/Logs",
    EXPORT_POSTBACK_LOGS: "/Postback/ExportLogs",

	GET_PROJECTS: "/Project/All",
	EXPORT_PROJECTS: "/Project/Export",
	CREATE_PROJECT: "/Project/Create",
	GET_PROJECT_GENERAL_INFO: "/Project/GeneralInfo",
	SAVE_PROJECT_GENERAL_INFO: "/Project/SaveGeneralInfo",
    DELETE_PROJECT_LOGO: "/Project/DeleteLogo",
    UPLOAD_PROJECT_LOGO: "/Project/UploadLogo",
    GET_PROJECT_DATA: "/Project/AdditionalData",
	GET_PROJECT_INTEGRATION: "/Project/Integration",
	SAVE_PROJECT_INTEGRATION: "/Project/SaveIntegration",
	GET_PROJECT_CHATUI: "/Project/ChatUI",
	SAVE_PROJECT_CHATUI: "/Project/SaveChatUI",
    GET_POSTBACK_SETTINGS: "/Project/PostbackSettings",
	SAVE_POSTBACK_SETTINGS: "/Project/SavePostbackSettings",
	GET_GAME_PROVIDERS: "/Project/GameProviders",

	GET_CUSTOM_HOSTNAMES: "/Domain/CustomHostnames",
	ADD_CUSTOM_HOSTNAME: "/Domain/AddCustomHostname",
	REMOVE_CUSTOM_HOSTNAME: "/Domain/RemoveCustomHostname",
	CHECK_CUSTOM_HOSTNAME_STATUS: "/Domain/CheckCustomHostnameStatus",
	ADD_DOMAIN_SITE: "/Domain/AddSite",
	GET_DOMAIN_SITES: "/Domain/Sites",
	REMOVE_DOMAIN_SITE: "/Domain/RemoveSite",
	ADD_DOMAIN_RECORD: "/Domain/AddRecord",
	REMOVE_DOMAIN_RECORD: "/Domain/RemoveRecord",

	GET_PROJECT_BRANDS: "/Project/Brands",
	ADD_PROJECT_BRAND: "/Project/AddBrand",
	SAVE_PROJECT_BRAND: "/Project/SaveBrand",
	DELETE_PROJECT_BRAND: "/Project/DeleteBrand",
	ADD_PROJECT_BRAND_URL: "/Project/AddBrandUrl",
	SAVE_PROJECT_BRAND_URL: "/Project/SaveBrandUrl",
	DELETE_PROJECT_BRAND_URL: "/Project/DeleteBrandUrl",

	ADD_PROJECT_CURRENCY: "/Project/AddCurrency",
	SAVE_PROJECT_CURRENCY: "/Project/SaveCurrency",
	DELETE_PROJECT_CURRENCY: "/Project/DeleteCurrency",
	GET_PROJECT_CURRENCIES: "/Project/Currencies",
	GET_PROJECT_AVAILABLE_CURRENCIES: "/Project/AvailableCurrencies",

	GET_PROJECT_AFFILIATE_REGISTRATION_CONFIGURATION: "/Project/AffiliateRegistrationConfiguration",
	SAVE_PROJECT_AFFILIATE_REGISTRATION_CONFIGURATION: "/Project/SaveAffiliateRegistrationConfiguration",

	GET_PROJECT_LANGUAGES: "/Project/LanguageSettings",
	GET_PROJECT_AVAILABLE_LANGUAGES: "/Project/AvailableLanguages",
	ADD_PROJECT_LANGUAGE: "/Project/AddLanguage",

	GET_PROJECT_PAYOUT_SETTINGS: "/Project/PayoutSettings",
	SAVE_PROJECT_PAYOUT_SETTINGS: "/Project/SavePayoutSettings",

    RECALCULATE_COMMISSIONS: "/Project/RecalculateCommissions",

	GET_PROJECT_FEE_SETTINGS: "/Project/AdministrativeFees",
	SAVE_PROJECT_FEE_SETTINGS: "/Project/SaveAdministrativeFees",

	GET_PROJECT_REFERRAL_SETTINGS: "/Project/ReferralSettings",
	SAVE_PROJECT_REFERRAL_SETTINGS: "/Project/SaveReferralSettings",

    GET_PROJECT_ACCESS_SETTINGS: "/Project/AccessSettings",
    SAVE_PROJECT_ACCESS_SETTINGS: "/Project/SaveAccessSettings",

    GET_PROJECT_ADDITIONAL_PARAMS: "/Project/AdditionalRedirectionParams",
    ADD_PROJECT_ADDITIONAL_PARAMS: "/Project/AddAdditionalRedirectionParams",
    EDIT_PROJECT_ADDITIONAL_PARAMS: "/Project/EditAdditionalRedirectionParams",
    DELETE_PROJECT_ADDITIONAL_PARAMS: "/Project/DeleteAdditionalRedirectionParams",

	GET_PROJECT_PAYMENT_METHODS: "/Payment/ProjectPaymentMethods",
	ADD_PROJECT_PAYMENT_METHOD: "/Payment/AddProjectPaymentMethod",
	DELETE_PROJECT_PAYMENT_METHOD: "/Payment/DeleteProjectPaymentMethod",
	ACTIVATE_PROJECT_PAYMENT_METHOD: "/Payment/ActivateProjectPaymentMethod",
	DEACTIVATE_PROJECT_PAYMENT_METHOD: "/Payment/DeactivateProjectPaymentMethod",
	GET_AVAILABLE_PROJECT_PAYMENT_METHOD_CURRENCIES: "/Payment/AvailableProjectPaymentMethodCurrencies",
	ADD_PROJECT_PAYMENT_METHOD_CURRENCY: "/Payment/AddProjectPaymentMethodCurrency",
	DELETE_PROJECT_PAYMENT_METHOD_CURRENCY: "/Payment/DeleteProjectPaymentMethodCurrency",
	CHANGE_PROJECT_PAYMENT_METHOD_DETAILS: "/Payment/ChangeProjectPaymentMethodDetails",

	GET_ADMINS: "/User/All",
	DELETE_USER: "/User/Delete",
	EXPORT_ADMINS: "/User/Export",
	CREATE_ADMIN: "/User/Create",
	GET_USER_GENERAL_INFO: "/User/GeneralInfo",
	SAVE_USER_GENERAL_INFO: "/User/SaveGeneralInfo",
	GET_USER_AVAILABLE_COMPANIES: "/User/AvailableCompanies",
	GET_USER_COMPANY_ACCESS: "/User/CompanyAccess",
	ADD_USER_COMPANY_ACCESS: "/User/AddCompanyAccess",
	DELETE_USER_COMPANY_ACCESS: "/User/DeleteCompanyAccess",

	GET_AFFILIATE_MANAGERS: "/AffiliateManager/All",
	EXPORT_AFFILIATE_MANAGERS: "/AffiliateManager/Export",
	CREATE_AFFILIATE_MANAGER: "/AffiliateManager/Create",
	FORCE_LOGOUT_AFFILIATE_MANAGERS: "/AffiliateManager/ForceLogout",
	GET_AFFILIATE_MANAGER_GENERAL_INFO: "/AffiliateManager/GeneralInfo",
	SAVE_AFFILIATE_MANAGER_GENERAL_INFO: "/AffiliateManager/SaveGeneralInfo",
	GET_AFFILIATE_MANAGER_AFFILIATES: "/AffiliateManager/Affiliates",
	ADD_AFFILIATE_MANAGER_AFFILIATE: "/AffiliateManager/AddAffiliate",
	REMOVE_AFFILIATE_MANAGER_AFFILIATE: "/AffiliateManager/RemoveAffiliate",
	GET_AFFILIATE_MANAGER_AVAILABLE_AFFILIATES: "/AffiliateManager/AvailableAffiliates",

	GET_CAMPAIGNS: "/Campaign/All",
	CREATE_CAMPAIGN: "/Campaign/Create",
	EXPORT_CAMPAIGNS: "/Campaign/Export",
	ACTIVATE_CAMPAIGN: "/Campaign/Activate",
	DEACTIVATE_CAMPAIGN: "/Campaign/Deactivate",
	GET_CAMPAIGN_GENERAL_INFO: "/Campaign/GeneralInfo",
	SAVE_CAMPAIGN_GENERAL_INFO: "/Campaign/SaveGeneralInfo",

	CREATE_CAMPAIGN_BANNER_CREATIVE: "/Campaign/CreateBannerCreative",
	CREATE_CAMPAIGN_HTML_CREATIVE: "/Campaign/CreateHTMLCreative",
	GET_CAMPAIGN_CREATIVES: "/Campaign/Creatives",
	CREATE_BANNER_CREATIVE: "/Campaign/CreateBannerCreative",
	EXPORT_CAMPAIGN_CREATIVES: "/Campaign/ExportCreatives",
	CREATE_HTML_CREATIVE: "/Campaign/CreateHTMLCreative",
	SAVE_BANNER_CREATIVE: "/Campaign/SaveBannerCreative",
	SAVE_HTML_CREATIVE: "/Campaign/SaveHTMLCreative",
	DELETE_CAMPAIGN_CREATIVE: "/Campaign/DeleteCreative",
	GET_CAMPAIGN_CREATIVE_HTML: "/Campaign/Html",

	GET_MEDIAS: "/Campaign/Medias",
	CREATE_MEDIA: "/Campaign/CreateMedia",
	GET_MEDIA_CODE: "/Campaign/MediaCode",
	ACTIVATE_MEDIA: "/Campaign/ActivateMedia",
	DEACTIVATE_MEDIA: "/Campaign/DeactivateMedia",
	SAVE_MEDIA: "/Campaign/SaveMedia",
	EXPORT_MEDIAS: "/Campaign/ExportMedias",

	GET_COMMUNICATION_TEMPLATES: "/Communication/AllTemplates",
	GET_COMMUNICATION_TEMPLATE: "/Communication/Template",
	SAVE_COMMUNICATION_TEMPLATE_TRANSLATION: "/Communication/SaveTemplateTranslation",
	RESET_COMMUNICATION_TEMPLATE_TRANSLATION: "/Communication/ResetTemplateTranslation",

	GET_TEMPLATE_VARIABLES: "/Communication/TemplateVariables",

	GET_NEWSLETTER_TEMPLATES: "/Newsletter/Templates",
	GET_NEWSLETTER_CONFIGURATION: "/Newsletter/Configuration",
	CREATE_NEWSLETTER_TEMPLATE: "/Newsletter/CreateTemplate",
	SAVE_NEWSLETTER_TEMPLATE_COLORS: "/Newsletter/SaveTemplateColors",
	SAVE_NEWSLETTER_TEMPLATE_FONT_FAMILY: "/Newsletter/SaveTemplateFontFamily",
	SAVE_NEWSLETTER_TEMPLATE_TEXT: "/Newsletter/SaveTemplateText",
	SAVE_NEWSLETTER_TEMPLATE_SECTION: "/Newsletter/SaveTemplateSection",
	UPLOAD_NEWSLETTER_TEMPLATE_FILE: "/Newsletter/UploadTemplateFile",
	REMOVE_NEWSLETTER_TEMPLATE_FILE: "/Newsletter/RemoveTemplateFile",
	SAVE_NEWSLETTER_TEMPLATE_CONTENT: "/Newsletter/SaveTemplateContent",
	DUPLICATE_NEWSLETTER_TEMPLATE: "/Newsletter/DuplicateTemplate",
	RENAME_NEWSLETTER_TEMPLATE: "/Newsletter/RenameTemplate",
	GET_NEWSLETTER_TEMPLATE_CONTENT: "/Newsletter/TemplateContent",
	REMOVE_NEWSLETTER_TEMPLATE: "/Newsletter/RemoveTemplate",
	GET_NEWSLETTER_AVAILABLE_AFFILIATES: "/Newsletter/Affiliates",
	GET_NEWSLETTER_AFFILIATES: "/Newsletter/NewsletterAffiliates",
	CREATE_NEWSLETTER: "/Newsletter/CreateNewsletter",
	GET_NEWSLETTERS: "/Newsletter/Newsletters",
	GET_NEWSLETTER_GENERAL_INFO: "/Newsletter/NewsletterGeneralInfo",
	SAVE_NEWSLETTER_GENERAL_INFO: "/Newsletter/SaveNewsletterGeneralInfo",
	REMOVE_NEWSLETTER: "/Newsletter/RemoveNewsletter",
	SEND_TEST_NEWSLETTER: "/Newsletter/TestNewsletter",
	GET_NEWSLETTER_TEMPLATE_VARIABLES: "/Newsletter/TemplateVariables",
	UNSUBSCRIBE_FROM_NEWSLETTER: "/Account/UnsubscribeFromNewsletter",

	GET_ACCESS_MANAGERS: "/AccessManager/All",
	EXPORT_ACCESS_MANAGERS: "/AccessManager/Export",
	CREATE_ACCESS_MANAGER: "/AccessManager/Create",
	GET_ACCESS_MANAGER_GENERAL_INFO: "/AccessManager/GeneralInfo",
	SAVE_ACCESS_MANAGER_GENERAL_INFO: "/AccessManager/SaveGeneralInfo",

	GET_SESSIONS: "/Session/All",
	EXPORT_SESSIONS: "/Session/Export",

	GET_NOTIFICATIONS: "/Notification/Notifications",
	MARK_AS_READ_NOTIFICATION: "/Notification/MarkAsRead",
	DELETE_NOTIFICATION: "/Notification/DeleteNotification",

	GET_TRANSLATION_GROUPS: "/Translation/AllGroups",
	SAVE_TRANSLATION: "/Translation/Save",
	SAVE_BO_TRANSLATION: "/Translation/SaveBOTranslations",
	CREATE_TRANSLATION: "/Translation/Create",
	RESET_TRANSLATION: "/Translation/Reset",
	GET_TRANSLATIONS: "/Translation/All",
	GET_BO_TRANSLATIONS: "/Translation/BOTranslations",
	PUBLISH_TRANSLATIONS: "/Translation/Publish",
	PUBLISH_BO_TRANSLATIONS: "/Translation/PublishBOTranslations",
	UNPUBLISH_TRANSLATIONS: "/Translation/Unpublish",

	GET_SYSTEM_PERMISSIONS: "/Permission/SystemPermissions",
	GET_SYSTEM_PERMISSION_RESOURCES: "/Permission/SystemResources",
	GET_PERMISSION_GROUPS: "/Permission/AllGroups",
	CREATE_PERMISSION_GROUP: "/Permission/CreateGroup",
	DELETE_PERMISSION_GROUP: "/Permission/DeleteGroup",
	GET_PERMISSION_GROUP_PERMISSIONS: "/Permission/GroupPermissions",
	SAVE_PERMISSION_GROUP_PERMISSIONS: "/Permission/SaveGroupPermissions",
	GET_PERMISSION_GROUP_USERS: "/Permission/GroupUsers",
	ADD_PERMISSION_GROUP_USERS: "/Permission/AddUsersPermissionGroup",
	DELETE_PERMISSION_GROUP_USER: "/Permission/DeleteUserPermissionGroup",
	GET_USER_PERMISSION_GROUPS: "/Permission/UserGroups",
	ADD_USER_PERMISSION_GROUP: "/Permission/AddUserPermissionGroup",
	GET_USER_AVAILABLE_PERMISSION_GROUPS: "/Permission/AvailableGroups",
	GET_USER_PERMISSIONS: "/Permission/UserPermissions",
	SAVE_USER_PERMISSIONS: "/Permission/SaveUserPermissions",

	GET_PERMISSION_REQUESTS: "/Permission/Requests",
	APPROVE_PERMISSION_REQUEST: "/Permission/Approve",
	REJECT_PERMISSION_REQUEST: "/Permission/Reject",
	GET_PERMISSION_REQUESTS_HISTORY: "/Permission/RequestHistory",

	GET_DASHBOARD_WALLETS: "/Dashboard/Wallets",
    GET_DASHBOARD_OPERATIONS_PIE_CHART: "/Dashboard/OperationsPieChart",
    GET_DASHBOARD_OPERATIONS_LINE_CHART: "/Dashboard/OperationsLineChart",
    GET_DASHBOARD_OPERATIONS_TOP_AFFILIATES: "/Dashboard/OperationsTopAffiliates",
    GET_DASHBOARD_FINANCIAL_LINE_CHART: "/Dashboard/FinancesLineChart",
    GET_DASHBOARD_FINANCIAL_TOP_AFFILIATES: "/Dashboard/FinancesTopAffiliates",
    GET_DASHBOARD_FINANCIAL_TOP_PLAYERS: "/Dashboard/FinancesTopPlayers",

	GET_PLATFORMS: "/Platform/Platforms",
	TEST_PLATFORM: "/Platform/Test",

	GET_USER_LOGS: "/UserLogs/All",
	EXPORT_USER_LOGS: "/UserLogs/Export",
	GET_USER_LOG_DETAILS: "/UserLogs/Details",
	GET_USER_LOG_RESOURCES: "/UserLogs/Resources",

	GET_ERRORS: "/SystemLogs/Exceptions",
	GET_INTEGRATION_LOGS: "/SystemLogs/IntegrationLogs",
	GET_GENERATIONS: "/Report/PlatformGenerationsReport",

	GET_JOBS: "/Jobs/All",
	UNLOCK_JOB: "/Jobs/Unlock",
	GET_JOB_SETTINGS: "/Jobs/GetSettings",
	SAVE_JOB_SETTINGS: "/Jobs/SaveSettings",
	GET_JOB_DETAILS: "/Jobs/CurrentlyExecutingJob",

	GET_MONITORING_ALL: "/SystemCounter/GetAll",
	GET_MONITORING: "/SystemCounter/Get",

	UPDATE_TRANSLATIONS: "/Developer/UpdateTranslations",
	FLUSH_CACHE: "/Developer/FlushCache",
	LANGUAGES: "/Developer/Languages",
	EXPORT_TRANSLATIONS: "/Developer/ExportTranslations",

	GET_DB_CONNECTIONS: "/Developer/DBConnections",
	KILL_DB_CONNECTIONS: "/Developer/KillConnection",

	SETUP_PROMO: "/Promo/Setup",
	GET_PROMO_CONFIGURATION: "/Promo/Configuration",
	GET_PROMO_INFO: "/Promo/Info",
	SAVE_PROMO_INFO: "/Promo/SaveInfo",
	SET_PROMO_STATUS: "/Promo/SetStatus",

	PUBLISH_PROMO: "/Promo/Publish",
	GET_PROMO_COLORS: "/Promo/Colors",
	SAVE_PROMO_COLORS: "/Promo/SaveColor",
	GET_PROMO_FONT_FAMILY: "/Promo/FontFamily",
	SAVE_PROMO_FONT_FAMILY: "/Promo/SaveFontFamily",
	UPLOAD_PROMO_IMAGE: "/Promo/UploadImage",
	DELETE_PROMO_IMAGE: "/Promo/DeleteImage",
	SAVE_PROMO_SECTION: "/Promo/SaveSection",
	GET_PROMO_LANGUAGES: "/Promo/Languages",
	SAVE_PROMO_LANGUAGE: "/Promo/SaveLanguage",
	SET_PROMO_DEFAULT_LANGUAGE: "/Promo/SetLanguageDefault",
	GET_PROMO_SECTION_ITEM: "/Promo/SectionItems",
	SAVE_PROMO_SECTION_ITEM: "/Promo/SaveSectionItems",
	GET_PROMO_BRANDS: "/Promo/Brands",
	ADD_PROMO_BRAND: "/Promo/AddBrand",
	SAVE_PROMO_BRAND: "/Promo/SaveBrand",
	DELETE_PROMO_BRAND: "/Promo/DeleteBrand",
	GET_PROMO_CONTACTS: "/Promo/Contacts",
	SAVE_PROMO_CONTACTS: "/Promo/SaveContacts",
	GET_PROMO_STORY_GROUPS: "/Promo/StoryGroups",
	ADD_PROMO_STORY_GROUP: "/Promo/AddStoryGroup",
	SAVE_PROMO_STORY_GROUP: "/Promo/SaveStoryGroup",
	DELETE_PROMO_STORY_GROUP: "/Promo/DeleteStoryGroup",
	DELETE_PROMO_STORY: "/Promo/DeleteStory",
	ADD_PROMO_STORY: "/Promo/AddStory",
	GET_PROMO_TRANSLATION: "/Promo/Translations",
	SAVE_PROMO_TRANSLATION: "/Promo/SaveTranslation",
	RESET_PROMO_TRANSLATION: "/Promo/ResetTranslation",
	GET_PROMO_TRANSLATION_GROUPS: "/Promo/TranslationSubGroups",

	GET_PROMO_REGISTRATION_FORM: "/Promo/RegistrationForm",
	GET_PROMO_REGISTRATION_AVAILABLE_PAYMENTS: "/Promo/AvailablePayments",
	GET_PROMO_REGISTRATION_AVAILABLE_CURRENCIES: "/Promo/AvailableCurrencies",
	GET_PROMO_CHANNELS: "/Promo/Channels",
	GET_PROMO_PASSWORD_SETTINGS: "/Promo/PasswordSettings",
	GET_PROMO_PAYMENT_DETAILS: "/Promo/PaymentDetails",
	PROMO_REGISTER: "/Promo/Register",

	AUTOSUGGESTION_GET_PROJECTS: "/Autosuggestion/Projects",
	AUTOSUGGESTION_GET_COMPANIES: "/Autosuggestion/Companies",
	AUTOSUGGESTION_GET_USERS: "/Autosuggestion/Users",
	AUTOSUGGESTION_GET_ACCESS_MANAGERS: "/Autosuggestion/AccessManagers",
	AUTOSUGGESTION_GET_PLAYERS: "/Autosuggestion/Players",
	AUTOSUGGESTION_GET_PERMISSION_GROUPS: "/Autosuggestion/PermissionGroups",
	AUTOSUGGESTION_GET_CONTROLLERS: "/Autosuggestion/Controllers",
	AUTOSUGGESTION_GET_COMMISSION_PLANS: "/Autosuggestion/Commissions",
	AUTOSUGGESTION_GET_PERMISSION_GROUP_AVAILABLE_USERS:
		"/Autosuggestion/PermissionGroupAvailableUsers",
	AUTOSUGGESTION_GET_PAYMENT_LABELS: "/Autosuggestion/PaymentLabels",
	AUTOSUGGESTION_GET_PAYMENT_METHODS: "/Autosuggestion/SystemPaymentMethods",
	AUTOSUGGESTION_GET_AFFILIATE_MANAGERS: "/Autosuggestion/AffiliateManagers",
	AUTOSUGGESTION_GET_AFFILIATES: "/Autosuggestion/Affiliates",
	AUTOSUGGESTION_GET_CAMPAIGNS: "/Autosuggestion/Campaigns",
	AUTOSUGGESTION_GET_BRANDS: "/Autosuggestion/Brands",
	AUTOSUGGESTION_GET_CREATIVES: "/Autosuggestion/Creatives",
	AUTOSUGGESTION_GET_MEDIAS: "/Autosuggestion/Medias",
	AUTOSUGGESTION_GET_TRAFFIC_SOURCES: "/Autosuggestion/TrafficSources",
	AUTOSUGGESTION_GET_NEWSLETTERS: "/Autosuggestion/NewsletterTemplates",
	AUTOSUGGESTION_GET_AFFILIATE_GROUPS: "/Autosuggestion/AffiliateGroups",
	AUTOSUGGESTION_GET_AFFILIATE_GROUP_MEMBERS: "/Autosuggestion/AffiliateGroupMembers",
    AUTOSUGGESTION_GET_POSTBACKS: "/Autosuggestion/Postbacks",

	GET_SYSTEM_TIMEZONES: "/System/TimeZones",
	GET_SYSTEM_COUNTRIES: "/System/Countries",
};

export default API_URL;
