import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
import Table from 'components/common/table';

import AddAdditionalParamComponent from "./param-add.component";

import { 
    getAdditionalParams,
    editAdditionalParam,
    deleteAdditionalParam 
} from 'store/actions/portal/projects/additionalParams.action';

import { tableColumnsCreator } from 'utils/tableColumnsCreator';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';
import { getTableColumns } from "./columns";

import usePermissions from 'core/hooks/usePermission';

/** Project Edit Page General Info Tab Additional Params Subtab Component */
const AdditionalParamsComponent = ({
    isSaving,
    isLoading,
    additionalParams,
    getAdditionalParams,
    editAdditionalParam,
    deleteAdditionalParam
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [showAddPopup, setShowAddPopup] = useState(false)

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_INTEGRATION, PERMISSION_ACTION.VIEW );

    const additionalParamsData = useMemo(() => {
        return Object.entries(additionalParams).map(entry => ({key: entry[0], value: entry[1]}))
    }, [additionalParams])

    const { mainTableColumns } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            additionalProps: {
                editAdditionalParam,
                t
            },
            t
        });
    }, [editAdditionalParam, t]);

    const headerPartsData = {

        button: {
            icon: "plus",
            type: "primary",
            text: t("backoffice.common.add"),
            enabled: hasModifyPermission && additionalParamsData.length < 5,
            onClick: () => setShowAddPopup(true),
        }
    }

    const tableRowActions = [];

    if (hasModifyPermission) {
        tableRowActions.push({
            title: t('backoffice.common.remove'),
            confirmationMessage: t("backoffice.projects.doYouWantToRemoveAdditionalParam"),
            confirmationTitle: t("backoffice.projects.removeAdditionalParam"),
            icon: "trash",
            showConfirmation: true,
            onClick: record => deleteAdditionalParam(record.key, record.value)
        })
    }


    return (
        <SubTabTableDashboardLayout
            header={headerPartsData}
        >
            <Table
                loading={isLoading || isSaving}
                columns={mainTableColumns}
                data={additionalParamsData}
                loadFn={() => getAdditionalParams()}
                noPagination={true}
                disableFullView={true}
                actions={tableRowActions}
                uniqueKey="key"
            />

            {
                showAddPopup && (
                    <AddAdditionalParamComponent 
                        onClose={() => setShowAddPopup(false)}
                    />
                )
            }
        </SubTabTableDashboardLayout>
    )
}

/** AdditionalParamsComponent propTypes
    * PropTypes
*/
AdditionalParamsComponent.propTypes = {
    /** Redux state property, is true when general info is saving */
    isSaving: PropTypes.bool,
    /** Redux state property, is true when general info is loading */
    isLoading: PropTypes.bool,
    /** Redux state property addiotnal Params */
    additionalParams: PropTypes.object,
    /** Redux action to get Project Additional Params */
    getAdditionalParams: PropTypes.func,
    /** Redux action to edit Project Additional Params */
    editAdditionalParam: PropTypes.func,
    /** Redux action to delete Project Additional Params */
    deleteAdditionalParam: PropTypes.func,
}

const mapDispatchToProps = dispatch => (
    {
        getAdditionalParams: () => {
            dispatch(getAdditionalParams())
        },

        editAdditionalParam: (key, value) => {
            dispatch(editAdditionalParam(key, value))
        },

        deleteAdditionalParam: (key, value) => {
            dispatch(deleteAdditionalParam(key, value))
        },
    }
)

const mapStateToProps = state => {
    return {
        additionalParams: state.projects.edit.additionalParams,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalParamsComponent)