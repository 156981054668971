import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

/** Check if has access to project edit page
     * @function
     * @param {number} type - project type
     * @returns {boolean}
 */
export const hasProjectEditPageAccess = permissionUtils => {
    return permissionUtils.some(
        { resource: PERMISSION_RESOURCE.PROJECT_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_BRANDS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_PROMO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_CURRENCIES, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_LANGUAGE_SETTINGS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_COMMISSION_PLAN, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_REGISTRATION_CONFIGURATION, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_PAYOUT_SETTINGS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.PROJECT_REFERRAL_SETTINGS, action: PERMISSION_ACTION.VIEW }
    )
}

/** Check if has access to admin edit page
     * @function
     * @returns {boolean}
 */
export const hasAdminEditPageAccess = permissionUtils => {
    return permissionUtils.some(
        { resource: PERMISSION_RESOURCE.ADMIN_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_COMPANY_ACCESS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_PROJECT_ACCESS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_PERMISSIONS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_SESSIONS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ADMIN_ADDITIONAL_ACCESS, action: PERMISSION_ACTION.VIEW }
    )
}

/** Check if has access to access manager edit page
     * @function
     * @returns {boolean}
 */
export const hasAccessManagerEditPageAccess = permissionUtils => {
    return permissionUtils.some(
        { resource: PERMISSION_RESOURCE.ACCESS_MANAGER_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.ACCESS_MANAGER_SESSIONS, action: PERMISSION_ACTION.VIEW }
    ) 
}

/** Check if has access to access manager edit page
     * @function
     * @returns {boolean}
 */
export const hasAffiliateManagerEditPageAccess = permissionUtils => {
    return permissionUtils.some(
        { resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER_AFFILIATES, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_MANAGER_SESSIONS, action: PERMISSION_ACTION.VIEW }
    ) 
}

/** Check if has access to project edit page
     * @function
     * @param {number} type - project type
     * @returns {boolean}
 */
export const hasCampaignEditPageAccess = permissionUtils => {
    return permissionUtils.some(
        { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CAMPAIGN, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.MARKETING_TOOLS_CREATIVE, action: PERMISSION_ACTION.VIEW }
    )
}

/** Check if has access to affiliate edit page
     * @function
     * @returns {boolean}
 */
export const hasAffilaiteEditPageAccess = permissionUtils => {
    return permissionUtils.some(
        { resource: PERMISSION_RESOURCE.AFFILIATE, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_GENERALINFO, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_COMMISSION_PLAN, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_PAYMENT_METHODS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_SESSIONS, action: PERMISSION_ACTION.VIEW },
        { resource: PERMISSION_RESOURCE.AFFILIATE_WALLETS, action: PERMISSION_ACTION.VIEW }
    )
}