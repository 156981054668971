import React from "react";

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from "components/common/tabs";

import AffiliateReportComponent from "./affiliateReport/affiliateReport.component";
import PlayerReportComponent from "./playerReport/playerReport.component";

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from "constants/permissions.constants";

/** Performance Reports Page Component */
const PerformanceReportsComponent = () => {

    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.reports.perAffiliate"),
            permissions: [{ resource: PERMISSION_RESOURCE.REPORT_AFFILIATE_PERFORMANCE, action: PERMISSION_ACTION.VIEW }],
            component: <AffiliateReportComponent />
        },

        {
            title: t("backoffice.reports.perPlayer"),
            permissions: [{ resource: PERMISSION_RESOURCE.REPORT_PLAYER_PERFORMANCE, action: PERMISSION_ACTION.VIEW }],
            component: <PlayerReportComponent />
        }
    ]

    return (
        <Tabs 
            items={items}
            mainPage={[
                { title: t('backoffice.menu.reports') },
                { title: t('backoffice.menu.performance') }
            ]}
        />
    )
};

export default PerformanceReportsComponent;
