import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col, Spin, Switch } from "antd";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Select from "components/common/select";

import { getCompanyAuthenticationSettings, saveCompanyAuthenticationSettings } from "store/actions/portal/companies/authenticationSettings.action";

import companyAuthenticationSettingsType from "types/company/authenticationSettings.type";

import { isFormChanged } from "utils/form";

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';

import { isMobile } from 'utils/common';
import usePermissions from 'core/hooks/usePermission';

const TOTAL_ATTEMPTS_POSSIBLE_VALUES = Array.from(Array(8).keys()).map(n => n + 3);
const AUTO_UNLOCK_POSSIBLE_VALUES = Array.from(Array(51).keys()).map(n => n + 10);

/** Company Edit Page Authentication Settings SubTab Component */
const AuthenticationSettingsComponent = ({
    isLoading,
    isSaving,
    getCompanyAuthenticationSettings,
    saveCompanyAuthenticationSettings,
    authenticationSettings,
    onTabChange
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.COMPANY_SECURITY_SETTINGS, PERMISSION_ACTION.MODIFY );

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const [isFormTouched, setIsFormTouched] = useState(false);
    const [is2FAEnabled, setIs2FAEnabled] = useState(null);

    /** Load company Authentication settings */
    useEffect(() => {
        getCompanyAuthenticationSettings()
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...authenticationSettings
        });

        setIs2FAEnabled(authenticationSettings.googleTwoFactorAuthentication)
    }, [authenticationSettings])

    /** Fires when form submitted
       * @function
       * @memberOf AuthenticationSettingsComponent
    */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                saveCompanyAuthenticationSettings({
                    ...data
                })

                setIsFormTouched(false);
            }).catch(() => { })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    initialValues={{
                        failedAttemptCountLogin: authenticationSettings.failedAttemptCountLogin,
                        autoUnlockTimeLogin: authenticationSettings.autoUnlockTimeLogin,
                        failedAttemptCount2FA: authenticationSettings.failedAttemptCount2FA,
                        autoUnlockTime2FA: authenticationSettings.autoUnlockTime2FA,
                        autoUnlockTotalCount: authenticationSettings.autoUnlockTotalCount,
                        googleTwoFactorAuthentication: authenticationSettings.googleTwoFactorAuthentication
                    }}
                    onValuesChange={(changed, formValues) => setIsFormTouched(isFormChanged({ ...formValues }, { ...authenticationSettings }))}
                >
                    <Row gutter={isMobile() ? [0, 0] : [40, 0]}>
                        <Col xs={24} sm={12} lg={8} xl={5} >
                            <Form.Item
                                label={t("backoffice.companies.autoUnlockTotalCount")}
                                name="autoUnlockTotalCount"
                                className={!hasModifyPermission ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={TOTAL_ATTEMPTS_POSSIBLE_VALUES.map(el => ({
                                        value: el, text: el
                                    }))}
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.companies.autoUnlockTotalCount")}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    disabled={!hasModifyPermission} 
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row gutter={isMobile() ? [0, 0] : [40, 0]}>
                        <Col span={24}>
                            <h4
                                className='rt--form-section-title rt--title rt--mb-16 rt--mt-16 rt--font-bold rt--font-biger'>
                                {
                                    t("backoffice.companies.login")
                                }
                            </h4>
                        </Col>
                        <Col xs={24} sm={12} lg={8} xl={5}>
                            <Form.Item
                                label={t("backoffice.companies.failedLoginAttemptsCount")}
                                name="failedAttemptCountLogin"
                                className={!hasModifyPermission ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={TOTAL_ATTEMPTS_POSSIBLE_VALUES.map(el => ({
                                        value: el, text: el
                                    }))}
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.companies.failedLoginAttemptsCount")}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={8} xl={5}>
                            <Form.Item
                                label={t("backoffice.companies.autoUnlockTime")}
                                name="autoUnlockTimeLogin"
                                className={!hasModifyPermission ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={AUTO_UNLOCK_POSSIBLE_VALUES.map(el => ({
                                        value: el, text: el + " " + t("backoffice.companies.min")
                                    }))}
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.companies.autoUnlockTime")}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    disabled={!hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between">
                                <Form.Item
                                    name="googleTwoFactorAuthentication"
                                    valuePropName="checked"
                                    className='rt--form-item-without-margin'
                                >
                                    <Switch
                                        disabled={!hasModifyPermission}
                                        onChange={(isChecked => setIs2FAEnabled(isChecked))}
                                    />
                                </Form.Item>
                                <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.companies.googleAuth')}</label>
                            </div>
                        </Col>
                    </Row>

                    <Row gutter={isMobile() ? [0, 0] : [40, 0]}>
                        <Col span={24}>
                            <h4
                                className='rt--form-section-title rt--title rt--mb-16 rt--mt-16 rt--font-bold rt--font-biger'>
                                {
                                    t("backoffice.companies.2fa")
                                }
                            </h4>
                        </Col>
                        <Col xs={24} sm={12} lg={8} xl={5}>
                            <Form.Item
                                label={t("backoffice.companies.failedFAAttemptsCount")}
                                name="failedAttemptCount2FA"
                                className={!is2FAEnabled ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={TOTAL_ATTEMPTS_POSSIBLE_VALUES.map(el => ({
                                        value: el, text: el
                                    }))}
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.companies.failedFAAttemptsCount")}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    disabled={!is2FAEnabled || !hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                        <Col xs={24} sm={12} lg={8} xl={5}>
                            <Form.Item
                                label={t("backoffice.companies.autoUnlockTime")}
                                name="autoUnlockTime2FA"
                                className={!is2FAEnabled ? "rt--form-item-disabled" : ""}
                            >
                                <Select
                                    options={AUTO_UNLOCK_POSSIBLE_VALUES.map(el => ({
                                        value: el, text: el + " " + t("backoffice.companies.min")
                                    }))}
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.companies.autoUnlockTime")}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    disabled={!is2FAEnabled || !hasModifyPermission}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** AuthenticationSettingsComponent propTypes
 * PropTypes
*/
AuthenticationSettingsComponent.propTypes = {
    /** Redux state property, is true when loading company authentication settings data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving company settings data */
    isSaving: PropTypes.bool,
    /** Redux action to get company authentication settings */
    getCompanyAuthenticationSettings: PropTypes.func,
    /** Redux action to save company authentication settings */
    saveCompanyAuthenticationSettings: PropTypes.func,
    /** Redux state property, represents company authentication settings  */
    authenticationSettings: companyAuthenticationSettingsType,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => (
    {
        getCompanyAuthenticationSettings: () => {
            dispatch(getCompanyAuthenticationSettings());
        },
        saveCompanyAuthenticationSettings: rules => {
            dispatch(saveCompanyAuthenticationSettings(rules));
        },
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.companies.isLoading,
        isSaving: state.companies.isSaving,
        authenticationSettings: state.companies.edit.authenticationSettings
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticationSettingsComponent)