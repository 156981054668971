import React from "react";

import DateRangePicker from "components/common/dateRangePicker";

import useDate from "hooks/useDate";

import searchType from "types/components/search.type";
import { isMobile } from "utils/common";

/** DatePicker Search Component */
const DatePickerSearch = ({
    loadFn,
    placeholder,
    showTime,
    onSearch,
    enabledMountsCount,
    allowClear=true,
    disabledRanges,
    value: controledValue,
}) => {
    
    const { dateService } = useDate();

    const datePickerValue = [
        controledValue?.[0] ? dateService.getDate(controledValue[0]) : "", 
        controledValue?.[1] ? dateService.getDate(controledValue[1]) : "", 
    ]

    const handleChange = value => {
        let fromValue = null;
        let toValue = null;
        if(value){
            if(value[0]){
                fromValue = dateService.toISOString(value[0])
            }
    
            if(value[1]){
                toValue = dateService.toISOString(value[1])
            }
        }
        onSearch?.([fromValue, toValue]);
        setTimeout(() => {
            loadFn?.(true);
        }, 20);  
    }

  return (
    <div className={ "rt--table-datepicker-search" + (!isMobile() ? " rt--ml-16" : "")}>
        <DateRangePicker
            showTime={showTime}
            enabledMountsCount={enabledMountsCount}
            onChange={handleChange}
            disabledRanges={disabledRanges}
            allowClear={allowClear}
            value={datePickerValue}
        />
    </div>
  );
};

/** DatePickerSearch propTypes
 * PropTypes
 */
DatePickerSearch.propTypes = searchType;

export default DatePickerSearch;
