//#region react
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";
import { useLocation, useNavigate } from "react-router-dom";
//#endregion

//#region actions
import {
    getProjectBrands,
    deleteProjectBrandUrl,
} from "store/actions/portal/projects/brands.action";
//#endregion

//#region components
import SubTabTableDashboardLayout from "components/layouts/tab/subtab/table";
import Table from 'components/common/table';
import Icon from "components/common/icon";
import AddEditBrandUrl from "./brandUrl-add-edit.component";
//#endregion

//#region hooks
import useIncludedColumns from 'hooks/useIncludedColumns';
import useUnsavedChangesConfirmation from 'hooks/useUnsavedChangesConfirmation';
//#endregion

//#region utils
import { tableColumnsCreator } from 'utils/tableColumnsCreator';
import { deleteQueryStringParamsToUrl } from 'utils/queryString';
//#endregion

//#region constants
import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { UNSAVED_FORM_PAGE_TYPE } from 'constants/common.constants';
import { BRAND_URLS } from 'constants/pageName.constants';
import { getTableColumns } from './columns';
//#endregion

//#region types
import brandType from "types/project/brand.type";
import usePermissions from 'core/hooks/usePermission';
//#endregion

const ROW_UNIQUE_KEY_PROP = "id";

const BrandUrlsComponent = ({
    getProjectBrands,
    brands,
    deleteProjectBrandUrl,
    isLoading,
    isSaving
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const { search, hash, pathname } = useLocation();
    const navigate = useNavigate();

    const [addEditPopupData, setAddEditPopupData] = useState(null);

    const navigateWithConfirmation = useUnsavedChangesConfirmation({
        cb: navigate,
        subscribe: [UNSAVED_FORM_PAGE_TYPE.SUB_TAB]
    })

    const [includedColumns, keepAppliedColumns] = useIncludedColumns({ pageName: BRAND_URLS });

    const currentBrandId = (new URLSearchParams(search)).get("brandId");

    const currentBrand = brands.find(brand => brand.id === currentBrandId);
    const urls = currentBrand?.urls ?? [];
    const mainDomain = currentBrand?.domain;

    //#region ------------------------------------- PERMISSIONS ---------------------------------------//
    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_BRAND_URLS, PERMISSION_ACTION.MODIFY );
    const hasDeletePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_BRAND_URLS, PERMISSION_ACTION.DELETE );
    const hasCreatePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_BRAND_URLS, PERMISSION_ACTION.CREATE );

    //#endregion

    //#region ---------------------------------- TABLE COLUMNS DATA -----------------------------------//

    const { mainTableColumns, columnsThatCanBeIncluded } = useMemo(() => {
        return tableColumnsCreator({
            mainColumns: getTableColumns,
            constructForInclude: true,
            includedColumns,
            additionalProps: {
                mainDomain,
                t
            },
            t
        });
    }, [mainDomain, includedColumns, t])

    //#endregion

    //#region ----------------------------------- TABLE ROW ACTIONS -----------------------------------//

    const tableRowActions = [];

    if (hasDeletePermission) {
        tableRowActions.push({
            title: t('backoffice.common.delete'),
            confirmationMessage: t("backoffice.projects.doYouWanttoDeleteBrandUrl"),
            confirmationTitle: t("backoffice.projects.deleteBrandUrl"),
            icon: "trash",
            showConfirmation: true,
            onClick: record => deleteProjectBrandUrl(currentBrandId, record.id),
        })
    }

    if (hasModifyPermission) {
        tableRowActions.push({
            title: t("backoffice.common.deepView"),
            icon: "edit",
            onClick: record => setAddEditPopupData({ url: record, mainDomain })
        })
    }

    //#endregion

    //#region --------------------------------- DASHBOARD HEADER DATA ---------------------------------//

    const headerPartsData = {
        columns: {
            columns: columnsThatCanBeIncluded,
            onApply: keepAppliedColumns,
            defaultSelectedColumns: includedColumns
        },
        button: {
            icon: "plus",
            type: "primary",
            onClick: () => setAddEditPopupData({ url: null, mainDomain }),
            text: t("backoffice.projects.addUrl"),
            enabled: hasCreatePermission
        }
    }

    //#endregion

    /** Load brands */
    useEffect(() => {
        if (brands.length === 0) {
            getProjectBrands();
        }
    }, [brands.length])

    return (
        <SubTabTableDashboardLayout
            header={headerPartsData}
            buttons={[
                {
                    type: "secondary",
                    onClick: () => {
                        const url = deleteQueryStringParamsToUrl(pathname, search, hash, ["brandId", "brandName"] );
                        navigateWithConfirmation(url, { replace: true } )
                    },
                    text: (
                        <span className='rt--back-button'>
                            <Icon name="left" size={16} />
                            <span>
                                {t("backoffice.common.back")}
                            </span>
                        </span>
                    ),
                    enabled: true,
                    className: "rt--button-secondary",
                    placement: "left",
                },
            ]}
        >
            <Table
                loading={isLoading || isSaving}
                columns={mainTableColumns}
                data={urls}
                uniqueKey={ROW_UNIQUE_KEY_PROP}
                noPagination={true}
                disableFullView={true}
                actions={tableRowActions}
            />

            {
                addEditPopupData && (
                    <AddEditBrandUrl
                        url={addEditPopupData.url}
                        mainDomain={addEditPopupData.mainDomain}
                        onClose={() => setAddEditPopupData(null)}
                    />
                )
            }
        </SubTabTableDashboardLayout>
    );
}

/** BrandUrlsComponent propTypes
    * PropTypes
*/
BrandUrlsComponent.propTypes = {
    /** Redux action to get project brands */
    getProjectBrands: PropTypes.func,
    /** Redux action to delete project brand url */
    deleteProjectBrandUrl: PropTypes.func,
    /** Redux state property, is true when project brands are loading */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when project brand is saving */
    isSaving: PropTypes.bool,
    /** Redux state, represents the currencies of current editing project  */
    brands: PropTypes.arrayOf(brandType)
}

const mapDispatchToProps = dispatch => (
    {
        getProjectBrands: () => {
            dispatch(getProjectBrands());
        },

        deleteProjectBrandUrl: (brandId, id) => {
            dispatch(deleteProjectBrandUrl(brandId, id));
        }
    }
)

const mapStateToProps = state => {
    return {
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
        brands: state.projects.edit.brands
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BrandUrlsComponent);