import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col, Spin, Switch, Radio, DatePicker, Button, Divider } from "antd";

import SubTabFormDashboardLayout from "components/layouts/tab/subtab/form";
import Tooltip from 'components/common/tooltip';
import Select from "components/common/select";
import Icon from "components/common/icon";

import Recalculate from './recalculate-popup.component';

import { getProjectPayoutSettings, saveProjectPayoutSettings } from "store/actions/portal/projects/payoutSettings.action";

import useDate from 'hooks/useDate';

import { constructArrayForGivenRanges, isMobile } from "utils/common";
import { isFormChanged } from 'utils/form';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { COMMISSION_PLAN_CALCULATION_PERIOD, INVOICE_GENERATION_METHOD, INVOICE_GENERATION_PERIOD, INVOICE_GENERATION_TYPE } from 'constants/commission.constants';

import projectPayoutSettingsType from "types/project/payoutSettings.type";

import usePermissions from 'core/hooks/usePermission';

import isProd from 'core/helpers/common/isProd';

/** Project Edit Page Financial Tab Payout Settings SubTab Component */
const PayoutSettingsComponent = ({
    isLoading,
    isSaving,
    getProjectPayoutSettings,
    saveProjectPayoutSettings,
    payoutSettings,
    onTabChange
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;

    const { dateService } = useDate();

    const [selectedInvoiceGenerationMethod, setSelectedInvoiceGenerationMethod] = useState(INVOICE_GENERATION_METHOD.MANUAL)

    const [isFormTouched, setIsFormTouched] = useState(false);

    const [recalculatePopup, setRecalculatePopup] = useState(false)

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_PAYOUT_SETTINGS, PERMISSION_ACTION.MODIFY );
    const hasRecalculatePermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_COMMISSION_RECALCULATION, PERMISSION_ACTION.MODIFY );

    const defaultValues = {
        isNegativeCarryOverEnabled: payoutSettings.isNegativeCarryOverEnabled,
        allowNegativeCarryOverImpactCPA: payoutSettings.allowNegativeCarryOverImpactCPA,
        approveCommissionManually: Boolean(payoutSettings.approveCommissionManually),
        invoiceGenerationType: payoutSettings.invoiceGenerationType ?? INVOICE_GENERATION_TYPE.BY_AFFILIATE,
        commissionCalculationPeriod: payoutSettings.commissionCalculationPeriod,
        nextCalculationDate: payoutSettings.nextCalculationDate ? dateService.makeUtcDateToLocal(payoutSettings.nextCalculationDate) : "",
        invoiceGenerationMethod: payoutSettings.invoiceGenerationMethod ?? INVOICE_GENERATION_METHOD.MANUAL,
        invoiceGenerationPeriod: payoutSettings.invoiceGenerationPeriod,
        nextInvoiceGenerationDate: payoutSettings.nextInvoiceGenerationDate ? dateService.makeUtcDateToLocal(payoutSettings.nextInvoiceGenerationDate) : "",
        resetNegativeBalance: payoutSettings.resetNegativeBalance
    }

    /** Get Payout Settings */
    useEffect(() => {
        getProjectPayoutSettings()
    }, [])

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue(defaultValues);
        setSelectedInvoiceGenerationMethod(defaultValues.invoiceGenerationMethod);
    }, [payoutSettings])

    /** Fires when form submitted
       * @function
       * @memberOf PayoutSettingsComponent
    */
    const handleForm = () => {
        validateFields()
            .then((data) => {
                saveProjectPayoutSettings({
                    isNegativeCarryOverEnabled: data.isNegativeCarryOverEnabled,
                    allowNegativeCarryOverImpactCPA: data.allowNegativeCarryOverImpactCPA,
                    approveCommissionManually: data.approveCommissionManually,
                    invoiceGenerationType: data.invoiceGenerationType,
                    nextCalculationDate: dateService.toISOString(data.nextCalculationDate),
                    commissionCalculationPeriod: data.commissionCalculationPeriod,
                    invoiceGenerationMethod: data.invoiceGenerationMethod,
                    invoiceGenerationPeriod: data.invoiceGenerationPeriod,
                    nextInvoiceGenerationDate: data.nextInvoiceGenerationDate ? dateService.toISOString(data.nextInvoiceGenerationDate): data.nextInvoiceGenerationDate,
                    resetNegativeBalance: data.resetNegativeBalance
                })

                setIsFormTouched(false);
            }).catch(() => { })
    }

    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched])

    return (
        <SubTabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={
                        (changed, formValues) => {
                            setTimeout(() => {
                                setIsFormTouched(
                                    isFormChanged(
                                        {
                                            isNegativeCarryOverEnabled: formValues.isNegativeCarryOverEnabled,
                                            allowNegativeCarryOverImpactCPA: formValues.allowNegativeCarryOverImpactCPA,
                                            approveCommissionManually: formValues.approveCommissionManually,
                                            invoiceGenerationType: formValues.invoiceGenerationType,
                                            commissionCalculationPeriod: formValues.commissionCalculationPeriod,
                                            nextCalculationDate: formValues.nextCalculationDate ? dateService.makeUtcDateToLocal(formValues.nextCalculationDate) : "",
                                            invoiceGenerationMethod: formValues.invoiceGenerationMethod,
                                            invoiceGenerationPeriod: formValues.invoiceGenerationPeriod,
                                            nextInvoiceGenerationDate: formValues.nextInvoiceGenerationDate ? dateService.makeUtcDateToLocal(formValues.nextInvoiceGenerationDate): "",    
                                            resetNegativeBalance: formValues.invoiceGenerationMethod === INVOICE_GENERATION_METHOD.MANUAL ? defaultValues.resetNegativeBalance : formValues.resetNegativeBalance
                                        },
                                        { ...defaultValues }
                                    )
                                )
                            }, 0)
                        }
                    }
                >
                    <Row gutter={!isMobile() ? [40, 0] : [0, 0]}>
                        <Col xs={24} sm={12} xl={8}>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <h4
                                        className={'rt--form-section-title rt--title rt--mb-16 rt--font-bold rt--font-biger' + (isMobile() ? " rt--mt-8": "")}>
                                        {
                                            t("backoffice.commissionplans.calculation")
                                        }
                                    </h4>
                                </Col>
                                <Col span={24}>
                                    <div className='rt--widget rt--pt-20 rt--pb-20 rt--pl-16 rt--pr-16 rt--mb-16'>
                                        <Form.Item
                                            label={t("backoffice.commissionplans.period")}
                                            name="commissionCalculationPeriod"
                                            className={!hasModifyPermission ? "rt--form-item-disabled" : ""}
                                        >
                                            <Select
                                                options={[
                                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.HOUR, text: t('backoffice.commissionplans.hour') },
                                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.DAY, text: t('backoffice.commissionplans.day') },
                                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.WEEK, text: t('backoffice.commissionplans.week') },
                                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.TWO_WEEK, text: t('backoffice.commissionplans.2week') },
                                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.THREE_WEEK, text: t('backoffice.commissionplans.3week') },
                                                    { value: COMMISSION_PLAN_CALCULATION_PERIOD.MONTH, text: t('backoffice.commissionplans.month') }
                                                ]}
                                                placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.period")}`}
                                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                                disabled={!hasModifyPermission}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("backoffice.commissionplans.nextCalculationTime")}
                                            name="nextCalculationDate"
                                            className={(!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                        >
                                            <DatePicker
                                                className="rt--datepicker"
                                                dropdownClassName='rt--datepicker-popup rt--datepicker-withtime-popup rt--datepicker-only-hours'
                                                format={`${dateService.getFormat(false)} HH:00`}
                                                showTime={ {
                                                    hideDisabledOptions: true,
                                                    disabledMinutes: () => constructArrayForGivenRanges({ start: 0, end: 60 }),
                                                    format: "HH"
                                                } }
                                                disabledDate={d => dateService.isBefore(d, dateService.getNow())}
                                                showToday={false}
                                                showNow={false}
                                                allowClear={false}
                                                placeholder={t('backoffice.common.selectDate')}
                                                suffixIcon={<Icon name="date" />}
                                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                                inputReadOnly={true}
                                                disabled={!hasModifyPermission}
                                            />
                                        </Form.Item>
                                        
                                        {
                                            ( hasRecalculatePermission && !isProd() ) && (
                                                <Fragment>
                                                    <Divider />
                                                    <Button
                                                        className='rt--button rt--button-main'
                                                        onClick={() => setRecalculatePopup(true)}
                                                    >
                                                        {
                                                            t("backoffice.commissionplans.recalculate")
                                                        }
                                                    </Button>
                                                </Fragment>
                                                
                                            )
                                        }
                                        
                                    </div>
                                </Col>
                                
                                <Col span={24}>
                                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                        <Form.Item
                                            name="isNegativeCarryOverEnabled"
                                            valuePropName="checked"
                                            className='rt--form-item-without-margin'
                                        >
                                            <Switch 
                                                disabled={!hasModifyPermission}
                                            />

                                        </Form.Item>
                                        <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.commissionplans.negativeCarryOver')}</label>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                        <Form.Item
                                            name="allowNegativeCarryOverImpactCPA"
                                            valuePropName="checked"
                                            className='rt--form-item-without-margin'
                                        >
                                            <Switch 
                                                disabled={!hasModifyPermission}
                                            />

                                        </Form.Item>
                                        <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.commissionplans.negativeRevenueCommissionWillImpactCPACommission')}</label>
                                    </div>
                                </Col>
                                <Col span={24}>
                                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                        <Form.Item
                                            name="approveCommissionManually"
                                            valuePropName="checked"
                                            className='rt--form-item-without-margin'
                                        >
                                            <Switch 
                                                disabled={!hasModifyPermission}
                                            />

                                        </Form.Item>
                                        <label className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label'>{t('backoffice.commissionplans.approveCommissionManually')}</label>
                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col xs={24} sm={12} xl={8}>
                            <Row gutter={[16, 0]}>
                                <Col span={24}>
                                    <h4
                                        className={'rt--form-section-title rt--title rt--mb-16 rt--font-bold rt--font-biger' + (isMobile() ? " rt--mt-8": "")}>
                                        {
                                            t("backoffice.commissionplans.affiliateWithdrawals")
                                        }
                                    </h4>
                                </Col>
                                <Col span={24}>
                                    <Form.Item
                                        label={t('backoffice.commissionplans.invoiceGeneration')}
                                        name="invoiceGenerationType"
                                        className={'rt--form-item-inline rt--form-item-radio-buttons' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                    >
                                        <Radio.Group
                                            disabled={!hasModifyPermission}
                                            options={[
                                                {
                                                    label: (
                                                        <div className='rt--flex rt--align-center'>
                                                            <span>{t('backoffice.commissionplans.byCompany')}</span>
                                                            <Tooltip
                                                                title={t('backoffice.commissionplans.byCompanyTooltip')}
                                                                trigger={["hover", "click"]}
                                                                placement="top"
                                                                enableMobile={true}
                                                            >
                                                                <Icon name="info" size={18} className="rt--ml-4" />
                                                            </Tooltip>
                                                        </div>
                                                    ),
                                                    value: INVOICE_GENERATION_TYPE.BY_COMPANY
                                                },
                                                {
                                                    label: (
                                                        <div className='rt--flex rt--align-center'>
                                                            <span>{t('backoffice.commissionplans.byAffiliate')}</span>
                                                            <Tooltip
                                                                title={t('backoffice.commissionplans.byAffiliateTooltip')}
                                                                trigger={["hover", "click"]}
                                                                placement="top"
                                                                enableMobile={true}
                                                            >
                                                                <Icon name="info" size={18} className="rt--ml-4" />
                                                            </Tooltip>
                                                        </div>
                                                    ),
                                                    value: INVOICE_GENERATION_TYPE.BY_AFFILIATE
                                                }
                                            ]}
                                        />
                                    </Form.Item>
                                </Col>
 
                                <Col span={24}>
                                    <div className='rt--widget rt--pt-20 rt--pb-20 rt--pl-16 rt--pr-16 rt--mb-16'>
                                        <Form.Item
                                            label=""
                                            name="invoiceGenerationMethod"
                                            className={'rt--form-item-inline rt--form-item-radio-buttons' + (!hasModifyPermission ? " rt--form-item-disabled" : "")}
                                        >
                                            <Radio.Group
                                                disabled={!hasModifyPermission}
                                                options={[
                                                    {
                                                        label: (
                                                            <div className='rt--flex rt--align-center'>
                                                                <span>{t('backoffice.commissionplans.manual')}</span>
                                                            </div>
                                                        ),
                                                        value: INVOICE_GENERATION_METHOD.MANUAL
                                                    },
                                                    {
                                                        label: (
                                                            <div className='rt--flex rt--align-center'>
                                                                <span>{t('backoffice.commissionplans.auto')}</span>
                                                            </div>
                                                        ),
                                                        value: INVOICE_GENERATION_METHOD.AUTO
                                                    }
                                                ]}
                                                onChange={e => {
                                                    setSelectedInvoiceGenerationMethod(e.target.value);
                                                    if(e.target.value === INVOICE_GENERATION_METHOD.MANUAL){
                                                        setFieldsValue({
                                                            resetNegativeBalance : payoutSettings.resetNegativeBalance
                                                        })
                                                    }
                                                }}
                                            />
                                        </Form.Item>
                                    
                                        <Form.Item
                                            label={t("backoffice.commissionplans.generationPeriod")}
                                            name="invoiceGenerationPeriod"
                                            className={!hasModifyPermission || selectedInvoiceGenerationMethod === INVOICE_GENERATION_METHOD.MANUAL ? "rt--form-item-disabled" : ""}
                                        >
                                            <Select
                                                options={[
                                                    { value: INVOICE_GENERATION_PERIOD.WEEK, text: t('backoffice.commissionplans.week') },
                                                    { value: INVOICE_GENERATION_PERIOD.TWO_WEEK, text: t('backoffice.commissionplans.2week') },
                                                    { value: INVOICE_GENERATION_PERIOD.MONTH, text: t('backoffice.commissionplans.month') }
                                                ]}
                                                placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.generationPeriod")}`}
                                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                                disabled={!hasModifyPermission || selectedInvoiceGenerationMethod === INVOICE_GENERATION_METHOD.MANUAL}
                                            />
                                        </Form.Item>
                                        <Form.Item
                                            label={t("backoffice.commissionplans.nextGenerationPeriod")}
                                            name="nextInvoiceGenerationDate"
                                            className={(!hasModifyPermission || selectedInvoiceGenerationMethod === INVOICE_GENERATION_METHOD.MANUAL ? " rt--form-item-disabled" : "")}
                                        >
                                            <DatePicker
                                                className="rt--datepicker"
                                                dropdownClassName='rt--datepicker-popup rt--datepicker-withtime-popup rt--datepicker-only-hours'
                                                format={`${dateService.getFormat(false)} HH:00`}
                                                showTime={ {
                                                    hideDisabledOptions: true,
                                                    disabledMinutes: () => constructArrayForGivenRanges({ start: 1, end: 60 }),
                                                    format: "HH"
                                                } }
                                                disabledDate={d => dateService.isBefore(d, dateService.getNow())}
                                                showToday={false}
                                                showNow={false}
                                                allowClear={false}
                                                placeholder={t('backoffice.common.selectDate')}
                                                suffixIcon={<Icon name="date" />}
                                                getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                                inputReadOnly={true}
                                                disabled={!hasModifyPermission || selectedInvoiceGenerationMethod === INVOICE_GENERATION_METHOD.MANUAL}
                                            />
                                        </Form.Item>
                                        {
                                            selectedInvoiceGenerationMethod === INVOICE_GENERATION_METHOD.AUTO && (
                                                <Col span={24}>
                                                    <Divider />
                                                    <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                                        <Form.Item
                                                            name="resetNegativeBalance"
                                                            valuePropName="checked"
                                                            className='rt--form-item-without-margin'
                                                        >
                                                            <Switch 
                                                                disabled={!hasModifyPermission}
                                                            />

                                                        </Form.Item>
                                                        <label 
                                                            className='rt--title rt--font-regular rt--font-normal rt--pl-8 rt--switcher-label rt--flex rt--align-center'>
                                                                <span>
                                                                    {
                                                                        t('backoffice.commissionplans.resetNegativeBalance')
                                                                    }
                                                                </span>
                                                                <Tooltip
                                                                    title={t('backoffice.commissionplans.resetNegativeBalanceTooltip')}
                                                                    trigger={["hover", "click"]}
                                                                    placement="top"
                                                                    enableMobile={true}
                                                                >
                                                                    <Icon name="info" size={18} className="rt--ml-4" />
                                                                </Tooltip>
                                                        </label>
                                                    </div>
                                                </Col>
                                            )
                                        }
                                    </div>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                </Form>
                {
                    recalculatePopup && (
                        <Recalculate 
                            onClose={() => setRecalculatePopup(false)}
                        />
                    )
                }
            </Spin>
        </SubTabFormDashboardLayout>
    )
}

/** PayoutSettingsComponent propTypes
 * PropTypes
*/
PayoutSettingsComponent.propTypes = {
    /** Redux state property, is true when loading project calculation data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving project calculation data */
    isSaving: PropTypes.bool,
    /** Redux state property, current editing project payout settings */
    payoutSettings: projectPayoutSettingsType,
    /** Redux action to get project payout settings */
    getProjectPayoutSettings: PropTypes.func,
    /** Redux action to save project payout settings */
    saveProjectPayoutSettings: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
};

const mapDispatchToProps = dispatch => (
    {
        getProjectPayoutSettings: () => {
            dispatch(getProjectPayoutSettings());
        },

        saveProjectPayoutSettings: data => {
            dispatch(saveProjectPayoutSettings(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        isLoading: state.projects.isLoading,
        isSaving: state.projects.isSaving,
        payoutSettings: state.projects.edit.payoutSettings,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PayoutSettingsComponent)