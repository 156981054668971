import React from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Row, Col, Form } from 'antd';

import FiltersWrapper from "components/common/filters";
import NumericInput from 'components/common/numericInput';
import Select from "components/common/select";

import {
    getPendingInvoices,
    setPendingInvoicesFilters
} from 'store/actions/portal/payment/invoices/pending.action';

import { AUTOSUGGESTION_TYPE } from 'constants/autosuggestion.constants';

import useAutosuggestion from 'hooks/useAutosuggestion';

import { globalProjectIdSelector, useAuthSelector } from 'core/stores/authStore';

/** Aggregated Bets Page Filters Component */

const Filters = ({
    setPendingInvoicesFilters,
    getPendingInvoices,
    filters
}) => {
    const { t } = useTranslation();

    const globalProjectId = useAuthSelector(globalProjectIdSelector);

    const [ paymentMethods, getPaymentMethods ] = useAutosuggestion({
        type: AUTOSUGGESTION_TYPE.SYSTEM_PAYMENT_METHODS,
        autoGet: false
    })

    return (
        <FiltersWrapper
            loadFn={getPendingInvoices}
            setFiltersFn={setPendingInvoicesFilters}
            filters={filters}
            updateProps={[globalProjectId]}
            datePickerFields={[
                { name: "date", time: true },
            ]}
            searchFieldName={["affiliateNameOrId", "date"]}
            onInit={() => {
                getPaymentMethods()
            }}
        >
            <Row gutter={[16, 0]}>
                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.invoices.invoiceId')}
                        name="id"
                        className='rt--general-form-item'
                        data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.invoices.invoiceId')}`}
                    >
                        <NumericInput
                            placeholder={`${t('backoffice.common.enter')} ${t('backoffice.invoices.invoiceId')}`}
                            maxLength={12}
                        />
                    </Form.Item>
                </Col>

                <Col span={24} >
                    <Form.Item
                        label={t('backoffice.invoices.paymentMethod')}
                        name="paymentId"
                        className='rt--form-item-without-margin'
                    >
                        <Select
                            options={
                                [
                                    { value: "", text: t('backoffice.common.all') }
                                ].concat(
                                    paymentMethods.map(item => (
                                        { value: item.id, text: item.name }
                                    ))
                                )
                            }
                            placeholder={`${t('backoffice.common.select')} ${t('backoffice.invoices.paymentMethod')}`}
                            getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                            search={true}
                        />
                    </Form.Item>
                </Col>
            </Row>
        </FiltersWrapper>
    )
}

/** Filters propTypes
    * PropTypes
*/
Filters.propTypes = {
    /** Redux action to get pending invoices */
    getPendingInvoices: PropTypes.func,
    /** Redux action to set pending invoices filters */
    setPendingInvoicesFilters: PropTypes.func,
    /** Redux state property, pending invoices filters */
    filters: PropTypes.object
}

const mapDispatchToProps = dispatch => (
    {
        getPendingInvoices: () => {
            dispatch(getPendingInvoices())
        },
        setPendingInvoicesFilters: filters => {
            dispatch(setPendingInvoicesFilters(filters));
        }
    }
)

const mapStateToProps = state => {
    return {
        filters: state.invoices.pending.filters
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    Filters
);