import React from 'react';
import PropTypes from 'prop-types';

import { useTranslation } from "core/hooks/useTranslation";

import Tabs from "components/common/tabs";

import MainComponent from './main';
import AppearanceComponent from './appearance';
import AdditionalParamsComponent from './additionalParams';

import { PERMISSION_ACTION, PERMISSION_RESOURCE } from 'constants/permissions.constants';

/** Project Edit Page General Info Tab Component */
const GeneralInfoComponent = ({
    onTabChange
}) => {
    const { t } = useTranslation();

    const items = [
        {
            title: t("backoffice.projects.main"),
            component: <MainComponent />
        },
        {
            title: t("backoffice.projects.appearance"),
            component: <AppearanceComponent/>
        },
        {
            title: t("backoffice.projects.additionalParams"),
            component: <AdditionalParamsComponent/>,
            permissions: [{ resource: PERMISSION_RESOURCE.PROJECT_INTEGRATION, action: PERMISSION_ACTION.VIEW }],
        }
    ]

    return (
        <Tabs.SubTabs 
            items={items}
            onTabChange={onTabChange}
        />
    )
}

/** GeneralInfoComponent propTypes
    * PropTypes
*/
GeneralInfoComponent.propTypes = {
    /** Fires when for any form in tab, saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

export default GeneralInfoComponent;
