import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { connect } from 'react-redux';
import { useTranslation } from "core/hooks/useTranslation";

import { Form, Row, Col, Spin, Switch, Radio, Divider } from 'antd';

import { getProjectReferralSettings, saveProjectReferralSettings } from "store/actions/portal/projects/referralSettings.action";

import TabFormDashboardLayout from "components/layouts/tab/form";
import Tooltip from "components/common/tooltip";
import Select from "components/common/select";
import Icon from "components/common/icon";

import projectReferralSettingsType from "types/project/referralSettings.type";

import { isFormChanged } from "utils/form";
import { numberTransform } from 'utils/common';

import { PERMISSION_RESOURCE, PERMISSION_ACTION } from 'constants/permissions.constants';
import { PROJECT_REFERRAL_COMMISSION_CALCULATION_TYPE, PROJECT_REFERRAL_CALCULATION_PERIOD } from 'constants/project.constants';
import NumericInput from 'components/common/numericInput';

import usePermissions from 'core/hooks/usePermission';

/** Project Edit Page Referral Settings Tab Component */
const ReferralSettingsComponent = ({
    getProjectReferralSettings,
    saveProjectReferralSettings,
    isSaving,
    isLoading,
    referralSettings,
    onTabChange
}) => {
    const { t } = useTranslation();

    const permissionUtils = usePermissions();

    const [formInstance] = Form.useForm();
    const { validateFields, setFieldsValue } = formInstance;
    const [isFormTouched, setIsFormTouched] = useState(false);

    const [enabled, setEnabled] = useState(false);

    const hasModifyPermission = permissionUtils.has( PERMISSION_RESOURCE.PROJECT_REFERRAL_SETTINGS, PERMISSION_ACTION.MODIFY );

    /** Set form fields values, when data is loaded */
    useEffect(() => {
        setFieldsValue({
            ...referralSettings
        });

        setEnabled(referralSettings.enabled);
    }, [referralSettings])

    /** Fires when form submitted
       * @function
       * @memberOf ReferralSettingsComponent
   */
    const handleForm = () => {
        validateFields()
            .then(data => {
                saveProjectReferralSettings({
                    ...data
                });
                setIsFormTouched(false);
            }).catch(err => {
                console.log(err)
            })
    };

    /** Set current editing project id as global project id */
    useEffect(() => {
        getProjectReferralSettings()
    }, []);


    useEffect(() => {
        onTabChange(isFormTouched);
    }, [isFormTouched]);

    return (
        <TabFormDashboardLayout
            buttons={
                [
                    {
                        type: "primary",
                        onClick: handleForm,
                        text: t("backoffice.common.save"),
                        enabled: hasModifyPermission,
                        loading: isSaving,
                        disabled: !isFormTouched
                    }
                ]
            }
        >
            <Spin spinning={isLoading} wrapperClassName="rt--form-spin">
                <Form
                    colon={false}
                    form={formInstance}
                    requiredMark={false}
                    layout="vertical"
                    onValuesChange={
                        (changed, formValues) => {
                            setIsFormTouched(
                                isFormChanged(
                                    { ...formValues },
                                    { ...referralSettings }
                                )
                            )
                        }
                    }
                >
                    <Row gutter={[16, 0]}>
                        <Col span={24}>
                            <div className="rt--switcher rt--flex-inline rt--align-center rt--justify-between rt--mb-16">
                                <Form.Item
                                    name="enabled"
                                    valuePropName="checked"
                                    className='rt--form-item-without-margin'
                                >
                                    <Switch
                                        disabled={!hasModifyPermission}
                                        onChange={enabled => setEnabled(enabled)}
                                    />

                                </Form.Item>
                                <label
                                    className='rt--pl-8 rt--switcher-label rt--flex rt--align-center'
                                >
                                    <span className='rt--title rt--font-regular rt--font-normal'>{t('backoffice.projects.referralProgram')}</span>
                                    <Tooltip
                                        title={t(`backoffice.projects.referralProgramInfo`)}
                                        trigger={["hover", "click"]}
                                        placement="bottomLeft"
                                        enableMobile={true}
                                    >
                                        <Icon name='info' size={18} className="rt--ml-4" />
                                    </Tooltip>
                                </label>
                            </div>
                            <Divider className='rt--form-section-divider' />
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="calculationType"
                                className={'rt--form-item-inline rt--form-item-radio-buttons' + (!hasModifyPermission || !enabled ? " rt--form-item-disabled" : "")}
                            >
                                <Radio.Group
                                    disabled={!hasModifyPermission || !enabled}
                                    options={[
                                        {
                                            label: (
                                                <div className='rt--flex rt--align-center'>
                                                    <span>{t('backoffice.projects.theCompany')}</span>
                                                    <Tooltip
                                                        title={t('backoffice.projects.theCompanyTooltip')}
                                                        trigger={["hover", "click"]}
                                                        placement="top"
                                                        enableMobile={true}
                                                    >
                                                        <Icon name='info' size={18} className="rt--ml-4" />
                                                    </Tooltip>
                                                </div>
                                            ),
                                            value: PROJECT_REFERRAL_COMMISSION_CALCULATION_TYPE.BY_COMPANY
                                        },
                                        {
                                            label: (
                                                <div className='rt--flex rt--align-center'>
                                                    <span>{t('backoffice.projects.subAffiliate')}</span>
                                                    <Tooltip
                                                        title={t('backoffice.projects.subAffiliateTooltip')}
                                                        trigger={["hover", "click"]}
                                                        placement="top"
                                                        enableMobile={true}
                                                    >
                                                        <Icon name='info' size={18} className="rt--ml-4" />
                                                    </Tooltip>
                                                </div>
                                            ),
                                            value: PROJECT_REFERRAL_COMMISSION_CALCULATION_TYPE.BY_SUBAFFILIATE
                                        }
                                    ]}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={[16, 0]}>
                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={
                                    <div className="rt--flex rt--align-center">
                                        { `${t('backoffice.projects.percentage')} *` }
                                        <Tooltip
                                            title={t(`backoffice.projects.percentageTooltip`)}
                                            trigger={["hover", "click"]}
                                            placement="bottomLeft"
                                            enableMobile={true}
                                        >
                                            <Icon name='info' size={18} className="rt--ml-4" />
                                        </Tooltip>
                                    </div>
                                }
                                name="commission"
                                rules={[
                                    { type: "number", min: 0, message: t('backoffice.validation.fieldInvalid'), transform: numberTransform },

                                    () => ({
                                        validator(rule, value) {
                                            if (value === "" || value === null || value === undefined) {
                                                return Promise.reject(t('backoffice.validation.fieldRequired'))
                                            }
                                            if (Number(value) < 0 || Number(value) > 100) {
                                                return Promise.reject(t('backoffice.validation.mustBeBetween').replace("%X%", 0).replace("%Y%", 100))
                                            }
                                            return Promise.resolve();
                                        }
                                    })
                                ]}
                                validateFirst
                                className={'rt--form-item-with-suffix rt--general-form-item ' + (!hasModifyPermission || !enabled ? 'rt--form-item-disabled' : "")}
                                data-placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.percentage')}`}
                            >
                                <NumericInput
                                    placeholder={`${t('backoffice.common.enter')} ${t('backoffice.projects.percentage')}`}
                                    disabled={!hasModifyPermission || !enabled}
                                    suffix={<span className='rt--text-light rt--font-smallest rt--mr-12'>%</span>}
                                    maxLength={9}
                                />
                            </Form.Item>
                        </Col>

                        <Col xs={24} sm={12} xl={6}>
                            <Form.Item
                                label={(
                                    <div className="rt--flex rt--align-center">
                                        {`${t('backoffice.projects.referralProgramPeriod')} *`}
                                        <Tooltip
                                            title={t(`backoffice.projects.referralProgramPeriodInfo`)}
                                            trigger={["hover", "click"]}
                                            placement="bottomLeft"
                                            enableMobile={true}
                                        >
                                            <Icon name='info' size={14} className="rt--ml-6" />
                                        </Tooltip>
                                    </div>
                                )}
                                name="calculationPeriod"
                                rules={[
                                    { required: true, message: t('backoffice.validation.fieldRequired') }
                                ]}
                                className={!hasModifyPermission || !enabled ? 'rt--form-item-disabled' : ""}
                            >
                                <Select
                                    options={[
                                        { value: PROJECT_REFERRAL_CALCULATION_PERIOD._1m, text: t('backoffice.projects.1_month') },
                                        { value: PROJECT_REFERRAL_CALCULATION_PERIOD._3m, text: t('backoffice.projects.3_months') },
                                        { value: PROJECT_REFERRAL_CALCULATION_PERIOD._6m, text: t('backoffice.projects.6_months') },
                                        { value: PROJECT_REFERRAL_CALCULATION_PERIOD._1y, text: t('backoffice.projects.1_year') },
                                        { value: PROJECT_REFERRAL_CALCULATION_PERIOD._2y, text: t('backoffice.projects.2_years') },
                                        { value: PROJECT_REFERRAL_CALCULATION_PERIOD.ALWAYS, text: t('backoffice.projects.always') }
                                    ]}
                                    placeholder={`${t("backoffice.common.select")} ${t("backoffice.commissionplans.referralProgramPeriod")}`}
                                    getPopupContainer={() => document.getElementsByClassName("rt--portal-layout")[0]}
                                    disabled={!hasModifyPermission || !enabled}
                                />
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Spin>
        </TabFormDashboardLayout>
    )
}

/** ReferralSettingsComponent propTypes
    * PropTypes
*/
ReferralSettingsComponent.propTypes = {
    /** Redux state property, is true when loading project fee data */
    isLoading: PropTypes.bool,
    /** Redux state property, is true when saving project fee data */
    isSaving: PropTypes.bool,
    /** Redux state property, current editing project referral settings */
    referralSettings: projectReferralSettingsType,
    /** Redux action to get project referral settings */
    getProjectReferralSettings: PropTypes.func,
    /** Redux action to save project referral settings */
    saveProjectReferralSettings: PropTypes.func,
    /** Fires when form saved/unsaved state is changed */
    onTabChange: PropTypes.func
}

const mapDispatchToProps = dispatch => (
    {
        getProjectReferralSettings: () => {
            dispatch(getProjectReferralSettings());
        },

        saveProjectReferralSettings: data => {
            dispatch(saveProjectReferralSettings(data));
        }
    }
)

const mapStateToProps = state => {
    return {
        referralSettings: state.projects.edit.referralSettings,
        isSaving: state.projects.isSaving,
        isLoading: state.projects.isLoading,
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ReferralSettingsComponent)